import {SpotType as Spot} from '../../components/HotSpotImage/HotSpotImage';
import {ProductType, SpotType} from '../../generated/graphql';

export const availableSpots: Spot[] = [
  {id: SpotType.FOREHEAD, left: 560, top: 348},
  {id: SpotType.GLABELLA, left: 560, top: 510},
  {id: SpotType.EYEBROW, left: 800, top: 500},
  //{id: SpotType.TEMPLES, left: 320, top: 420},
  {id: SpotType.CROWS, left: 290, top: 550},
  {id: SpotType.BUNNY, left: 510, top: 610},
  {id: SpotType.EYE, left: 700, top: 620},
  {id: SpotType.CHEEKS, left: 785, top: 680},
  {id: SpotType.NOSE, left: 560, top: 680},
  {id: SpotType.NSFOLDS, left: 640, top: 770},
  {id: SpotType.LIPLINES, left: 490, top: 830},
  {id: SpotType.LIPCORNERS, left: 660, top: 870},
  {id: SpotType.LIPS, left: 560, top: 870},
  {id: SpotType.MARIONETTES, left: 470, top: 920},
  {id: SpotType.JAWLINE, left: 360, top: 920},
  {id: SpotType.CHIN, left: 560, top: 1000},
  {id: SpotType.NECK, left: 690, top: 1030},
  //{id: SpotType.EARLOBES, left: 250, top: 700},
  {id: SpotType.THICKJAWLINE, left: 310, top: 790},
  //{id: SpotType.PRP, left: 0, top: 0, wholeFace: true},
  {id: SpotType.RADIESSE, left: 0, top: 0, wholeFace: true},
  {id: SpotType.SCULPTRA, left: 0, top: 0, wholeFace: true},
  {id: SpotType.UNKNOWN, left: 0, top: 0, wholeFace: true},
];
export {availableSpots as spots};

export const spotProduct: {[key: string]: ProductType[]} = {};

spotProduct[SpotType.FOREHEAD] = [ProductType.BOTOX];
spotProduct[SpotType.GLABELLA] = [ProductType.BOTOX];
spotProduct[SpotType.EYEBROW] = [ProductType.BOTOX];
spotProduct[SpotType.TEMPLES] = [ProductType.FILLER];
spotProduct[SpotType.CROWS] = [ProductType.BOTOX];
spotProduct[SpotType.BUNNY] = [ProductType.BOTOX];
spotProduct[SpotType.EYE] = [ProductType.FILLER];
spotProduct[SpotType.CHEEKS] = [ProductType.FILLER];
spotProduct[SpotType.NOSE] = [ProductType.FILLER];
spotProduct[SpotType.NSFOLDS] = [ProductType.FILLER];
spotProduct[SpotType.LIPLINES] = [ProductType.BOTOX, ProductType.FILLER];
spotProduct[SpotType.LIPCORNERS] = [ProductType.FILLER];
spotProduct[SpotType.LIPS] = [ProductType.FILLER];
spotProduct[SpotType.MARIONETTES] = [ProductType.FILLER];
spotProduct[SpotType.JAWLINE] = [ProductType.FILLER];
spotProduct[SpotType.CHIN] = [ProductType.BOTOX, ProductType.FILLER];
spotProduct[SpotType.NECK] = [ProductType.BOTOX, ProductType.FILLER];
spotProduct[SpotType.EARLOBES] = [ProductType.FILLER];
spotProduct[SpotType.THICKJAWLINE] = [ProductType.BOTOX];
spotProduct[SpotType.PRP] = [ProductType.PRP];
spotProduct[SpotType.RADIESSE] = [ProductType.RADIESSE];
spotProduct[SpotType.SCULPTRA] = [ProductType.SCULPTRA];
spotProduct[SpotType.UNKNOWN] = [ProductType.OTHER];
