import Dexie from 'dexie';
import { nanoid } from 'nanoid';

import { UploadedFile } from './types/UploadedFile';

export interface Upload {
  id: string;
  data: string;
}

class JuvenisDatabase extends Dexie {
  uploads!: Dexie.Table<Upload, string>;
  constructor() {
    super('JuvenisDatabase');
    this.version(1).stores({
      uploads: 'id',
    });
  }
  async storeFile(acceptedFile: File): Promise<UploadedFile> {
    return new Promise<UploadedFile>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFile);
      reader.onload = async () => {
        const id = nanoid();
        await db.uploads.add({
          id,
          data: (reader.result instanceof ArrayBuffer ? '' : (reader.result as string)) || '',
        });
        resolve({originalName: acceptedFile.name, uploadedName: `idb:${id}`});
      };
      reader.onerror = reject;
    });
  }
}

const db = new JuvenisDatabase();
export default db;
