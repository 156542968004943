import gql from 'graphql-tag';
const query = gql`
  query verifyRequest($params: VerifyLinkRequest!) {
    verifyRequest(params: $params) {
      email
      firstName
      lastName
    }
  }
`;
export default query;
