import { useMutation } from '@apollo/react-hooks';
import { ChoiceGroup, Label, MessageBarType, Stack, StackItem } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TimePicker from '../../components/TimePicker';
import { MutationaddSlotArgs, TimeSlot, TimeSlotAvailability } from '../../generated/graphql';
import { addSlot } from '../../graphql/mutations';
import { ModalTwoButtons } from '../../templates/ModalTwoButtons';
import { convertErrors } from '../../utils/convertToFormErrors';
import { PADDING } from './const';
import { DashboardActionType, useDashboardContext } from './Context';

export interface AddSlotProps {
  onClose: () => void;
}

export const AddSlot: React.FunctionComponent<AddSlotProps> = (props) => {
  const {t} = useTranslation();
  const {onClose} = props;
  const {
    dispatch,
    state: {selectedDate},
  } = useDashboardContext();
  const [dateTime, setDateTime] = React.useState<DateTime>(selectedDate.startOf('hour'));
  const [availability, setAvailability] = React.useState(TimeSlotAvailability.ALL);

  const [mutationaddSlot] = useMutation<{addSlot: TimeSlot}, MutationaddSlotArgs>(addSlot);

  const onSave = React.useCallback(async () => {
    try {
      const ret = await mutationaddSlot({
        variables: {
          timeSlot: {userId: '1', date: dateTime.toSeconds(), length: 60, availability},
        },
      });

      dispatch({
        type: DashboardActionType.PopUp,
        message: {type: MessageBarType.success, text: t('message.slotAdded')},
      });

      if (ret.data?.addSlot)
        dispatch({
          type: DashboardActionType.UpdateTimeSlot,
          action: 'create',
          timeSlot: ret.data?.addSlot,
        });
    } catch (e) {
      if (e instanceof ApolloError) {
        const {globalErrors} = convertErrors(e, t);

        if (globalErrors)
          dispatch({
            type: DashboardActionType.PopUp,
            message: {
              type: MessageBarType.error,
              text: globalErrors.map((v) => v.message).join(' '),
            },
          });
      }
    }
  }, [dateTime, dispatch, availability, mutationaddSlot, t]);

  return (
    <ModalTwoButtons
      cancelButtonProps={{text: t('button.cancel'), onClick: (): void => onClose()}}
      saveButtonProps={{
        text: t('button.save'),
        onClick: (): void => {
          onSave();
        },
      }}>
      <Stack tokens={{childrenGap: PADDING}}>
        <StackItem>
          <Label>{t('field.time')}</Label>
          <TimePicker stepMinutes={5} value={dateTime} onChange={(nd): void => setDateTime(nd)} />
        </StackItem>
        <StackItem>
          <ChoiceGroup
            label={t('field.availability')}
            options={[
              {key: TimeSlotAvailability.ALL, text: t('option.availability.ALL')},
              {key: TimeSlotAvailability.EXISTING, text: t('option.availability.EXISTING')},
              {key: TimeSlotAvailability.NEW, text: t('option.availability.NEW')},
            ]}
            defaultSelectedKey={availability}
            onChange={(_, option) =>
              setAvailability((option?.key as TimeSlotAvailability) || TimeSlotAvailability.ALL)
            }></ChoiceGroup>
        </StackItem>
      </Stack>
    </ModalTwoButtons>
  );
};

export default AddSlot;
