import gql from 'graphql-tag';
const query = gql`
  query getQuestions($isExistingClient: Boolean!) {
    getQuestions(isExistingClient: $isExistingClient) {
      code
      text
    }
  }
`;
export default query;
