import { Calendar, DateRangeType, DayOfWeek, Stack, StackItem, Toggle } from '@fluentui/react';
import { useStateMachine } from 'little-state-machine';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import FileSyncer from '../components/FileSyncer';
import { URL_MONTHLY_REPORT, URL_PRODUCT_REPORT } from '../const/config';
import { GlobalState, switchDataMode } from '../state/globalState';
import { theme } from '../styles/theme';

interface SideBarProps {
  hidden: boolean;
  onSelectDate?: (date: Date, dateRangeArray?: Date[]) => void;
  selectedDate?: Date;
}

const SideBar: React.FunctionComponent<SideBarProps> = (props: SideBarProps) => {
  const {onSelectDate, selectedDate, hidden} = props;
  const {t} = useTranslation();

  const {
    state: {isLowDataMode,user: {login}},
    action,
  } = useStateMachine<GlobalState>(switchDataMode);

  const dayPickerStrings = React.useMemo(() => {
    return {
      months: [
        t('calendar:month.January'),
        t('calendar:month.February'),
        t('calendar:month.March'),
        t('calendar:month.April'),
        t('calendar:month.May'),
        t('calendar:month.June'),
        t('calendar:month.July'),
        t('calendar:month.August'),
        t('calendar:month.September'),
        t('calendar:month.October'),
        t('calendar:month.November'),
        t('calendar:month.December'),
      ],
      shortMonths: [
        t('calendar:shortMonth.January'),
        t('calendar:shortMonth.February'),
        t('calendar:shortMonth.March'),
        t('calendar:shortMonth.April'),
        t('calendar:shortMonth.May'),
        t('calendar:shortMonth.June'),
        t('calendar:shortMonth.July'),
        t('calendar:shortMonth.August'),
        t('calendar:shortMonth.September'),
        t('calendar:shortMonth.October'),
        t('calendar:shortMonth.November'),
        t('calendar:shortMonth.December'),
      ],
      days: [
        t('calendar:day.Sunday'),
        t('calendar:day.Monday'),
        t('calendar:day.Tuesday'),
        t('calendar:day.Wednesday'),
        t('calendar:day.Thursday'),
        t('calendar:day.Friday'),
        t('calendar:day.Saturday'),
      ],
      shortDays: [
        t('calendar:shortDay.Sunday'),
        t('calendar:shortDay.Monday'),
        t('calendar:shortDay.Tuesday'),
        t('calendar:shortDay.Wednesday'),
        t('calendar:shortDay.Thursday'),
        t('calendar:shortDay.Friday'),
        t('calendar:shortDay.Saturday'),
      ],
      goToToday: t('calendar:goToToday'),
      weekNumberFormatString: t('calendar:weekNumberFormatString'),
      prevMonthAriaLabel: t('calendar:prevMonthAriaLabel'),
      nextMonthAriaLabel: t('calendar:nextMonthAriaLabel'),
      prevYearAriaLabel: t('calendar:prevYearAriaLabel'),
      nextYearAriaLabel: t('calendar:nextYearAriaLabel'),
      prevYearRangeAriaLabel: t('calendar:prevYearRangeAriaLabel'),
      nextYearRangeAriaLabel: t('calendar:nextYearRangeAriaLabel'),
      closeButtonAriaLabel: 'calendar:closeButtonAriaLabel',
    };
  }, [t]);

  const date = selectedDate || new Date();
  const strDate = date.toISOString().split('T')[0];

  return hidden ? null : (
    <Stack tokens={{padding: theme.spacing.s1, childrenGap: theme.spacing.l1}}>
      <StackItem>
        <Calendar
          onSelectDate={onSelectDate}
          isMonthPickerVisible={false}
          dateRangeType={DateRangeType.Day}
          showGoToToday={true}
          value={selectedDate}
          firstDayOfWeek={DayOfWeek.Monday}
          strings={dayPickerStrings}
          highlightCurrentMonth={true}
          highlightSelectedMonth={true}
          isDayPickerVisible={true}
          showMonthPickerAsOverlay={true}
          showWeekNumbers={false}
        />
      </StackItem>
      {login?.role === 'ADMIN' &&
        <>
          <StackItem>
            <Link to={generatePath(URL_MONTHLY_REPORT, {date: strDate})}>Monthly report</Link>
          </StackItem>
          <StackItem>
            <Link to={generatePath(URL_PRODUCT_REPORT, {date: strDate})}>Monthly product report</Link>
          </StackItem>
        </>
      }
      <StackItem>
        <Toggle
          label='Low data mode'
          checked={isLowDataMode}
          onText='On'
          offText='Off'
          onChange={() => action({})}
        />
      </StackItem>
      <StackItem>
        <FileSyncer />
      </StackItem>
    </Stack>
  );
};

export {SideBar};
export default SideBar;
