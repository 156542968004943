export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Timestamp in seconds since Unix epoch */
  UnixTime: any;
};

export enum Action {
  SENDINSTRUCTIONSBOTOX = 'SENDINSTRUCTIONSBOTOX',
  SENDINSTRUCTIONSFILLER = 'SENDINSTRUCTIONSFILLER',
  SENDFOLLOWUP = 'SENDFOLLOWUP',
  SENDREQUESTLINK = 'SENDREQUESTLINK',
  SENDINVOICELINK = 'SENDINVOICELINK',
  SENDCONSENTLINK = 'SENDCONSENTLINK'
}

export enum Anesthesia {
  ICEPACK = 'ICEPACK',
  LIDOCAINE = 'LIDOCAINE',
  STANHEXIDINE = 'STANHEXIDINE'
}

export type Answer = {
  questionCode: Scalars['String'];
  questionText?: Maybe<Scalars['String']>;
  answer: Toggle;
};

export type AnswerInput = {
  __typename?: 'AnswerInput';
  id: Scalars['ID'];
  version: Scalars['Int'];
  questionCode: Scalars['String'];
  questionText?: Maybe<Scalars['String']>;
  answer: Toggle;
};

export type AvailableSlotInput = {
  date: Scalars['UnixTime'];
  phone?: Maybe<Scalars['String']>;
  change?: Maybe<Toggle>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  version: Scalars['Int'];
  name: Scalars['String'];
};

export type BrandInput = {
  name: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export enum ClientTXRequest {
  REQ01 = 'REQ01',
  REQ02 = 'REQ02',
  REQ03 = 'REQ03',
  REQ04 = 'REQ04',
  REQ05 = 'REQ05',
  REQ06 = 'REQ06',
  REQ07 = 'REQ07',
  REQ08 = 'REQ08',
  REQ09 = 'REQ09',
  REQ10 = 'REQ10',
  REQ11 = 'REQ11',
  REQ12 = 'REQ12',
  REQ13 = 'REQ13',
  REQ14 = 'REQ14',
  REQ15 = 'REQ15',
  REQ16 = 'REQ16',
  REQ17 = 'REQ17',
  REQ18 = 'REQ18',
  REQ19 = 'REQ19'
}

export enum ClinicalCondition {
  COND01 = 'COND01',
  COND02 = 'COND02',
  COND03 = 'COND03',
  COND04 = 'COND04',
  COND05 = 'COND05',
  COND06 = 'COND06'
}

export type Common = {
  __typename?: 'Common';
  id: Scalars['ID'];
  version: Scalars['Int'];
};

export type CommonImmutable = {
  __typename?: 'CommonImmutable';
  id: Scalars['ID'];
};

export type Consent = {
  __typename?: 'Consent';
  id: Scalars['ID'];
  version: Scalars['Int'];
  productType: ProductType;
  signatureSVG?: Maybe<Scalars['String']>;
  signatureTime?: Maybe<Scalars['UnixTime']>;
  signatureIP?: Maybe<Scalars['String']>;
};

export type ConsentInput = {
  sessionCode: Scalars['ID'];
  productTypes: Array<ProductType>;
  signatureSVG?: Maybe<Scalars['String']>;
};

export type CreateAppointmentInput = {
  dateTime: Scalars['UnixTime'];
  patientId?: Maybe<Scalars['ID']>;
  sessionId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type DateRange = {
  startDate?: Maybe<Scalars['UnixTime']>;
  endDate?: Maybe<Scalars['UnixTime']>;
};

export type DateRangeValue = {
  __typename?: 'DateRangeValue';
  startDate?: Maybe<Scalars['UnixTime']>;
  endDate?: Maybe<Scalars['UnixTime']>;
};


export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  X = 'X'
}

export type GetNotesParams = {
  filter: NoteFilter;
  sort?: Maybe<Array<NoteSort>>;
};

export type GetTimeSlotsParams = {
  filter: TimeSlotFilter;
  sortDate?: Maybe<SortDirection>;
};

export type Invoice = {
  __typename?: 'Invoice';
  client: Client;
  date: Scalars['UnixTime'];
  id: Scalars['ID'];
  total: Scalars['Int'];
  tax: Scalars['Int'];
  discount: Scalars['Int'];
  lineItems: Array<LineItem>;
  precision: Scalars['Int'];
};

export enum Language {
  en = 'en',
  fr = 'fr',
  ru = 'ru'
}

export type LineItem = {
  __typename?: 'LineItem';
  name: Scalars['String'];
  quantity: Scalars['Int'];
  price: Scalars['Int'];
  total: Scalars['Int'];
  tax: Scalars['Int'];
};

export type LinkRequest = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clientKey: Scalars['String'];
};

export type ListPatientsInput = {
  filter: PatientFilter;
};

export type LoginRequest = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum LookupField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE'
}

export enum MediaConsentType {
  NONE = 'NONE',
  PARTIAL = 'PARTIAL',
  FULL = 'FULL'
}

export type MonthlyProductReportData = {
  __typename?: 'MonthlyProductReportData';
  range: DateRangeValue;
  reportItems: Array<ReportProductItem>;
};

export type MonthlyReportData = {
  __typename?: 'MonthlyReportData';
  precision: Scalars['Int'];
  range: DateRangeValue;
  reportItems: Array<ReportItem>;
  total: ReportItem;
};

export type Mutation = {
  __typename?: 'Mutation';
  removeSlot?: Maybe<Scalars['Boolean']>;
  addSlot?: Maybe<TimeSlot>;
  changeTime?: Maybe<TimeSlot>;
  changeSessionTime?: Maybe<TimeSlot>;
  submitAppointmentDetails: Scalars['Boolean'];
  verifyCode: Scalars['Boolean'];
  requestCodeBySMS: Scalars['Boolean'];
  createAppointment: Session;
  requestAppointment: Scalars['Boolean'];
  requestLink: Scalars['Boolean'];
  savePatient: Patient;
  saveTreatment: Session;
  saveTreatmentNote: TreatmentInjection;
  signConsent: Scalars['Boolean'];
  subscribe: Scalars['Boolean'];
  unsubscribe: Scalars['Boolean'];
  sendPhoneCode: Scalars['Boolean'];
  /** Return JWT with validated phone */
  validatePhoneCode: Scalars['String'];
  createTimeSlots: Scalars['Boolean'];
  saveNote: Note;
  saveQuestionary: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  login: UserWithTokens;
};


export type MutationremoveSlotArgs = {
  timeSlotId: Scalars['ID'];
};


export type MutationaddSlotArgs = {
  timeSlot: TimeSlotInput;
};


export type MutationchangeTimeArgs = {
  timeSlot?: Maybe<TimeSlotInput>;
  sessionId: Scalars['ID'];
};


export type MutationchangeSessionTimeArgs = {
  timeSlotId?: Maybe<Scalars['ID']>;
  sessionCode: Scalars['String'];
};


export type MutationsubmitAppointmentDetailsArgs = {
  params: SessionParams;
};


export type MutationverifyCodeArgs = {
  input: PhoneConfirmationInput;
};


export type MutationrequestCodeBySMSArgs = {
  input: PhoneConfirmationInput;
};


export type MutationcreateAppointmentArgs = {
  params: CreateAppointmentInput;
};


export type MutationrequestAppointmentArgs = {
  params: RequestParams;
};


export type MutationrequestLinkArgs = {
  params: LinkRequest;
};


export type MutationsavePatientArgs = {
  version?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  params: PatientInput;
};


export type MutationsaveTreatmentArgs = {
  version?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  params: TreatmentInput;
};


export type MutationsaveTreatmentNoteArgs = {
  version?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  params: TreatmentInjectionInput;
};


export type MutationsignConsentArgs = {
  params: ConsentInput;
};


export type MutationsubscribeArgs = {
  input: SubscribeInput;
};


export type MutationunsubscribeArgs = {
  email: Scalars['String'];
};


export type MutationsendPhoneCodeArgs = {
  params: SendVerifyRequest;
};


export type MutationvalidatePhoneCodeArgs = {
  params: VerifyPhoneRequest;
};


export type MutationcreateTimeSlotsArgs = {
  availability?: Maybe<TimeSlotAvailability>;
  includeDays?: Maybe<Array<Scalars['Int']>>;
  excludeDays?: Maybe<Array<Scalars['Int']>>;
  schedule: ScheduleInput;
  month: Scalars['Int'];
  year: Scalars['Int'];
};


export type MutationsaveNoteArgs = {
  id?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
  params: NoteInput;
};


export type MutationsaveQuestionaryArgs = {
  id: Scalars['ID'];
  params: QuestionaryInput;
};


export type MutationrefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationloginArgs = {
  params: LoginRequest;
};

export enum NextTreatment {
  NEXT01 = 'NEXT01',
  NEXT02 = 'NEXT02',
  NEXT03 = 'NEXT03',
  NEXT04 = 'NEXT04'
}

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  version: Scalars['Int'];
  archived: Toggle;
  user: User;
  dateTime: Scalars['UnixTime'];
  text: Scalars['String'];
};

export enum NoteField {
  dateTime = 'dateTime'
}

export type NoteFilter = {
  text?: Maybe<Scalars['String']>;
  archived?: Maybe<Array<Toggle>>;
  startDate?: Maybe<Scalars['UnixTime']>;
  endDate?: Maybe<Scalars['UnixTime']>;
};

export type NoteInput = {
  text: Scalars['String'];
  dateTime: Scalars['UnixTime'];
  archived: Toggle;
};

export type NoteSort = {
  field: NoteField;
  order: SortDirection;
};

export type Patient = {
  __typename?: 'Patient';
  id: Scalars['ID'];
  version: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars['UnixTime']>;
  birthdayText?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  existingClient?: Maybe<Toggle>;
  language: Language;
  sessions: Array<Session>;
};

export type PatientFilter = {
  text?: Maybe<Scalars['String']>;
  lookupFields?: Maybe<Array<LookupField>>;
};

export type PatientInput = {
  email: Scalars['String'];
  phone: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender?: Maybe<Gender>;
  birthday?: Maybe<Scalars['UnixTime']>;
  existingClient?: Maybe<Toggle>;
};

export enum PaymentType {
  UNKNOWN = 'UNKNOWN',
  CASH = 'CASH',
  CARD = 'CARD',
  ETRANSFER = 'ETRANSFER'
}

export type PhoneConfirmationInput = {
  phone: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type Photo = {
  __typename?: 'Photo';
  id: Scalars['ID'];
  version: Scalars['Int'];
  type: PhotoType;
  fileName: Scalars['String'];
  fileCDN: Scalars['String'];
};

export type PhotoInput = {
  type: PhotoType;
  fileName: Scalars['String'];
  fileCDN: Scalars['String'];
};

export enum PhotoType {
  NEUTRAL = 'NEUTRAL',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  FROWN = 'FROWN',
  SURPRISE = 'SURPRISE',
  TREATMENT = 'TREATMENT',
  FOLLOWUP = 'FOLLOWUP',
  SESSION = 'SESSION'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  version: Scalars['Int'];
  priority: Scalars['Int'];
  brand: Brand;
  productType: ProductType;
  productUnit: ProductUnit;
  name: Scalars['String'];
  defaultPrice: Scalars['Float'];
  cost: Scalars['Float'];
  productVariants?: Maybe<Array<ProductVariant>>;
};

export enum ProductType {
  BOTOX = 'BOTOX',
  FILLER = 'FILLER',
  PRP = 'PRP',
  RADIESSE = 'RADIESSE',
  MESO = 'MESO',
  HYALO = 'HYALO',
  OTHER = 'OTHER',
  SCULPTRA = 'SCULPTRA'
}

export enum ProductUnit {
  UNIT = 'UNIT',
  ML = 'ML',
  PROCEDURE = 'PROCEDURE'
}

export type ProductUsage = {
  __typename?: 'ProductUsage';
  id: Scalars['ID'];
  version: Scalars['Int'];
  product: Product;
  unitsInjected: Scalars['Float'];
  unitsPurchased?: Maybe<Scalars['Float']>;
  isNewPackage: Toggle;
  price: Scalars['Float'];
};

export type ProductUsageInput = {
  productId: Scalars['ID'];
  unitsInjected: Scalars['Int'];
  unitsPurchased?: Maybe<Scalars['Int']>;
  isNewPackage: Toggle;
  price: Scalars['Int'];
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  id: Scalars['ID'];
  version: Scalars['Int'];
  product: Product;
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getQuestions: Array<Question>;
  getAvailableTimeslots: Array<TimeSlot>;
  getPatient?: Maybe<Patient>;
  getSession?: Maybe<Session>;
  getInvoice?: Maybe<Invoice>;
  listPatients: Array<Patient>;
  verifyRequest: Patient;
  getTimeSlots?: Maybe<Array<TimeSlot>>;
  monthlyProductReport?: Maybe<MonthlyProductReportData>;
  monthlyReport?: Maybe<MonthlyReportData>;
  getNotes?: Maybe<Array<Note>>;
  getProducts: Array<Product>;
};


export type QuerygetQuestionsArgs = {
  isExistingClient: Scalars['Boolean'];
};


export type QuerygetAvailableTimeslotsArgs = {
  params: AvailableSlotInput;
};


export type QuerygetPatientArgs = {
  id: Scalars['String'];
};


export type QuerygetSessionArgs = {
  id: Scalars['String'];
};


export type QuerygetInvoiceArgs = {
  id: Scalars['String'];
};


export type QuerylistPatientsArgs = {
  params: ListPatientsInput;
};


export type QueryverifyRequestArgs = {
  params: VerifyLinkRequest;
};


export type QuerygetTimeSlotsArgs = {
  params: GetTimeSlotsParams;
};


export type QuerymonthlyProductReportArgs = {
  params: DateRange;
};


export type QuerymonthlyReportArgs = {
  params: DateRange;
};


export type QuerygetNotesArgs = {
  params: GetNotesParams;
};


export type QuerygetProductsArgs = {
  name?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  code: Scalars['String'];
  text: Scalars['String'];
};

export type QuestionaryInput = {
  commentHealth: Scalars['String'];
  answers: Array<Answer>;
};

export enum Reaction {
  REACT01 = 'REACT01',
  REACT02 = 'REACT02',
  REACT03 = 'REACT03',
  REACT04 = 'REACT04',
  REACT05 = 'REACT05',
  REACT06 = 'REACT06'
}

export type ReportItem = {
  __typename?: 'ReportItem';
  date: Scalars['UnixTime'];
  clientName: Scalars['String'];
  units: Scalars['Int'];
  price: Scalars['Int'];
  cost: Scalars['Int'];
  product: Scalars['String'];
  productType: ProductType;
  totalSale: Total;
  totalCost: Total;
  balance: Scalars['Int'];
  comission: Scalars['Int'];
  transferA: Scalars['Int'];
  transferB: Scalars['Int'];
};

export type ReportProductItem = {
  __typename?: 'ReportProductItem';
  product: Scalars['String'];
  units: Scalars['Float'];
};

export type RequestParams = {
  patient: PatientInput;
  timeSlot?: Maybe<Scalars['ID']>;
};

export enum Role {
  ADMIN = 'ADMIN',
  PATIENT = 'PATIENT',
  USER = 'USER'
}

export type ScheduleInput = {
  Mon?: Maybe<Array<Scalars['Int']>>;
  Tue?: Maybe<Array<Scalars['Int']>>;
  Wed?: Maybe<Array<Scalars['Int']>>;
  Thu?: Maybe<Array<Scalars['Int']>>;
  Fri?: Maybe<Array<Scalars['Int']>>;
};

export type SendVerifyRequest = {
  recaptchaValue: Scalars['String'];
  phone: Scalars['String'];
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  version: Scalars['Int'];
  patient: Patient;
  user: User;
  type: SessionType;
  sessionCode: Scalars['String'];
  status: SessionStatus;
  commentHealth?: Maybe<Scalars['String']>;
  commentProcedure?: Maybe<Scalars['String']>;
  noteSession?: Maybe<Scalars['String']>;
  noteTreatment?: Maybe<Scalars['String']>;
  noteFollowUp?: Maybe<Scalars['String']>;
  signatureSVG?: Maybe<Scalars['String']>;
  signatureTime?: Maybe<Scalars['UnixTime']>;
  signatureIP?: Maybe<Scalars['String']>;
  requestTime?: Maybe<Scalars['UnixTime']>;
  requestIP?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Session>>;
  parent?: Maybe<Session>;
  spots?: Maybe<Array<SpotType>>;
  photos?: Maybe<Array<Photo>>;
  consents?: Maybe<Array<Consent>>;
  productUsage?: Maybe<Array<ProductUsage>>;
  timeSlot?: Maybe<TimeSlot>;
  mediaConsent: MediaConsentType;
  paymentType: PaymentType;
  treatment?: Maybe<TreatmentInjection>;
  answers: Array<AnswerInput>;
  files: Array<Photo>;
};

export type SessionParams = {
  patient: PatientInput;
  sessionCode: Scalars['String'];
  commentHealth: Scalars['String'];
  commentProcedure: Scalars['String'];
  signatureSVG: Scalars['String'];
  spots: Array<SpotType>;
  photos: Array<PhotoInput>;
  answers: Array<Answer>;
  mediaConsent?: Maybe<MediaConsentType>;
};

export enum SessionStatus {
  REQUEST = 'REQUEST',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum SessionType {
  PRIMARY = 'PRIMARY',
  FOLLOWUP = 'FOLLOWUP'
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type SpotInjection = {
  __typename?: 'SpotInjection';
  spot: SpotType;
  /** 1/100 of the unit */
  units: Scalars['Int'];
};

export type SpotInjectionInput = {
  spot: SpotType;
  /** 1/100 of the unit */
  units: Scalars['Int'];
};

export enum SpotType {
  FOREHEAD = 'FOREHEAD',
  GLABELLA = 'GLABELLA',
  CROWS = 'CROWS',
  EYE = 'EYE',
  CHEEKS = 'CHEEKS',
  EYEBROW = 'EYEBROW',
  TEMPLES = 'TEMPLES',
  BUNNY = 'BUNNY',
  NOSE = 'NOSE',
  NSFOLDS = 'NSFOLDS',
  LIPLINES = 'LIPLINES',
  LIPCORNERS = 'LIPCORNERS',
  LIPS = 'LIPS',
  MARIONETTES = 'MARIONETTES',
  JAWLINE = 'JAWLINE',
  CHIN = 'CHIN',
  NECK = 'NECK',
  EARLOBES = 'EARLOBES',
  THICKJAWLINE = 'THICKJAWLINE',
  SIDE = 'SIDE',
  PRP = 'PRP',
  RADIESSE = 'RADIESSE',
  SCULPTRA = 'SCULPTRA',
  MESOTOX = 'MESOTOX',
  GUMMYSMILE = 'GUMMYSMILE',
  LIPFLIP = 'LIPFLIP',
  UNKNOWN = 'UNKNOWN',
  DAO = 'DAO'
}

export type SubscribeInput = {
  email: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['ID'];
  email: Scalars['String'];
  language: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  id?: Maybe<Scalars['ID']>;
  version: Scalars['Int'];
  user: User;
  session?: Maybe<Session>;
  date: Scalars['UnixTime'];
  isAvailableToNewClients: Toggle;
  availability: TimeSlotAvailability;
  length: Scalars['Int'];
};

export enum TimeSlotAvailability {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
  ALL = 'ALL'
}

export type TimeSlotFilter = {
  text?: Maybe<Scalars['String']>;
  lookupFields?: Maybe<Array<LookupField>>;
  range: DateRange;
};

export type TimeSlotInput = {
  userId: Scalars['ID'];
  date: Scalars['UnixTime'];
  length: Scalars['Int'];
  availability: TimeSlotAvailability;
};

/** Yes/No value */
export enum Toggle {
  YES = 'YES',
  NO = 'NO'
}

export type Total = {
  __typename?: 'Total';
  total: Scalars['Int'];
  gst: Scalars['Int'];
  qst: Scalars['Int'];
  totalTx: Scalars['Int'];
};

export enum TreatmentFormType {
  SESSION = 'SESSION',
  TREATMENT = 'TREATMENT',
  FOLLOWUP = 'FOLLOWUP'
}

export type TreatmentInjection = {
  __typename?: 'TreatmentInjection';
  clientTXRequest: Array<ClientTXRequest>;
  clientTXRequestNote: Scalars['String'];
  clinicalCondition: Array<ClinicalCondition>;
  clinicalConditionNote: Scalars['String'];
  contraIndications: Toggle;
  treatmentPlan: Array<TreatmentPlan>;
  treatmentPlanNote: Scalars['String'];
  sideEffectsExplained: Toggle;
  optimalResultExplained: Toggle;
  questionsAnswered: Toggle;
  reactions: Array<Reaction>;
  reactionsNote: Scalars['String'];
  instructionsExplained: Toggle;
  instructionsSent?: Maybe<Toggle>;
  invoiceSent?: Maybe<Toggle>;
  protocolAdjustedTreatment: Toggle;
  followUp: Toggle;
  nextTreatment: Array<NextTreatment>;
  nextTreatmentNote: Scalars['String'];
  /** SVG schema */
  schema: Scalars['String'];
  /** Base image */
  schemaBase: Scalars['String'];
  treatmentProducts: Array<TreatmentProduct>;
};

export type TreatmentInjectionInput = {
  clientTXRequest: Array<ClientTXRequest>;
  clientTXRequestNote: Scalars['String'];
  clinicalCondition: Array<ClinicalCondition>;
  clinicalConditionNote: Scalars['String'];
  contraIndications?: Maybe<Toggle>;
  treatmentPlan: Array<TreatmentPlan>;
  treatmentPlanNote: Scalars['String'];
  sideEffectsExplained: Toggle;
  optimalResultExplained: Toggle;
  questionsAnswered: Toggle;
  reactions: Array<Reaction>;
  reactionsNote: Scalars['String'];
  instructionsExplained?: Maybe<Toggle>;
  instructionsSent?: Maybe<Toggle>;
  invoiceSent?: Maybe<Toggle>;
  protocolAdjustedTreatment?: Maybe<Toggle>;
  followUp?: Maybe<Toggle>;
  nextTreatment: Array<NextTreatment>;
  nextTreatmentNote: Scalars['String'];
  /** SVG schema */
  schema: Scalars['String'];
  /** Base image */
  schemaBase: Scalars['String'];
  treatmentProducts: Array<TreatmentProductInput>;
};

export type TreatmentInput = {
  type: TreatmentFormType;
  note: Scalars['String'];
  files: Array<PhotoInput>;
  actions?: Maybe<Array<Action>>;
  productTypes?: Maybe<Array<ProductType>>;
  productUsage?: Maybe<Array<ProductUsageInput>>;
  paymentType?: Maybe<PaymentType>;
};

export enum TreatmentPlan {
  PLAN01 = 'PLAN01',
  PLAN02 = 'PLAN02',
  PLAN03 = 'PLAN03',
  PLAN04 = 'PLAN04',
  PLAN05 = 'PLAN05',
  PLAN06 = 'PLAN06',
  PLAN07 = 'PLAN07',
  PLAN08 = 'PLAN08',
  PLAN09 = 'PLAN09',
  PLAN10 = 'PLAN10',
  PLAN11 = 'PLAN11',
  PLAN12 = 'PLAN12',
  PLAN13 = 'PLAN13',
  PLAN14 = 'PLAN14',
  PLAN15 = 'PLAN15',
  PLAN16 = 'PLAN16',
  PLAN17 = 'PLAN17',
  PLAN18 = 'PLAN18',
  PLAN19 = 'PLAN19',
  PLAN20 = 'PLAN20',
  PLAN21 = 'PLAN21',
  PLAN22 = 'PLAN22',
  PLAN23 = 'PLAN23',
  PLAN24 = 'PLAN24'
}

export type TreatmentProduct = {
  __typename?: 'TreatmentProduct';
  /** in 1/100 of unit */
  units: Scalars['Float'];
  spots?: Maybe<Array<SpotInjection>>;
  anesthesia?: Maybe<Array<Anesthesia>>;
  note?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  productVariantId?: Maybe<Scalars['ID']>;
};

export type TreatmentProductInput = {
  /** in 1/100 of unit */
  units: Scalars['Float'];
  spots?: Maybe<Array<SpotInjectionInput>>;
  anesthesia?: Maybe<Array<Anesthesia>>;
  note?: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  productVariantId?: Maybe<Scalars['ID']>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  version: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Role;
  sessions: Array<Session>;
  timeslots: Array<TimeSlot>;
  notes: Array<Note>;
};

export type UserWithTokens = {
  __typename?: 'UserWithTokens';
  id: Scalars['ID'];
  version: Scalars['Int'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: Role;
  sessions: Array<Session>;
  timeslots: Array<TimeSlot>;
  notes: Array<Note>;
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type VerifyLinkRequest = {
  token: Scalars['String'];
};

export type VerifyPhoneRequest = {
  recaptchaValue: Scalars['String'];
  code: Scalars['String'];
};
