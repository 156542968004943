import { useQuery } from '@apollo/react-hooks';
import {
  Callout,
  CommandBar,
  CommandButton,
  ContextualMenuItemType,
  DirectionalHint,
  FontSizes,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  mergeStyleSets,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Modal,
  Persona,
  PersonaSize,
  SearchBox,
  Stack,
  StackItem,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useStateMachine } from 'little-state-machine';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { useDebounce } from 'react-use';

import { LANGUAGES, settings, URL_LOGIN } from '../../const/config';
import { Patient, QuerygetTimeSlotsArgs, QuerylistPatientsArgs, Role, TimeSlot } from '../../generated/graphql';
import { getTimeSlots, listPatients } from '../../graphql/queries';
import NoteList from '../../organisms/NoteList';
import SideBar from '../../organisms/Sidebar';
import Spinner from '../../organisms/Spinner';
import { logOut } from '../../state';
import { GlobalState } from '../../state/globalState';
import { updateUser } from '../../state/userState';
import { theme } from '../../styles/theme';
import AddSlot from './AddSlot';
import Agenda from './Agenda';
import { AGENDA_WIDTH, PADDING, PADDING32, SIDEBAR_WIDTH, SIZE_2XUNIT, SIZE_UNIT } from './const';
import { DashboardActionType, DashboardProvider, useDashboardContext } from './Context';
import { MonthView } from './MonthView';
import PatientDetails from './PatientDetails';
import { PatientForm } from './PatientForm';
import { PopUpMessage } from './PopUpMessage';
import { PeriodType } from './types';
import { WeekView } from './WeekView';

const styles = mergeStyleSets({
  persona: {
    selectors: {
      ':hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.neutralLighter,
      },
    },
  },
});

export const Dashboard: React.FunctionComponent = (props) => {
  const {
    state: {
      user: {login, filterText},
    },
    action: logoutAction,
  } = useStateMachine<GlobalState>(updateUser);

  const {
    state: {periodType, selectedDate, range, timeSlot, patient},
    dispatch,
  } = useDashboardContext();

  const searchBoxID = useId('searchBoxID');

  const [searchText, setSearchText] = React.useState(filterText);
  const [searchTextDB, setSearchTextDB] = React.useState(filterText);
  const [hiddenSidebar, setHiddenSidebar] = React.useState(true);
  const [hiddenAgenda, setHiddenAggenda] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [isTimeSlotOpen, setIsTimeSlotOpen] = React.useState(false);

  const [isPatientOpen, setIsPatientOpen] = React.useState(false);

  const [showPatientList, setShowPatientList] = React.useState(false);
  const [patients, setPatients] = React.useState<Patient[]>();

  const query = useQuery<{getTimeSlots: TimeSlot[]}, QuerygetTimeSlotsArgs>(getTimeSlots, {
    variables: {
      params: {
        filter: {
          range: {startDate: range.startDate.toSeconds(), endDate: range.endDate.toSeconds()},
          //text: searchTextDB,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getTimeSlots) {
        dispatch({type: DashboardActionType.LoadTimeSlots, timeSlots: data.getTimeSlots});
      }
    },
    onError: (error) => {
      setIsError(true);
    },
  });

  useQuery<{listPatients: Patient[]}, QuerylistPatientsArgs>(listPatients, {
    variables: {
      params: {
        filter: {
          text: searchTextDB,
        },
      },
    },
    skip: !searchTextDB || searchTextDB.length < 3,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.listPatients) {
        setShowPatientList(true);
        setPatients(data?.listPatients);
      }
    },
  });

  useDebounce(
    () => {
      setSearchTextDB(searchText);
    },
    1000,
    [searchText],
  );

  const {t, i18n} = useTranslation();

  React.useEffect(() => {
    document.title = t('page.dashboard.title');
  }, [t]);

  const onSearchChange = React.useCallback((_, newValue?: string) => {
    setSearchText(newValue || '');
  }, []);

  const onDateChange = React.useCallback(
    (newDate: Date) => {
      dispatch({
        type: DashboardActionType.Filter,
        selectedDate: DateTime.fromJSDate(newDate, {zone: settings.TIME_ZONE}),
      });
    },
    [dispatch],
  );

  useDebounce(
    () => {
      dispatch({type: DashboardActionType.Filter, searchTextDB: searchText});
    },
    500,
    [searchText],
  );

  const selectedJSDate = React.useMemo((): Date | undefined => {
    return selectedDate.toJSDate();
  }, [selectedDate]);

  const CommandBarItems = React.useMemo(() => {
    let text = t('error.unknown');
    if (login?.role === 'USER') return [];
    switch (periodType) {
      case PeriodType.MONTH:
        text = t(`calendar:month.${selectedDate.monthLong}`) + ', ' + selectedDate.year;
        break;
      case PeriodType.WORKWEEK:
      case PeriodType.WEEK:
        const sd = selectedDate.startOf('week');
        const ed = selectedDate.endOf('week');

        text =
          sd.day +
          ' ' +
          t(`calendar:shortMonth.${sd.monthLong}`) +
          ' - ' +
          ed.day +
          ' ' +
          t(`calendar:shortMonth.${ed.monthLong}`);
        break;
      case PeriodType.DAY:
        text =
          t(`calendar:day.${selectedDate.weekdayLong}`) +
          ', ' +
          selectedDate.day +
          ' ' +
          t(`calendar:shortMonth.${selectedDate.monthLong}`);
        break;
    }

    return [
      {
        key: 'left',
        iconOnly: true,
        cacheKey: 'left',
        iconProps: {iconName: 'ChevronLeft'},
        onClick: (): void => {
          dispatch({
            type: DashboardActionType.Filter,
            selectedDate: selectedDate.minus({
              [periodType === PeriodType.WORKWEEK ? PeriodType.WEEK : periodType]: 1,
            }),
          });
        },
      },
      {
        key: 'period',
        text,
        cacheKey: 'period',
      },
      {
        key: 'right',
        iconOnly: true,
        cacheKey: 'right',
        onClick: (): void => {
          dispatch({
            type: DashboardActionType.Filter,
            selectedDate: selectedDate.plus({
              [periodType === PeriodType.WORKWEEK ? PeriodType.WEEK : periodType]: 1,
            }),
          });
        },
        iconProps: {iconName: 'ChevronRight'},
      },
    ];
  }, [dispatch, login?.role, periodType, selectedDate, t]);

  const periodMenuProps = React.useMemo<IContextualMenuProps>(() => {
    return {
      isBeakVisible: false,
      alignTargetEdge: true,

      directionalHint: DirectionalHint.bottomLeftEdge,
      onItemClick: (_, item?): void => {
        item && dispatch({periodType: item.key as PeriodType, type: DashboardActionType.Filter});
      },
      items: [
        {
          key: PeriodType.MONTH,
          canCheck: true,
          checked: periodType === PeriodType.MONTH,
          text: t('menu.period.month'),
        },
        {
          key: PeriodType.WEEK,
          canCheck: true,
          checked: periodType === PeriodType.WEEK,
          text: t('menu.period.week'),
        },
        {
          key: PeriodType.WORKWEEK,
          canCheck: true,
          checked: periodType === PeriodType.WORKWEEK,
          text: t('menu.period.workweek'),
        },
        {
          key: PeriodType.DAY,
          canCheck: true,
          checked: periodType === PeriodType.DAY,
          text: t('menu.period.day'),
        },
      ],
    };
  }, [dispatch, periodType, t]);

  const userMenuProps = React.useMemo((): IContextualMenuProps => {
    const langMenu: IContextualMenuItem[] = LANGUAGES.map((key, idx) => {
      return {
        key: key,
        checked: key === i18n.language,
        text: t(`languages.${key}.name`),
        canCheck: true,
        onClick: (): void => {
          i18n.changeLanguage(key);
        },
      };
    });

    return {
      isBeakVisible: false,
      alignTargetEdge: true,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: [
        {
          key: 'lang',
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            bottomDivider: true,
            title: t('menu.languages'),
            items: langMenu,
          },
        },

        {
          key: 'logout',
          text: t('menu.logout'),
          iconProps: {iconName: 'Lock'},
          onClick: (): void => {
            logOut();
            logoutAction({login: undefined});
          },
        },
      ],
    };
  }, [i18n, logoutAction, t]);

  const DetailView = React.useMemo(() => {
    if (settings.TEST_FLAG) {
      let $component: React.FunctionComponent;
      if (login?.role === 'USER') return null;
      switch (periodType) {
        case PeriodType.MONTH:
          $component = MonthView;
          break;
        default:
          $component = WeekView;
          break;
      }
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <$component />
      );
    } else {
      if (login?.role === 'USER') return null;
      switch (periodType) {
        case PeriodType.MONTH:
          return <MonthView />;

        default:
          return <WeekView />;
      }
    }
  }, [login?.role, periodType]);

  if (!login) return <Redirect to={URL_LOGIN} />;
  if (query.loading) return <Spinner />;

  if (isError)
    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        actions={
          <MessageBarButton
            onClick={(): void => {
              query
                .refetch()
                .then((result) => {
                  dispatch({
                    type: DashboardActionType.LoadTimeSlots,
                    timeSlots: result.data.getTimeSlots,
                  });
                  setIsError(false);
                })
                .catch((e) => {
                  setIsError(true);
                });
            }}>
            {t('button.retry')}
          </MessageBarButton>
        }>
        {t('error.network')}
      </MessageBar>
    );

  return (
    <>
      <PopUpMessage />
      <Modal isOpen={isTimeSlotOpen} isBlocking onDismiss={(): void => setIsTimeSlotOpen(false)}>
        <AddSlot onClose={(): void => setIsTimeSlotOpen(false)} />
      </Modal>
      <Modal isOpen={isPatientOpen} isBlocking styles={{main: {padding: PADDING32}}}>
        <PatientForm
          readOnly={login.role !== Role.ADMIN}
          onDismiss={(): void => setIsPatientOpen(false)}
          onUpdate={(): void => setIsPatientOpen(false)}></PatientForm>
      </Modal>
      <Stack styles={{root: {height: '100vh', boxSizing: 'border-box'}}}>
        {login.role === Role.ADMIN && timeSlot && timeSlot.session && (
          <PatientDetails
            patientId={timeSlot.session.patient.id}
            onClose={(): void => dispatch({type: DashboardActionType.TimeSlot})}
          />
        )}
        {patient && (
          <PatientDetails
            readOnly={login.role !== Role.ADMIN}
            patientId={patient.id}
            onClose={(): void => dispatch({type: DashboardActionType.Patient})}
          />
        )}
        <StackItem>
          <Stack
            horizontal
            tokens={{padding: '0px 0px 0px 0px'}}
            styles={{
              root: {
                backgroundColor: theme.palette.themePrimary,
                height: SIZE_UNIT,
                color: theme.palette.white,
              },
            }}>
            <StackItem
              align='center'
              styles={{
                root: {
                  width: SIDEBAR_WIDTH,
                  paddingLeft: PADDING,
                  fontSize: FontSizes.xxLarge,
                  fontWeight: '500',
                  color: theme.palette.white,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
              }}>
              Juvenis
            </StackItem>
            <StackItem align='center' grow>
              <SearchBox
                id={searchBoxID}
                value={searchText}
                disabled={query.loading}
                onChange={onSearchChange}
                styles={{
                  root: {
                    maxWidth: SIDEBAR_WIDTH,
                  },
                }}
              />
              <Callout
                target={`#${searchBoxID}`}
                isBeakVisible={false}
                role='status'
                hidden={!showPatientList}
                calloutWidth={300}
                onDismiss={(): void => setShowPatientList(false)}
                directionalHint={DirectionalHint.bottomLeftEdge}>
                <Stack tokens={{childrenGap: PADDING, padding: PADDING}}>
                  {(!patients || patients.length === 0) && (
                    <MessageBar messageBarType={MessageBarType.warning}>
                      {t('message.noResults')}
                    </MessageBar>
                  )}
                  {patients?.map((patient) => {
                    const firstName = patient.firstName || ' ';
                    const lastName = patient.lastName || ' ';
                    const initials = `${firstName[0]}${lastName[0]}`;
                    return (
                      <StackItem key={patient.id}>
                        <Persona
                          onClick={(): void =>
                            dispatch({type: DashboardActionType.Patient, patient})
                          }
                          className={styles.persona}
                          size={PersonaSize.size40}
                          text={`${firstName} ${lastName}`}
                          imageInitials={initials}
                        />
                      </StackItem>
                    );
                  })}
                </Stack>
              </Callout>
            </StackItem>
            <StackItem align='center'>
              <CommandButton
                menuProps={userMenuProps}
                styles={{
                  root: {color: theme.palette.white},
                  rootHovered: {color: theme.palette.white},
                  rootExpanded: {color: theme.palette.white},
                }}>
                {login.firstName} {login.lastName}
              </CommandButton>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <Stack
            horizontal
            tokens={{padding: '0px 0px 0px 0px'}}
            styles={{
              root: {
                backgroundColor: theme.palette.neutralLighterAlt,
                color: theme.palette.black,
              },
            }}>
            <StackItem
              align='center'
              styles={{
                root: {
                  height: SIZE_UNIT,
                  width: hiddenSidebar ? SIZE_UNIT : SIDEBAR_WIDTH,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: theme.palette.neutralQuaternaryAlt,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: theme.palette.neutralQuaternaryAlt,
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                },
              }}>
              <CommandBar
                styles={{
                  root: {padding: 0, backgroundColor: theme.palette.neutralLighterAlt},
                }}
                items={[
                  {
                    key: 'vis',
                    cacheKey: 'vis',
                    iconOnly: true,
                    iconProps: {iconName: 'GlobalNavButton'},
                    onClick: (): void => setHiddenSidebar(!hiddenSidebar),
                  },
                ]}
              />
            </StackItem>
            <StackItem
              align='center'
              grow
              styles={{
                root: {
                  height: SIZE_UNIT,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: theme.palette.neutralQuaternaryAlt,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                },
              }}>
              <div>
                <CommandBar
                  items={CommandBarItems}
                  styles={{root: {padding: 0, backgroundColor: theme.palette.neutralLighterAlt}}}
                />
              </div>
            </StackItem>
            <StackItem
              align='center'
              styles={{
                root: {
                  height: SIZE_UNIT,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: '1px',
                  borderBottomColor: theme.palette.neutralQuaternaryAlt,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                },
              }}>
              {login?.role !== 'USER' && (
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  <IconButton
                    iconProps={{iconName: 'AddFriend'}}
                    onClick={(): void => setIsPatientOpen(true)}
                  />
                  <IconButton
                    iconProps={{iconName: 'AddEvent'}}
                    onClick={(): void => setIsTimeSlotOpen(true)}
                  />
                  <IconButton iconProps={{iconName: 'Calendar'}} menuProps={periodMenuProps}>
                    {/*t(`menu.period.${periodType}`)*/}
                  </IconButton>
                </div>
              )}
            </StackItem>
          </Stack>
        </StackItem>

        <StackItem styles={{root: {height: `calc(100% - ${SIZE_2XUNIT})`}}}>
          <Stack horizontal styles={{root: {height: '100%', position: 'relative'}}}>
            <StackItem
              styles={{
                root: {
                  height: '100%',
                  width: hiddenSidebar ? 0 : SIDEBAR_WIDTH,
                  borderRightStyle: 'solid',
                  borderRightWidth: '1px',
                  borderRightColor: theme.palette.neutralQuaternaryAlt,
                  backgroundColor: theme.palette.neutralLighterAlt,
                  boxSizing: 'border-box',
                },
              }}>
              <SideBar
                selectedDate={selectedJSDate}
                onSelectDate={onDateChange}
                hidden={hiddenSidebar}
              />
            </StackItem>
            <StackItem grow>{DetailView}</StackItem>
            {!hiddenAgenda && (
              <StackItem
                styles={{
                  root: {
                    height: '100%',
                    width: AGENDA_WIDTH,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '1px',
                    borderLeftColor: theme.palette.neutralQuaternaryAlt,
                    boxSizing: 'border-box',
                  },
                }}>
                <Stack styles={{root: {height: '100%'}}}>
                  <StackItem grow styles={{root: {overflowX: 'hidden', overflowY: 'scroll'}}}>
                    <div style={{padding: PADDING}}>
                      <Agenda key='agenda'>
                        <NoteList />
                      </Agenda>
                    </div>
                  </StackItem>
                  <StackItem
                    styles={{
                      root: {
                        borderTopStyle: 'solid',
                        borderTopWidth: '1px',
                        boxSizing: 'border-box',
                        borderTopColor: theme.palette.neutralQuaternaryAlt,
                      },
                    }}>
                    <IconButton
                      iconProps={{iconName: 'OpenPaneMirrored'}}
                      onClick={(): void => {
                        setHiddenAggenda(true);
                      }}
                    />
                  </StackItem>
                </Stack>
              </StackItem>
            )}
            {hiddenAgenda && (
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '100%',
                  transform: 'translate(-100%, -100%)',
                }}>
                <IconButton
                  iconProps={{iconName: 'OpenPane'}}
                  onClick={(): void => {
                    setHiddenAggenda(false);
                  }}
                />
              </div>
            )}
          </Stack>
        </StackItem>
      </Stack>
    </>
  );
};

export const DashboardState: React.FunctionComponent = (props) => {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  );
};

export default DashboardState;
