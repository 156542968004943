interface Identifiable {
  id: number | string;
}

function ToggleArrayIndentifiable<T extends Identifiable>(source: Array<T>, key: T): Array<T> {
  const findIdx = source.findIndex((v) => v.id === key.id);
  if (findIdx === -1) {
    const copy = source.slice();
    copy.push(key);
    return copy;
  } else {
    return source.splice(findIdx);
  }
}

interface Comparable {
  compare: (value: Comparable) => number;
}

export const ToggleArray = <T extends Comparable>(source: Array<T>, key: T): Array<T> => {
  if (key === undefined) return source;
  const idx = source.findIndex((v) => v === key);
  if (idx === -1) {
    return [...source, key].sort((a, b) => (a === b ? 0 : a > b ? 1 : -1));
  }
  return source.filter((v) => v !== key);
};

export const ToggleArrayString = (source: Array<string>, key?: string): Array<string> => {
  if (key === undefined) return source;
  if (source === undefined) return [key];
  const idx = source.findIndex((v) => v === key);
  if (idx === -1) {
    return [...source, key].sort((a, b) => a.localeCompare(b));
  }
  return source.filter((v) => v !== key);
};

export default ToggleArrayIndentifiable;
