import { FontSizes, mergeStyleSets, Stack, StackItem } from '@fluentui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TimeSlot } from '../../generated/graphql';
import { theme } from '../../styles/theme';
import { PADDING } from './const';
import { DashboardActionType, useDashboardContext } from './Context';
import { Slot } from './Slot';
import { DatePosition } from './types';
import {settings} from '../../const/config';

const percentWidth = 100 / 7;

const styles = mergeStyleSets({
  header: {
    width: `${percentWidth}%`,
    textAlign: 'center',
    fontSize: FontSizes.small,
  },
  calendar: {
    width: '100%',
    height: 'calc(100% - 32px)',
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridAutoRows: '1fr',
    gridGap: 0,
  },
  day: {
    backgroundColor: theme.palette.themeLighterAlt,
    overflow: 'hidden',
    borderTop: '1px solid ' + theme.palette.neutralQuaternaryAlt,
    boxSizing: 'border-box',
    padding: '4px',
    fontSize: FontSizes.small,
    cursor: 'pointer',
  },
  today: {
    borderTop: '4px solid ' + theme.palette.themeDark,
    backgroundColor: theme.palette.themeLighterAlt,
  },
  selected: {
    backgroundColor: theme.palette.neutralLight,
  },
  mDay: {
    fontSize: FontSizes.medium,
  },
  appo: {
    borderRadius: '2px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginTop: '2px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    backgroundColor: theme.palette.themeLight,
    selectors: {
      ':hover': {
        backgroundColor: theme.palette.themeSecondary,
        color: theme.palette.white,
      },
    },
  },
  pending: {
    borderLeft: '4px solid ' + theme.palette.greenDark,
  },
  request: {
    borderLeft: '4px solid ' + theme.palette.redDark,
  },
});

export const MonthView: React.FunctionComponent = (props) => {
  const {t} = useTranslation();
  const {
    state: {selectedDate, timeSlots, range},
    dispatch,
  } = useDashboardContext();

  const refCalendar = React.useRef<HTMLDivElement>(null);

  const daysCell = React.useMemo(() => {
    if (!selectedDate || !range || !timeSlots) return null;
    const {startDate, endDate} = range;
    const days = endDate.plus({millisecond: 1}).diff(startDate).milliseconds / 1000 / 86400;

    const percentHeight = 100 / (days / 7);

    const dayPositions: DatePosition[] = [];
    let weekNumber = 0;
    for (let dt = startDate; dt < endDate; dt = dt.plus({day: 1})) {
      const {weekday} = dt;
      const top = weekNumber * percentHeight;
      const left = (weekday - 1) * percentWidth;
      dayPositions.push({
        date: dt,
        position: {
          top: `${top}%`,
          bottom: `${100 - top - percentHeight}%`,
          left: `${left}%`,
          right: `${100 - left - percentWidth}%`,
        },
      });
      if (weekday === 7) weekNumber++;
    }

    let pMonth = -1;

    const mapTimeSlot: Record<number, TimeSlot[]> = {};
    for (const ts of timeSlots) {
      const d = DateTime.fromSeconds(ts.date,{zone:settings.TIME_ZONE});
      const idx = d.month * 100 + d.day;
      if (mapTimeSlot[idx]) {
        mapTimeSlot[idx].push(ts);
      } else {
        mapTimeSlot[idx] = [ts];
      }
    }

    const td = DateTime.fromObject({zone:settings.TIME_ZONE}).startOf('day');

    return dayPositions.map((dayP, idx) => {
      const {date} = dayP;
      const isNewMonth = date.month !== pMonth;
      pMonth = date.month;
      const dayTs = mapTimeSlot[pMonth * 100 + date.day] || [];

      const datClass = [styles.day];
      const isInPast = date.toSeconds() <= td.toSeconds();
      if (date.toSeconds() === td.toSeconds()) datClass.push(styles.today);
      if (date.toSeconds() === selectedDate.toSeconds()) datClass.push(styles.selected);
      return (
        <div
          key={idx}
          className={datClass.join(' ')}
          onClick={(): void => dispatch({type: DashboardActionType.Filter, selectedDate: date})}>
          <>
            <div className={styles.mDay}>
              {isNewMonth && t(`calendar:shortMonth.${date.monthLong}`)} {date.day}
            </div>
            {dayTs.map((v, idx) => {
              return !isInPast || v.session ? (
                <Slot
                  key={idx}
                  ts={v}
                  onClick={(): void => dispatch({type: DashboardActionType.TimeSlot, timeSlot: v})}
                />
              ) : null;
            })}
          </>
        </div>
      );
    });
  }, [selectedDate, range, timeSlots, t, dispatch]);

  if (!timeSlots || !selectedDate || !range) return null;

  return (
    <Stack styles={{root: {height: '100%'}}}>
      <StackItem>
        <Stack horizontal tokens={{childrenGap: PADDING, padding: PADDING}}>
          <StackItem className={styles.header}>{t('calendar:day.Monday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Tuesday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Wednesday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Thursday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Friday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Saturday')}</StackItem>
          <StackItem className={styles.header}>{t('calendar:day.Sunday')}</StackItem>
        </Stack>
      </StackItem>
      <StackItem grow>
        <div className={styles.calendar} ref={refCalendar}>
          {daysCell}
        </div>
      </StackItem>
    </Stack>
  );
};

export default MonthView;
