import React, {FC, useEffect} from 'react';
import Page from '../templates/Page';
import {useTranslation} from 'react-i18next';
import {theme} from '../styles/theme';
import {MessageBar, MessageBarType, Stack, StackItem} from '@fluentui/react';
import {useMutation} from '@apollo/react-hooks';
import {MutationunsubscribeArgs} from '../generated/graphql';
import {unsubscribe} from '../graphql/mutations';
import {useParams} from 'react-router';

export const UnsubscribeHelper: FC = () => {
  const {t} = useTranslation();
  const params = useParams<{email: string}>();

  const [runMutation] = useMutation<boolean, MutationunsubscribeArgs>(
    unsubscribe,
  );

  useEffect(() => {
    runMutation({variables: params}).then();
  }, [runMutation, params]);

  return <Page title={t('page.unsubscribe.title')}>
    <Stack tokens={{childrenGap: theme.spacing.s2}}>
      <StackItem>
        <MessageBar messageBarType={MessageBarType.success} isMultiline={true}>
          {t('message.unsubscribed')}
        </MessageBar>
      </StackItem>
    </Stack>
  </Page>;
};
export default UnsubscribeHelper;