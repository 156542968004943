import { DateTime } from 'luxon';

import { SessionStatus, UserWithTokens } from '../generated/graphql';
import { PeriodType } from '../pages/Dashboard/types';
import { GlobalState } from './globalState';

export const updateUser = (state: GlobalState, payload: Partial<UserState>): GlobalState => {
  return {
    ...state,
    user: {
      ...state.user,
      ...payload,
    },
  };
};

export type UserState = {
  login?: UserWithTokens;
  selectedDate: DateTime;
  periodType: PeriodType;
  filterStatus: SessionStatus[];
  filterText: string;
};
