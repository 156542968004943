import gql from 'graphql-tag';

const query = gql`
  mutation saveTreatmentNote($version: Int, $id: Int!, $params: TreatmentInjectionInput!) {
    saveTreatmentNote(version: $version, id: $id, params: $params) {
      clientTXRequest
      clientTXRequestNote
      clinicalCondition
      clinicalConditionNote
      contraIndications
      treatmentPlan
      treatmentPlanNote
      sideEffectsExplained
      optimalResultExplained
      questionsAnswered
      reactions
      reactionsNote
      instructionsExplained
      instructionsSent
      invoiceSent
      protocolAdjustedTreatment
      followUp
      nextTreatment
      nextTreatmentNote
      schema
      schemaBase
      treatmentProducts {
        units
        spots {
          spot
          units
        }
        anesthesia
        note
        productId
        productVariantId
      }
    }
  }
`;
export default query;
