import { DateTime } from 'luxon';

export type DateTimeRange = {
  startDate: DateTime;
  endDate: DateTime;
};

export enum PeriodType {
  DAY = 'day',
  WEEK = 'week',
  WORKWEEK = 'workweek',
  MONTH = 'month',
}

export type DatePosition = {
  date: DateTime;
  position: Record<'top' | 'bottom' | 'left' | 'right', string>;
};
