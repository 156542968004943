import { FontSizes, IconButton, mergeStyleSets, MessageBar, Modal, ProgressIndicator } from '@fluentui/react';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { settings } from '../const/config';
import { Photo, PhotoType } from '../generated/graphql';
import { UploadedFile } from '../types/UploadedFile';

const styles = mergeStyleSets({
  root: {
    width: '100%',
    minHeight: '100px',
    position: 'relative',
    border: '1px dashed gray',
  },

  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: FontSizes.xxLarge,
    transform: 'translate(-50%,-50%)',
    userSelect: 'none',
    cursor: 'pointer',
  },

  modal: {
    width: '300px',
    //height: '50px',
  },
});

interface ImageUploadProps {
  uploadURL: string;
  maxContentLength?: number;
  token?: string;
  sampleImage: string;
  imageType: PhotoType;
  imageName: string;
  onChange?: (fileType: PhotoType, file?: Partial<Photo>) => void;
  value?: Partial<Photo>;
}

const ImageUpload: React.FunctionComponent<ImageUploadProps> = (props: ImageUploadProps) => {
  const {
    uploadURL,
    maxContentLength,
    token,
    imageName,
    sampleImage,
    imageType,
    onChange,
    value,
  } = props;

  const {t} = useTranslation();
  const source = useMemo(() => axios.CancelToken.source(), []);

  const [isOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [progress, setProgress] = React.useState(0);

  const config: AxiosRequestConfig = useMemo(() => {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxContentLength,
      cancelToken: source.token,
      onUploadProgress: (progressEvent: ProgressEvent): void => {
        if (progressEvent.lengthComputable) {
          setProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
        }
      },
    };
  }, [maxContentLength, source.token]);

  const onDrop = React.useCallback(
    async (acceptedFiles) => {
      setOpen(true);
      setError(undefined);
      const fd = new FormData();
      token && fd.append('token', token);
      acceptedFiles.forEach((file: File, index: number) => {
        fd.append('File' + index, file);
      });
      try {
        const response = await axios.post<UploadedFile[]>(uploadURL, fd, config);
        const {data} = response;
        if (onChange && data && data instanceof Array && data.length > 0) {
          const fileUp = data[0] as UploadedFile;
          onChange(imageType, {
            fileCDN: fileUp.uploadedName,
            fileName: fileUp.originalName,
            type: imageType,
          });
        }
      } catch (err) {
        setError(t('error.uploadFailed'));
      } finally {
        setOpen(false);
      }
    },
    [config, imageType, onChange, t, token, uploadURL],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false,
    accept: ['image/jpeg', 'image/png', 'image/gif'],
  });

  return (
    <div style={{border: '1px solid gray'}}>
      <input {...getInputProps()} />
      <Modal isModeless={false} isBlocking={true} isOpen={isOpen}>
        <div className={styles.modal}>
          <MessageBar
            isMultiline={false}
            onDismiss={(): void => {
              source.cancel();
              setOpen(false);
            }}
            dismissButtonAriaLabel={t('button.cancel')}
            truncated={false}>
            <ProgressIndicator
              label={t('message.progress')}
              percentComplete={progress}
              styles={{root: {width: '200px'}}}
            />
          </MessageBar>
        </div>
      </Modal>
      <div
        {...getRootProps()}
        style={{
          cursor: 'pointer',
          background: value
            ? `url(${settings.URL_UPLOAD_FILES + value.fileCDN}) no-repeat center center`
            : undefined,
          backgroundSize: value ? 'cover' : undefined,
        }}>
        <img
          alt=''
          src={sampleImage}
          style={{
            maxWidth: '100%',
            padding: 0,
            margin: 'auto',
            maxHeight: '100%',
            visibility: value ? 'hidden' : 'visible',
          }}
        />
      </div>
      <div
        style={{
          display: 'table',
          width: '100%',
          backgroundColor: error ? 'rgba(163,0,0,1)' : 'rgba(154, 140, 152, 1)',
          color: 'rgba(234, 234, 234, 1)',
        }}>
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'middle',
            width: '100%',
            padding: '0 4px 0 4px',
            userSelect: 'none',
            textTransform: 'uppercase',
            fontWeight: 900,
            textDecoration: 'none',
          }}>
          {error !== undefined ? error : isDragActive ? t('file.drop') : imageName}
        </div>
        <div
          style={{
            display: 'table-cell',
            verticalAlign: 'bottom',
            whiteSpace: 'pre',
          }}>
          <IconButton
            style={{visibility: value ? 'visible' : 'hidden'}}
            iconProps={{iconName: 'Delete'}}
            onClick={(): void => {
              onChange && onChange(imageType, undefined);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageUpload;
export {ImageUpload};
