import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import React from 'react';

import { settings } from '../const/config';

type State = {hasError: boolean; error?: Error};

settings.LOG_ROCKET && LogRocket.init(settings.LOG_ROCKET);

settings.SENTRY_URL &&
  Sentry.init({
    dsn: settings.SENTRY_URL,
  });

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

class ErrorBoundary extends React.Component<unknown, State> {
  constructor(props: unknown) {
    super(props);
    this.state = {hasError: false, error: undefined};
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: Error, errorInfo: {[key: string]: any}): void {
    Sentry.captureException(error, {extra: errorInfo});
    this.setState({hasError: true, error});
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>
            Unexpected Error <b>:(</b>
          </h1>
          <h2>
            <p>
              You can go back to our <a href='/'>Homepage</a>.
            </p>
          </h2>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
