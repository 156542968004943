import { useQuery } from '@apollo/react-hooks';
import {
  DefaultButton,
  Label,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Questions from '../../components/Questions';
import { QuestionaryForm, ToggleKeys } from '../../const/questionary';

import { Question, Toggle } from '../../generated/graphql';
import { getQuestions } from '../../graphql/queries';
import Spinner from '../../organisms/Spinner';
import { theme } from '../../styles/theme';
import MultiPageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { convertFieldErrorToString } from '../../utils/convertToFormErrors';
import { StageComponent } from './Stages';

const NotNumber = /([^0-9]+)/g;

export const StageQuestionary: StageComponent = (props) => {
  const {session, step, onNext, onPrev} = props;
  const [isError, setIsError] = React.useState(false);
  const [questions, setQuestions] = React.useState<Question[]>();
  const {
    t,
    i18n: {language},
  } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const query = useQuery<{getQuestions: Question[]}>(getQuestions, {
    variables: {language, isExistingClient: session.patient?.existingClient === Toggle.YES},
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.getQuestions) {
        setQuestions(
          data.getQuestions.sort((q1, q2) => {
            return parseInt(q1.code.replace(NotNumber, '')) >
              parseInt(q2.code.replace(NotNumber, ''))
              ? 1
              : -1;
          }),
        );
      }
    },
    onError: (error) => {
      setIsError(true);
    },
  });

  const QuestionarySchema = React.useMemo(
    () =>
      yup.object<QuestionaryForm>().shape({
        answers: yup
          .array()
          //.required()
          .of(
            yup.object().shape({
              questionCode: yup
                .string()
                .required()
                .matches(/[A-z0-9]+/),
              answer: yup.string().oneOf(ToggleKeys),
            }),
          ),
        commentHealth: yup.string().nullable(),
      }),
    [],
  );

  const {
    handleSubmit,
    formState,
    formState: {errors},
    control,
  } = useForm<QuestionaryForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      answers: session.answers?.length ? session.answers : undefined,
      commentHealth: session.commentHealth || '',
    },
    resolver: yupResolver(QuestionarySchema),
    criteriaMode: 'all',
  });

  const onSubmit = React.useCallback(
    (values) => {
      onNext && onNext(step, {...values});
    },
    [onNext, step],
  );

  const {isSubmitting, isValid} = formState;

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton
        disabled={isSubmitting || !isValid}
        onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [isSubmitting, isValid, t, handleSubmit, onSubmit],
  );

  const prevButton = React.useMemo(() => {
    return onPrev ? (
      <DefaultButton disabled={isSubmitting} onClick={async (): Promise<void> => onPrev()}>
        {t('button.previous')}
      </DefaultButton>
    ) : undefined;
  }, [isSubmitting, onPrev, t]);

  if (query.loading) return <Spinner />;

  if (isError)
    return (
      <Page>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          actions={
            <MessageBarButton
              onClick={(): void => {
                query
                  .refetch()
                  .then((result) => {
                    setQuestions(result.data.getQuestions);
                    setIsError(false);
                  })
                  .catch((e) => {
                    setIsError(true);
                  });
              }}>
              {t('button.retry')}
            </MessageBarButton>
          }>
          {t('page.session.error.network')}
        </MessageBar>
      </Page>
    );

  return (
    <Page title={t('page.session.title')}>
      <MultiPageForm
        nextButton={nextButton}
        prevButton={prevButton}
        title={t(`page.session.${step}.title`)}>
        <Stack tokens={{childrenGap: theme.spacing.m}}>
          <StackItem>{t(`page.session.${step}.headerNote`)}</StackItem>
          <StackItem>{t(`page.session.${step}.header`)}</StackItem>
          <StackItem>
            <table className='questionary'>
              <thead>
                <tr>
                  <th>{t('field.questions')}</th>
                  <th>{t('option.yes')}</th>
                  <th>{t('option.no')}</th>
                </tr>
              </thead>
              <Controller
                control={control}
                name='answers'
                render={({field: {onChange}}) => (
                  <Questions questions={questions} onChange={onChange} />
                )}
              />
            </table>
          </StackItem>
          <StackItem>
            <Label>{t(`page.session.${step}.comment`)}</Label>
            <Controller
              name='commentHealth'
              control={control}
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  onChange={(_, newValue) => onChange(newValue)}
                  onBlur={onBlur}
                  value={value || ''}
                  multiline
                  rows={3}
                  errorMessage={convertFieldErrorToString(errors.commentHealth)}
                />
              )}
            />
          </StackItem>
          <StackItem>{t(`page.session.${step}.footer`)}</StackItem>
        </Stack>
      </MultiPageForm>
    </Page>
  );
};

export default StageQuestionary;
