import {DatePicker, DayOfWeek, Dropdown, IDropdownOption, mergeStyleSets} from '@fluentui/react';
import {DateTime} from 'luxon';
import React from 'react';

import {PADDING} from '../pages/Dashboard/const';
import {settings} from '../const/config';

export interface TimePickerProps {
  value?: DateTime;
  onChange?: (value: DateTime) => void;
  minHour?: number;
  maxHour?: number;
  stepMinutes: number;
}

const styles = mergeStyleSets({
  root: {
    display: 'grid',
    width: '300px',
    gridTemplateColumns: 'repeat(4, auto)',
    gap: '4px',
  },
  date: {
    marginRight: PADDING,
  },
  select: {
    maxWidth: '100px',
  },
  dots: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const TimePicker: React.FunctionComponent<TimePickerProps> = (props) => {
  const {value, onChange, stepMinutes, minHour, maxHour} = props;
  const d = value || DateTime.fromObject({zone: settings.TIME_ZONE}).startOf('hour');
  const minT = d.startOf('day').plus({hour: minHour || 8});
  const maxT = d.startOf('day').plus({hour: maxHour || 20, minute: 60 - stepMinutes, second: 0});

  const hourOptions = React.useMemo(() => {
    const ret: IDropdownOption[] = [];

    for (let dt = minT; dt < maxT; dt = dt.plus({hour: 1})) {
      ret.push({key: dt.hour, text: String(dt.hour).padStart(2, '0')});
    }

    return ret;
  }, [maxT, minT]);

  React.useEffect(() => {
    if (d < minT) {
      onChange && onChange(minT);
    } else if (d > maxT) {
      onChange && onChange(maxT);
    }
  }, [d, maxT, minT, onChange]);

  const minuteOptions = React.useMemo(() => {
    const ret: IDropdownOption[] = [];
    for (let i = 0; i < 60; i += stepMinutes) {
      ret.push({key: i, text: String(i).padStart(2, '0')});
    }
    return ret;
  }, [stepMinutes]);

  return (
    <div className={styles.root}>
      <div className={styles.date}>
        <DatePicker
          disableAutoFocus={true}
          showMonthPickerAsOverlay={true}
          firstDayOfWeek={DayOfWeek.Monday}
          value={d.toJSDate()}
          onSelectDate={(date): void => {
            if (date) {
              onChange &&
              onChange(
                DateTime.fromJSDate(date,{zone:settings.TIME_ZONE}).startOf('day').plus({hour: d.hour, minute: d.minute}),
              );
            }
          }}
        />
      </div>
      <div className={styles.select}>
        <Dropdown
          options={hourOptions}
          selectedKey={Math.min(Math.max(d.hour, minT.hour), maxT.hour)}
          onChange={(_, selectedHour): void => {
            const diff = d.hour - Number(selectedHour?.key);
            onChange && onChange(d.minus({hour: diff}));
          }}
        />
      </div>
      <div className={styles.dots}>:</div>
      <div className={styles.select}>
        <Dropdown
          options={minuteOptions}
          selectedKey={Math.floor(d.minute / stepMinutes) * stepMinutes}
          onChange={(_, selectedMinute): void => {
            onChange && onChange(d.startOf('hour').plus({minute: Number(selectedMinute?.key)}));
          }}
        />
      </div>
    </div>
  );
};

export default TimePicker;
