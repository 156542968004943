import { useMutation } from '@apollo/react-hooks';
import { Label, MessageBar, MessageBarType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { settings, URL_DASHBOARD } from '../const/config';
import { MutationloginArgs, UserWithTokens } from '../generated/graphql';
import { login } from '../graphql/mutations';
import { setToken, TokenType } from '../state';
import { updateUser } from '../state/userState';
import { theme } from '../styles/theme';
import Page from '../templates/Page';
import { convertErrors, convertFieldErrorToString } from '../utils/convertToFormErrors';

type FormFields = {
  email: string;
  password: string;
};

type FormFieldNames = Extract<keyof FormFields, string>;

export const Login: React.FunctionComponent = (props) => {
  const {t} = useTranslation();
  const {action} = useStateMachine(updateUser);
  const history = useHistory();
  const location = useLocation();
  const isMounted = useRef<boolean>();
  useEffect(() => {
    isMounted.current = true;
    setToken(TokenType.ACCESS, '');
    setToken(TokenType.REFRESH, '');
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  const [globalErrors, setGlobalErrors] = React.useState<Error[] | undefined>();
  const [runMutation] = useMutation<{login: UserWithTokens}, MutationloginArgs>(login);

  const LoginSchema = React.useMemo(
    () =>
      yup.object<FormFields>().shape({
        email: yup
          .string()
          .required(t('constraints:isNotEmpty', {context: 'email'}))
          .email(t('constraints:isEmail')),
        password: yup.string().required(t('constraints:isNotEmpty')),
      }),
    [t],
  );

  const {
    handleSubmit,
    formState,
    formState: {errors},
    control,
    setError,
  } = useForm<FormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {email: settings.DEV_PREFILL_LOGIN, password: settings.DEV_PREFILL_PASSWORD},
    resolver: yupResolver(LoginSchema),
    criteriaMode: 'all',
  });
  const {isSubmitting} = formState;

  const onSubmit = React.useMemo(
    () =>
      handleSubmit((values: FormFields) => {
        let globalErrors: Error[] | undefined;
        return new Promise<void>(async (resolve, reject) => {
          try {
            const {data} = await runMutation({
              variables: {params: {...values}},
            });
            if (data) {
              const {login} = data;
              setToken(TokenType.ACCESS, login.accessToken);
              setToken(TokenType.REFRESH, login.refreshToken);
              action({login: {...login}});
              const prevLocation = location.state as Location;
              history.replace(
                prevLocation?.hasOwnProperty('pathname') &&
                  prevLocation.pathname !== location.pathname
                  ? prevLocation.pathname + prevLocation.search + prevLocation.hash
                  : URL_DASHBOARD,
              );
            }
          } catch (e) {
            const errors = convertErrors(e, t);
            errors.fieldErrors?.forEach((e) => {
              setError(e.property as FormFieldNames, {types: e.constraints});
            });
            const ReCaptchaIndex = errors.fieldErrors?.findIndex(
              (value) => 'ReCaptchaConstraint' in value.constraints,
            );
            globalErrors = errors.globalErrors;
            if (ReCaptchaIndex !== undefined && ReCaptchaIndex !== -1) {
              if (globalErrors === undefined) globalErrors = [];
              globalErrors.push({
                name: 'ReCaptchaConstraint',
                message: t('constraints:ReCaptchaConstraint'),
              });
            }
          } finally {
            isMounted.current && setGlobalErrors(globalErrors);
            resolve();
          }
        });
      }),
    [action, handleSubmit, history, location.pathname, location.state, runMutation, setError, t],
  );

  return (
    <Page title={t('page.login.title')}>
      <form onSubmit={onSubmit} className='formCompact'>
        <Stack tokens={{childrenGap: theme.spacing.s2}}>
          {globalErrors && (
            <StackItem>
              <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                {globalErrors.map((error, idx) => (
                  <div key={error.name}>{error.message}</div>
                ))}
              </MessageBar>
            </StackItem>
          )}
          <StackItem>
            <Label required>{t('field.email')}:</Label>
            <Controller
              name='email'
              control={control}
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  onBlur={onBlur}
                  autoComplete='email'
                  onChange={(_, value) => onChange(value)}
                  disabled={isSubmitting}
                  value={value}
                  errorMessage={convertFieldErrorToString(errors.email)}
                />
              )}
            />
          </StackItem>
          <StackItem>
            <Label required>{t('field.password')}:</Label>
            <Controller
              control={control}
              name='password'
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  autoComplete='current-password'
                  type='password'
                  onBlur={onBlur}
                  disabled={isSubmitting}
                  value={value}
                  onChange={(_, value) => onChange(value)}
                  errorMessage={convertFieldErrorToString(errors.password)}
                />
              )}
            />
          </StackItem>
          <StackItem>
            <PrimaryButton type='submit' disabled={isSubmitting}>
              {t('button.continue')}
            </PrimaryButton>
          </StackItem>
        </Stack>
      </form>
    </Page>
  );
};

export default Login;
