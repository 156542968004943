import { DefaultButton, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Toggle } from '../../generated/graphql';
import { theme } from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { PADDING } from '../Dashboard/const';
import { StageComponent } from './Stages';

export const StageTerms: StageComponent = (props) => {
  const {session, step, onNext, onPrev, type, isLastOne} = props;
  const {t} = useTranslation();
  const [didAgree] = React.useState(true);

  const stageStep=session.patient?.existingClient===Toggle.YES ? `${step}_existing` : step;

  const onSubmit = React.useCallback(
    (values) => {
      onNext && onNext(step, {});
    },
    [onNext, step],
  );

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton disabled={!didAgree} onClick={async (): Promise<void> => onSubmit({})}>
        {t(isLastOne ? 'button.confirm' : 'button.next', {context: type})}
      </PrimaryButton>
    ),
    [didAgree, t, isLastOne, type, onSubmit],
  );

  const prevButton = React.useMemo(() => {
    return onPrev ? (
      <DefaultButton onClick={async (): Promise<void> => onPrev()}>
        {t('button.previous')}
      </DefaultButton>
    ) : undefined;
  }, [onPrev, t]);

  return (
    <Page title={t('page.session.title')}>
      <MultipageForm
        nextButton={nextButton}
        prevButton={prevButton}
        title={t(`page.session.${stageStep}.title`)}>
        <Stack tokens={{childrenGap: theme.spacing.m}}>
          <StackItem>{t(`page.session.${stageStep}.header`)}</StackItem>
          <StackItem className='justified' styles={{root: {padding: PADDING}}}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={t(`page.session.${stageStep}.text`)} />
          </StackItem>
        </Stack>
      </MultipageForm>
    </Page>
  );
};

export default StageTerms;
