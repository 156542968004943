import gql from 'graphql-tag';

const query = gql`
  mutation changeSessionTime($timeSlotId: ID, $sessionCode: String!) {
    changeSessionTime(timeSlotId: $timeSlotId, sessionCode: $sessionCode) {
      id
      version
    }
  }
`;

export default query;
