import { ErrorHandler, ErrorResponse } from 'apollo-link-error';

import { getNewToken } from '..';
import { getToken, logOut, setToken, TokenType } from '../state';
import {Observable} from 'apollo-client/util/Observable';

export const onApolloError: ErrorHandler = (error: ErrorResponse) => {
  const {graphQLErrors, operation, forward} = error;
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.message) {
        case 'error.AccessDenied':
        case 'error.InvalidToken':
          logOut();
          return;
        case 'error.ExpiredToken':
          const token = getToken(TokenType.REFRESH);
          if (!token) {
            logOut();
            return;
          }
          return new Observable((observer) => {
            getNewToken(token)
              .then((newToken) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: `Bearer ${newToken}` || null,
                  },
                });
                setToken(TokenType.ACCESS, newToken);
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                forward(operation).subscribe(subscriber);
              })
              .catch(() => {
                observer.error(error);
                logOut();
              });
          });
      }
    }
  }
  return;
};
