import { settings } from '../const/config';
import db from '../idb';
import { UploadedFile } from '../types/UploadedFile';

export const resolveUpload = async (file: UploadedFile): Promise<UploadedFile> => {
  if (file.uploadedName.startsWith('idb:')) {
    const id = file.uploadedName.replace(/^idb:/, '');
    const o = await db.uploads.get({id});
    return {...file, uri: o?.data || ''};
  }
  return {...file, uri: settings.URL_UPLOAD_FILES + file.uploadedName};
};
export default resolveUpload;
