import gql from 'graphql-tag';

const query = gql`
  query listPatients($params: ListPatientsInput!) {
    listPatients(params: $params) {
      id
      version
      email
      phone
      firstName
      lastName
      gender
      birthday
      comment
      existingClient
      language
    }
  }
`;

export default query;
