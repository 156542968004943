import gql from 'graphql-tag';

const query = gql`
  mutation login($params: LoginRequest!) {
    login(params: $params) {
      id
      version
      email
      firstName
      lastName
      role
      accessToken
      refreshToken
    }
  }
`;
export default query;
