import { useQuery } from '@apollo/react-hooks';
import {
  FontSizes,
  IconButton,
  mergeStyleSets,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Stack,
  StackItem,
} from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Note, QuerygetNotesArgs, Toggle } from '../generated/graphql';
import getNotes from '../graphql/queries/getNotes';
import { theme } from '../styles/theme';
import { ErrorType } from '../types/ErrorType';
import NoteEdit from './NoteEdit';
import Spinner from './Spinner';

const styles = mergeStyleSets({
  note: {
    fontSize: FontSizes.mediumPlus,
    paddingBottom: theme.spacing.m,
    paddingTop: theme.spacing.s1,
    borderTop: `1px solid ${theme.palette.blackTranslucent40}`,
  },
});

const NoteList: React.FunctionComponent = (props) => {
  const [error, setError] = React.useState<ErrorType>();
  const [notes, setNotes] = React.useState<Note[]>();
  const [editNote, setEditNote] = React.useState<Note>();
  const {t} = useTranslation();

  const onSuccessLoad = React.useCallback((loadedNotes: Note[]) => {
    if (!loadedNotes) {
      return;
    }
    setError(undefined);
    setNotes(loadedNotes);
  }, []);

  const query = useQuery<{getNotes: Note[]}, QuerygetNotesArgs>(getNotes, {
    variables: {
      params: {filter: {}},
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccessLoad(data.getNotes);
    },
    onError: (error) => {
      setError(error.networkError ? ErrorType.NETWORK : ErrorType.TOKEN);
    },
  });

  const onNoteSave = React.useCallback(
    (note: Note) => {
      if (notes) {
        if (editNote?.id) {
          const idx = notes.findIndex((value) => String(value.id) === String(note.id));

          if (idx !== -1) {
            const newNotes = [...notes];
            newNotes[idx] = note;
            setNotes(newNotes);
          } else {
            setNotes([note, ...notes]);
          }
        } else {
          setNotes([note, ...notes]);
        }
      } else {
        setNotes([note]);
      }
      setEditNote(undefined);
    },
    [editNote, notes],
  );

  const onNoteDismiss = React.useCallback(() => {
    setEditNote(undefined);
  }, []);

  if (error) {
    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        isMultiline={true}
        actions={
          <MessageBarButton
            onClick={(): void => {
              query
                .refetch()
                .then((result) => onSuccessLoad(result.data.getNotes))
                .catch((e) => {
                  setError(ErrorType.NETWORK);
                });
            }}>
            {t('button.retry')}
          </MessageBarButton>
        }>
        {t('page.session.error.network')}
      </MessageBar>
    );
  }

  if (notes === undefined) return <Spinner />;

  return (
    <div>
      <Stack>
        <StackItem>
          <Stack>
            {editNote !== undefined && !editNote.id ? (
              <NoteEdit onUpdate={onNoteSave} note={editNote} onDismiss={onNoteDismiss} />
            ) : (
              <IconButton
                iconProps={{iconName: 'AddNotes'}}
                onClick={() => {
                  setEditNote({archived: Toggle.NO, text: ''} as Note);
                }}
              />
            )}
          </Stack>
        </StackItem>
        {notes.map((note) => (
          <StackItem key={note.id} className={styles.note}>
            {editNote?.id === note.id ? (
              <NoteEdit note={note} onUpdate={onNoteSave} onDismiss={onNoteDismiss} />
            ) : (
              <>
                {note.text}
                <IconButton iconProps={{iconName: 'edit'}} onClick={() => setEditNote(note)} />
              </>
            )}
          </StackItem>
        ))}
      </Stack>
    </div>
  );
};

export default NoteList;
