import {Checkbox} from '@fluentui/react';
import useComponentSize from '@rehooks/component-size';
import {motion} from 'framer-motion';
import React, {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';

export type SpotType = {
  id: string;
  top: number;
  left: number;
  wholeFace?: boolean;
};

export type onSpotChangeFunc = (spotId: string, active: boolean) => void;
export type onSpotClickFunc = (spotId: string) => void;

type HotSpotImageProps = {
  mainSrc: string;
  markerSrc: string;
  markerSelectedSrc?: string;
  mappedWidth: number;
  markerRate?: number;
  spots: SpotType[];
  selected: string[];
  onSpotChange?: onSpotChangeFunc;
  onSpotClick?: onSpotClickFunc;
  hideUnselected?: boolean;
  showFullFace?: boolean;
};

const HotSpotImage: React.FunctionComponent<HotSpotImageProps> = (props) => {
  const {
    mainSrc,
    markerRate,
    markerSrc,
    markerSelectedSrc,
    mappedWidth,
    onSpotChange,
    selected,
    onSpotClick,
    spots,
    hideUnselected,
    showFullFace,
  } = props;
  const ref = useRef<HTMLImageElement>(null);
  const {width} = useComponentSize(ref);
  const markerWidth = Math.max(width * (markerRate || 0.05), 24);

  const {t} = useTranslation();

  const spotsAdjsted = useMemo<SpotType[]>(() => {
    const translateRate = width / mappedWidth;
    return spots.map((spot) => {
      const {top, left, ...data} = spot;
      return {top: top * translateRate, left: left * translateRate, ...data};
    });
  }, [mappedWidth, spots, width]);

  return (
    <div>
      <div style={{position: 'relative'}}>
        <img alt='' src={mainSrc} ref={ref} />
        {spotsAdjsted.map((spot) => {
          if (hideUnselected && !selected.includes(spot.id)) return null;
          if (spot.wholeFace) return null;
          return (
            <motion.div
              key={spot.id}
              initial={{x: '-50%', y: '-50%'}}
              onMouseOver={(): void => {
                onSpotChange && onSpotChange(spot.id, true);
              }}
              onMouseOut={(): void => {
                onSpotChange && onSpotChange(spot.id, false);
              }}
              onClick={(): void => {
                onSpotClick && onSpotClick(spot.id);
              }}
              whileHover={{scale: 1.5}}
              whileTap={{scale: 0.9}}
              className={'spot'}
              style={{
                top: spot.top,
                left: spot.left,
              }}>
              <img
                width={markerWidth}
                alt=''
                src={selected.includes(spot.id) ? markerSelectedSrc || markerSrc : markerSrc}
              />
            </motion.div>
          );
        })}
      </div>
      {showFullFace && (
        <div>
          <hr />
          <div className='other'>{t('field.other')}</div>
          <div className='spotsList'>
            {spots
              .filter((s) => {
                return !!s.wholeFace;
              })
              .map((s) => (
                <div key={s.id}>
                  <Checkbox
                    label={t(`spots.${s.id}.name`)}
                    checked={selected.includes(s.id)}
                    onChange={(_, checked): void => {
                      onSpotClick && onSpotClick(s.id);
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export {HotSpotImage};
