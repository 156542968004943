import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

interface DrawerProps {
  isOpen: boolean;
}

const Drawer: React.FunctionComponent<DrawerProps> = (props) => {
  const {isOpen, children} = props;
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: {opacity: 1, height: 'auto'},
            collapsed: {opacity: 0, height: 0},
          }}
          transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}>
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Drawer;
