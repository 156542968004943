import {
  ChoiceGroup,
  DatePicker,
  IChoiceGroupOption,
  Label,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import FieldError from '../../components/FieldError';
import {Gender, Patient} from '../../generated/graphql';
import {InlineChoiceItemStyle} from '../../styles/InlineChoiceStyle';
import {theme} from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import {PhoneRegexp} from '../../utils';
import {convertFieldErrorToString} from '../../utils/convertToFormErrors';
import {StageComponent, StageType} from './Stages';

export const StagePatient: StageComponent = (props) => {
  const {session, step, onNext, type} = props;

  const {t} = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ClientSchema = React.useMemo(() => {
    const rulesDetails =
      type === StageType.DETAILS
        ? {
            gender: yup
              .mixed()
              // .required(t('constraints:isNotEmpty'))
              .oneOf([Gender.MALE, Gender.FEMALE, Gender.X], t('constraints:isNotEmpty')),
            birthday: yup.date(),
          }
        : {};

    return yup.object<Patient>().shape({
      firstName: yup.string().nullable().required(t('constraints:isNotEmpty')),
      lastName: yup.string().nullable().required(t('constraints:isNotEmpty')),
      email: yup
        .string()
        .nullable()
        .trim()
        //.required(t('constraints:isNotEmpty'))
        .email(t('constraints:isEmail')),
      phone: yup
        .string()
        .trim()
        .nullable()
        .required(t('constraints:isNotEmpty'))
        .matches(PhoneRegexp, t('constraints:isPhone')),
      ...rulesDetails,
    });
  }, [t, type]);

  const genderOptions: IChoiceGroupOption[] = React.useMemo(
    () => [
      {
        key: Gender.FEMALE,
        text: t('option.gender.female'),
        styles: InlineChoiceItemStyle,
      },
      {
        key: Gender.MALE,
        text: t('option.gender.male'),
        styles: InlineChoiceItemStyle,
      },
    ],
    [t],
  );

  const {
    handleSubmit,
    formState,
    formState: {errors},
    control,
    watch,
  } = useForm<Pick<Patient, 'firstName' | 'lastName' | 'gender' | 'email' | 'phone' | 'birthday'>>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...session.patient,
      birthday: session.patient?.birthday
        ? new Date(
            typeof session.patient.birthday === 'number'
              ? session.patient.birthday * 1000
              : session.patient.birthday,
          )
        : undefined,
    },
    resolver: yupResolver(ClientSchema),
    criteriaMode: 'all',
  });

  const birthday: Date = watch('birthday');

  const onSubmit = React.useCallback(
    (values) => {
      onNext &&
        onNext(step, {
          patient: {...values, existingClient: session.patient?.existingClient},
        });
    },
    [onNext, session.patient, step],
  );

  const dayPickerStrings = React.useMemo(() => {
    return {
      months: [
        t('calendar:month.January'),
        t('calendar:month.February'),
        t('calendar:month.March'),
        t('calendar:month.April'),
        t('calendar:month.May'),
        t('calendar:month.June'),
        t('calendar:month.July'),
        t('calendar:month.August'),
        t('calendar:month.September'),
        t('calendar:month.October'),
        t('calendar:month.November'),
        t('calendar:month.December'),
      ],
      shortMonths: [
        t('calendar:shortMonth.January'),
        t('calendar:shortMonth.February'),
        t('calendar:shortMonth.March'),
        t('calendar:shortMonth.April'),
        t('calendar:shortMonth.May'),
        t('calendar:shortMonth.June'),
        t('calendar:shortMonth.July'),
        t('calendar:shortMonth.August'),
        t('calendar:shortMonth.September'),
        t('calendar:shortMonth.October'),
        t('calendar:shortMonth.November'),
        t('calendar:shortMonth.December'),
      ],
      days: [
        t('calendar:day.Sunday'),
        t('calendar:day.Monday'),
        t('calendar:day.Tuesday'),
        t('calendar:day.Wednesday'),
        t('calendar:day.Thursday'),
        t('calendar:day.Friday'),
        t('calendar:day.Saturday'),
      ],
      shortDays: [
        t('calendar:shortDay.Sunday'),
        t('calendar:shortDay.Monday'),
        t('calendar:shortDay.Tuesday'),
        t('calendar:shortDay.Wednesday'),
        t('calendar:shortDay.Thursday'),
        t('calendar:shortDay.Friday'),
        t('calendar:shortDay.Saturday'),
      ],
      goToToday: t('calendar:goToToday'),
      weekNumberFormatString: t('calendar:weekNumberFormatString'),
      prevMonthAriaLabel: t('calendar:prevMonthAriaLabel'),
      nextMonthAriaLabel: t('calendar:nextMonthAriaLabel'),
      prevYearAriaLabel: t('calendar:prevYearAriaLabel'),
      nextYearAriaLabel: t('calendar:nextYearAriaLabel'),
      prevYearRangeAriaLabel: t('calendar:prevYearRangeAriaLabel'),
      nextYearRangeAriaLabel: t('calendar:nextYearRangeAriaLabel'),
      closeButtonAriaLabel: 'calendar:closeButtonAriaLabel',
    };
  }, [t]);

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = new Date(birthday || '');
      let year = previousValue.getFullYear();
      let month = previousValue.getMonth();
      let day = previousValue.getDate();

      const newValueParts = (newValue || '').trim().split(/[^0-9]/);

      if (newValueParts.length > 0) {
        year = parseInt(newValueParts[0], 10);
        if (year < 100) year += 1900;
      }

      if (newValueParts.length > 1) {
        month = Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1;
      }

      if (newValueParts.length > 2) {
        day = Math.max(1, Math.min(31, parseInt(newValueParts[2], 10)));
      }

      return new Date(year, month, day);
    },
    [birthday],
  );

  const nextButton = useMemo(
    () => (
      <PrimaryButton onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [handleSubmit, onSubmit, t],
  );

  const {isSubmitting} = formState;

  // @ts-ignore
  return (
    <Page title={t(type === StageType.DETAILS ? 'page.session.title' : 'page.request.title')}>
      {type !== StageType.DETAILS && <h1>{t('page.request.text')}</h1>}
      <MultipageForm
        nextButton={nextButton}
        title={t(type === StageType.DETAILS ? `page.session.${step}.title` : 'page.request.step1')}>
        <form onSubmit={onSubmit} className='formCompact'>
          <Stack tokens={{childrenGap: theme.spacing.s2}}>
            <StackItem>
              <Label required>{t('field.firstName')}:</Label>
              <Controller
                name='firstName'
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextField
                    onChange={(_, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    value={value || ''}
                    disabled={isSubmitting}
                    errorMessage={convertFieldErrorToString(errors.firstName)}
                  />
                )}
              />
            </StackItem>
            <StackItem>
              <Label required>{t('field.lastName')}:</Label>
              <Controller
                name='lastName'
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextField
                    onChange={(_, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    value={value || ''}
                    disabled={isSubmitting}
                    errorMessage={convertFieldErrorToString(errors.lastName)}
                  />
                )}
              />
            </StackItem>

            <StackItem>
              <Label required>{t('field.phone')}:</Label>
              <Controller
                name='phone'
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextField
                    onChange={(_, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    value={value || ''}
                    placeholder={'514-000-0000'}
                    disabled={isSubmitting}
                    errorMessage={convertFieldErrorToString(errors.phone)}
                  />
                )}
              />
            </StackItem>

            <StackItem>
              <Label>{t('field.email')}:</Label>
              <Controller
                name='email'
                control={control}
                render={({field: {onChange, onBlur, value}}) => (
                  <TextField
                    onChange={(_, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    value={value || ''}
                    disabled={isSubmitting}
                    errorMessage={convertFieldErrorToString(errors.email)}
                  />
                )}
              />
            </StackItem>
            {type === StageType.DETAILS && (
              <>
                <StackItem>
                  <Label>{t('field.birthday')}:</Label>
                  <Controller
                    name='birthday'
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <DatePicker
                        allowTextInput
                        disabled={isSubmitting}
                        value={value}
                        openOnClick={false}
                        onBlur={onBlur}
                        textField={{placeholder: 'YYYY-MM-DD'}}
                        strings={dayPickerStrings}
                        onSelectDate={(date?: Date | null) => onChange(date || undefined)}
                        formatDate={(date?: Date): string => {
                          if (!date) return '';
                          const month = date.getMonth() + 1;
                          const day = date.getDate();
                          return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
                            day < 10 ? `0${day}` : day
                          }`;
                        }}
                        parseDateFromString={onParseDateFromString}
                      />
                    )}
                  />
                  {
                    errors.birthday && (
                    <FieldError>{
                      // @ts-ignore
                      convertFieldErrorToString(errors.birthday)}</FieldError>
                  )}
                </StackItem>

                <StackItem>
                  <Label>{t('field.gender')}:</Label>
                  <Controller
                    name='gender'
                    control={control}
                    render={({field: {onChange, onBlur, value}}) => (
                      <ChoiceGroup
                        onBlur={onBlur}
                        onChange={(_, newValue) => onChange(newValue?.key)}
                        options={genderOptions}
                        selectedKey={value || ''}
                        disabled={isSubmitting}
                      />
                    )}
                  />
                  {errors.gender && (
                    <FieldError>{convertFieldErrorToString(errors.gender)}</FieldError>
                  )}
                </StackItem>
              </>
            )}
          </Stack>
        </form>
      </MultipageForm>
    </Page>
  );
};

export default StagePatient;
