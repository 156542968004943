import { AnimationClassNames, getGlobalClassNames, getTheme, mergeStyleSets } from '@fluentui/react';
import React from 'react';

const theme = getTheme();

const globalClassNames = {errorMessage: 'ms-TextField-errorMessage'};
const semanticColors = theme.semanticColors;
const classNamesError = getGlobalClassNames(globalClassNames, theme);

const globalErrorClass = classNamesError.errorMessage;

const errorStyles = mergeStyleSets({
  errorMessage: [
    AnimationClassNames.slideDownIn20,
    theme.fonts.small,
    {
      color: semanticColors.errorText,
      margin: 0,
      paddingTop: 5,
      display: 'flex',
      alignItems: 'center',
    },
  ],
});

const errorClass = errorStyles.errorMessage;

const className = [errorClass, globalErrorClass].join(' ');

const FieldError: React.SFC = (props) => {
  return <div className={className}>{props.children}</div>;
};

export default FieldError;
