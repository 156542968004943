import { useQuery } from '@apollo/react-hooks';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PARAM_SESSION, PARAM_STEP, URL_REQUEST, URL_SESSION, URL_SESSION_CHANGE, } from '../../const/config';
import { Session, SessionStatus, Toggle } from '../../generated/graphql';
import { getSession } from '../../graphql/queries';
import Spinner from '../../organisms/Spinner';
import { GlobalState } from '../../state/globalState';
import { updateSession } from '../../state/sessionState';
import Page from '../../templates/Page';
import { ErrorType } from '../../types/ErrorType';
import StageMessage from './StageMessage';
import {
  getNextStage,
  getPreviousStage,
  NextHandler,
  SessionStage,
  StageComponents,
  Stages,
  StageType,
  StatusStage,
  StepMessage,
} from './Stages';

export const SessionRouter: React.FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { step, sessionCode } = useParams<{
    sessionCode: string;
    step: string;
  }>();
  const [error, setError] = React.useState<ErrorType>();
  const {
    action,
    state: { session },
  } = useStateMachine<GlobalState>(updateSession);

  const [stageSteps, setStageSteps] = React.useState(Stages.DETAILS);
  const [stageType, setStageType] = React.useState(StageType.DETAILS);
  const [isExisting, setIsExisting] = React.useState(false);

  const onSuccessLoad = React.useCallback(
    (session: Session) => {

      if (!session) {
        history.replace(
          URL_SESSION.replace(/:sessionCode/, sessionCode).replace(
            /:step\?/,
            StatusStage[SessionStatus.CANCELED],
          ),
        );
        return;
      }
      switch (session.status) {
        case SessionStatus.PENDING:
          history.replace(generatePath(URL_SESSION_CHANGE, { sessionCode, step: SessionStage.TIME }));
          return;
        case SessionStatus.CANCELED:
        case SessionStatus.COMPLETED:
          history.replace(
            generatePath(URL_SESSION, { sessionCode, step: StatusStage[session.status] }),
          );
          return;
      }


      const isExisting = session.patient.existingClient === Toggle.YES;
      let steps = isExisting ? Stages.DETAILS_EXISTING : Stages.DETAILS;

      setIsExisting(isExisting);
      setStageSteps(steps);
      setStageType(isExisting ? StageType.DETAILS_EXISTING : StageType.DETAILS);
      setError(undefined);

      action({
        session,
        step: steps[0],
        sessionCode,
      });
    },
    [action, history, sessionCode],
  );

  const query = useQuery<{ getSession: Session }, { id: string }>(getSession, {
    variables: {
      id: sessionCode,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccessLoad(data.getSession);
    },
    onError: (error) => {
      setError(error.networkError ? ErrorType.NETWORK : ErrorType.TOKEN);
    },
  });

  const stepNorm = React.useMemo(() => {
    const idx = stageSteps.findIndex((value) => value === step);
    if (idx === -1) return stageSteps[0];
    const idxState = stageSteps.findIndex((value) => value === session.step);
    if (idxState === -1) return stageSteps[0];
    return stageSteps[idxState < idx ? idxState : idx];
  }, [step, session, stageSteps]);

  const onPrev = React.useCallback(() => {
    let prevStep = getPreviousStage(stageType, step as SessionStage);
    if (prevStep===SessionStage.CONSENT && !session.session?.spots?.length){
      prevStep = getPreviousStage(stageType, SessionStage.CONSENT);
    }
    action({ step: prevStep });
    history.push(URL_SESSION.replace(PARAM_SESSION, sessionCode).replace(PARAM_STEP, prevStep));
  }, [action, history, sessionCode, step, stageType]);

  const onNext: NextHandler = React.useCallback(
    (step, update, doReset) => {
      const isExistingClient = session.session?.patient?.existingClient === Toggle.YES;
      let myStep = step;
      if (doReset) {
        action({ session: undefined });
      } else {
        const updatedSession = { ...session.session, ...update };
        for (; ;) {
          const nextStep = getNextStage(stageType, myStep);
          /*
          if (isExistingClient && [SessionStage.PHOTO, SessionStage.CONSENT].includes(nextStep)) {
            myStep = nextStep;
            continue;
          }*/
          if (nextStep===SessionStage.CONSENT && !session.session?.spots?.length) {
            myStep = nextStep;
            continue;
          }
          action({ step: nextStep, session: updatedSession });
          history.push(
            URL_SESSION.replace(PARAM_SESSION, sessionCode).replace(PARAM_STEP, nextStep),
          );
          break;
        }
      }
    },
    [action, history, session.session, sessionCode, stageType],
  );

  switch (error) {
    case ErrorType.NETWORK:
      return (
        <Page>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            actions={
              <MessageBarButton
                onClick={(): void => {
                  query
                    .refetch()
                    .then((result) => onSuccessLoad(result.data.getSession))
                    .catch(() => {
                      setError(ErrorType.NETWORK);
                    });
                }}>
                {t('button.retry')}
              </MessageBarButton>
            }>
            {t('page.session.error.network')}
          </MessageBar>
        </Page>
      );
    case ErrorType.TOKEN:
      return (
        <Page>
          <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            actions={
              <MessageBarButton
                onClick={(): void => {
                  history.push(URL_REQUEST.replace(PARAM_STEP, ''));
                }}>
                {t('button.requestNewLink')}
              </MessageBarButton>
            }>
            {t('page.session.error.token')}
          </MessageBar>
        </Page>
      );
  }

  if (query.loading) return <Spinner/>;

  if (StepMessage.includes(step as SessionStage)) {
    console.log({step})
    if ((step===(SessionStage.PENDING as string)) && isExisting){
      console.log("EXISTING")
      return <StageMessage step={SessionStage.PENDING_EXISTING} type={stageType} session={{}}/>;
    }else {
      return <StageMessage step={step as SessionStage} type={stageType} session={{}}/>;
    }
  }

  if (step !== stepNorm)
    return (
      <Redirect
        to={URL_SESSION.replace(PARAM_STEP, stepNorm).replace(PARAM_SESSION, sessionCode)}
      />
    );

  const $Component = isExisting ? StageComponents.DETAILS_EXISTING[step] : StageComponents.DETAILS[step];
  return $Component ? (
    // eslint-disable-next-line react/jsx-pascal-case
    <$Component
      step={step as SessionStage}
      onNext={onNext}
      onPrev={getPreviousStage(stageType, step as SessionStage) !== step ? onPrev : undefined}
      isLastOne={stageSteps[stageSteps.length - 2] === step}
      type={stageType}
      session={session.session || {}}
    />
  ) : null;
};

export default SessionRouter;
