import { Photo, PhotoType, Session } from '../generated/graphql';
import { SessionStage } from '../pages/Session/Stages';
import { GlobalState } from './globalState';

export const updateSession = (state: GlobalState, payload: Partial<SessionState>): GlobalState => {
  return {
    ...state,
    session: {
      ...state.session,
      ...payload,
    },
  };
};

export type PhotoMap = {[key in PhotoType]: Photo | undefined};

export type SessionState = {
  step?: SessionStage;
  session?: Partial<Session>;
};
