import { useQuery } from '@apollo/react-hooks';
import {
  ActionButton,
  DefaultButton,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApolloError } from 'apollo-client';
import { DateTime } from 'luxon';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Cell, Grid } from 'styled-css-grid';
import * as yup from 'yup';

import { settings } from '../../const/config';
import { QuerygetAvailableTimeslotsArgs, TimeSlot, Toggle } from '../../generated/graphql';
import { getAvailableTimeslots } from '../../graphql/queries';
import Spinner from '../../organisms/Spinner';
import { theme } from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { convertToFormErrors, FormErrors } from '../../utils/convertToFormErrors';
import SubscribeForm from '../Subscribe';
import { StageComponent, StageType } from './Stages';

interface TimeslotForm {
  timeSlot?: Pick<TimeSlot, 'id' | 'date'>;
}

const DaysOfWeek: { [key: number]: string } = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

interface TimeSlotProps {
  doChange: boolean;
}

const currentDate = DateTime.fromObject({ zone: settings.TIME_ZONE });

export const StageTimeslot: StageComponent<TimeSlotProps> = (props) => {
  const {
    session: { timeSlot, patient },
    step,
    onNext,
    onPrev,
    type,
    isLastOne,
    doChange,
  } = props;
  const [isError, setIsError] = React.useState(false);
  const [errors, setErrors] = React.useState<FormErrors>();
  const [showSubscribe, setShowSubscribe] = React.useState(false);

  const [timeSlots, setTimeSlots] = React.useState<TimeSlot[]>();
  const { t } = useTranslation();

  const TimeslotSchema = React.useMemo(
    () =>
      yup.object<TimeslotForm>().shape({
        timeSlot: yup.mixed().defined(),
      }),
    [],
  );

  const { handleSubmit, register, formState, trigger, setValue, control } = useForm<TimeslotForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      timeSlot: timeSlot || undefined,
    },
    resolver: yupResolver(TimeslotSchema),
    criteriaMode: 'all',
  });

  React.useEffect(() => {
    register('timeSlot');
  }, [register]);

  const onSubmit = React.useCallback(
    (values) => {
      onNext && onNext(step, { ...values });
    },
    [onNext, step],
  );

  const selectedTimeSlot = useWatch({ control, name: 'timeSlot' });

  const [selectedDate, setSelectedDate] = React.useState<DateTime>(
    (selectedTimeSlot?.date ? DateTime.fromSeconds(selectedTimeSlot.date, { zone: settings.TIME_ZONE }) : currentDate).startOf(
      'month',
    ),
  );

  const query = useQuery<{ getAvailableTimeslots: TimeSlot[] }, QuerygetAvailableTimeslotsArgs>(
    getAvailableTimeslots,
    {
      variables: {
        params: {
          date: selectedDate.toSeconds(),
          phone: patient?.phone,
          change: doChange ? Toggle.YES : Toggle.NO,
        },
      },
      //skip: !!timeSlots?.length,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data?.getAvailableTimeslots) {
          setTimeSlots(data.getAvailableTimeslots);
          if (data.getAvailableTimeslots.length > 0) {
            setSelectedDate(DateTime.fromSeconds(data.getAvailableTimeslots[0].date));
          }
        }
      },
      onError: (error) => {
        setErrors(convertToFormErrors(error));
        setIsError(true);
      },
    },
  );

  const weeks = React.useMemo(() => {
    const ret: { [key: string]: TimeSlot[] } = {};
    let sd = selectedDate.startOf('month').startOf('week');
    const ed = selectedDate.endOf('month');

    do {
      ret[sd.toISODate()] = [];
      sd = sd.plus({ week: 1 });
    } while (sd < ed);

    timeSlots?.forEach((slot) => {
      const key = DateTime.fromSeconds(slot.date, { zone: settings.TIME_ZONE })
        .startOf('week')
        .toISODate();
      if (!ret[key]) {
        ret[key] = [slot];
      } else ret[key].push(slot);
    });

    return Object.values(ret).map((slots, idx) => (
      <Cell key={idx}>
        {slots.map((slot, idx) => {
          const prevIdx = idx - 1;
          let doPrintDay;
          const cd = DateTime.fromSeconds(slot.date, { zone: settings.TIME_ZONE });
          if (prevIdx === -1) {
            doPrintDay = true;
          } else {
            const pd = DateTime.fromSeconds(slots[prevIdx].date, { zone: settings.TIME_ZONE });
            doPrintDay = pd.day !== cd.day;
          }

          return (
            <React.Fragment key={idx}>
              {doPrintDay && (
                <div>
                  <b>
                    {t(`calendar:day.${DaysOfWeek[cd.weekday]}`)}, {cd.day}
                  </b>
                </div>
              )}
              <DefaultButton
                styles={{ root: { paddingBottom: theme.spacing.s2, minWidth: '100px' } }}
                primary={selectedTimeSlot?.date === slot.date}
                onClick={async () => {
                  setValue('timeSlot', slot, { shouldValidate: true, shouldTouch: true, shouldDirty: true });
                  await trigger('timeSlot');
                }}>
                {DateTime.fromSeconds(slot.date, { zone: settings.TIME_ZONE }).toFormat('HH:mm')}
              </DefaultButton>
            </React.Fragment>
          );
        })}
      </Cell>
    ));
  }, [selectedDate, selectedTimeSlot, setValue, t, timeSlots, trigger]);

  const { isSubmitting } = formState;

  const prevButton = () => {
    const styles = doChange
      ? {
        root: {
          backgroundColor: theme.palette.red,
        },
        rootHovered: {
          backgroundColor: theme.palette.redDark,
        },
        rootPressed: {
          backgroundColor: theme.palette.redDark,
        },
      }
      : undefined;
    return onPrev ? (
      <DefaultButton
        disabled={isSubmitting}
        primary={doChange}
        styles={styles}
        onClick={async (): Promise<void> => onPrev()}>
        {t(doChange ? 'button.cancelAppointment' : 'button.previous')}
      </DefaultButton>
    ) : undefined;
  };

  if (query.loading) return <Spinner/>;

  if (isError && !errors)
    return (
      <Page>
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={true}
          actions={
            <MessageBarButton
              onClick={(): void => {
                query
                  .refetch()
                  .then((result) => {
                    setTimeSlots(result.data.getAvailableTimeslots);
                    setIsError(false);
                    setErrors(undefined);
                  })
                  .catch((error) => {
                    if (error instanceof ApolloError) {
                      setErrors(convertToFormErrors(error));
                    }
                    setIsError(true);
                  });
              }}>
              {t('button.retry')}
            </MessageBarButton>
          }>
          {t('page.session.error.network')}
        </MessageBar>
      </Page>
    );

  return (
    <Page
      title={t(type === StageType.DETAILS ? 'page.session.title' : 'page.request.title', {
        context: doChange ? 'change' : '',
      })}>
      <MultipageForm
        nextButton={errors ? undefined : <PrimaryButton
          disabled={isSubmitting || !formState.isValid}
          onClick={handleSubmit(onSubmit)}>
          {t(doChange ? 'button.modifyAppointment' : isLastOne ? 'button.submit' : 'button.next', {
            context: type,
          })}

        </PrimaryButton>}
        prevButton={prevButton()}
        title={
          timeSlot
            ? t('message.currentTime', {
              time: DateTime.fromSeconds(timeSlot.date, { zone: settings.TIME_ZONE }).toFormat('yyyy-MM-dd HH:mm'),
            })
            : t(type === StageType.DETAILS ? `page.session.${step}.title` : 'page.request.step2')
        }>
        <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
          <StackItem>{t(`page.session.${step}.header`)}</StackItem>
          <StackItem>
            <Stack horizontal grow horizontalAlign="space-evenly">
              <StackItem>
                <ActionButton
                  iconProps={{ iconName: 'ChevronLeft' }}
                  disabled={currentDate.toSeconds() > selectedDate.toSeconds()}
                  onClick={(): void => {
                    setSelectedDate(selectedDate.minus({ month: 1 }));
                  }}
                >{t(`calendar:month.${selectedDate.minus({ month: 1 }).monthLong}`)}, {selectedDate.minus({ month: 1 }).year}</ActionButton>
              </StackItem>
              <StackItem>
                <ActionButton
                  iconProps={{ iconName: 'ChevronRight' }}
                  onClick={(): void => {
                    setSelectedDate(selectedDate.plus({ month: 1 }));
                  }}
                >{t(`calendar:month.${selectedDate.plus({ month: 1 }).monthLong}`)}, {selectedDate.plus({ month: 1 }).year}</ActionButton>
              </StackItem>
            </Stack>
          </StackItem>
          <StackItem className="stepTitle">
            {t(`calendar:month.${selectedDate.monthLong}`)}, {selectedDate.year}
          </StackItem>
          <StackItem className="weekCell2">
            <Grid
              gap="2px"
              columns={'repeat(auto-fit,minmax(100px,1fr))'}
              style={{ minHeight: '200px' }}>
              {timeSlots?.length ? (
                weeks
              ) : (
                <Cell middle center>
                  {errors ? Object.values(errors).join(' ') : t(`page.session.${step}.empty`)}
                </Cell>
              )}
            </Grid>
          </StackItem>
          <StackItem>
            <PrimaryButton onClick={() => setShowSubscribe(true)}>{t('button.subscribeFull')}</PrimaryButton>
            {showSubscribe && <SubscribeForm onClose={() => setShowSubscribe(false)} email={patient?.email || ''}/>}
          </StackItem>
        </Stack>
      </MultipageForm>
    </Page>
  );
};

export const StageTimeslotNew: StageComponent = (props) => {
  return <StageTimeslot doChange={false} {...props}></StageTimeslot>;
};

export const StageConsentChange: StageComponent = (props) => {
  return <StageTimeslot doChange={true} {...props}></StageTimeslot>;
};

export default StageTimeslotNew;
