import { FocusTrapZone, IMessageBarProps, Layer, MessageBar, MessageBarType } from '@fluentui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useDebounce } from 'react-use';

import { DashboardActionType, useDashboardContext } from './Context';

const variants = {
  open: {opacity: 1, y: 0},
  closed: {opacity: 0, y: '-100%'},
};

export type PopUpMessageType = {
  type: MessageBarType;
  text: string;
};

export const PopUpMessage: React.FunctionComponent = (props) => {
  const {
    state: {message},
    dispatch,
  } = useDashboardContext();

  useDebounce(
    () => {
      if (message && message.type !== MessageBarType.error) {
        dispatch({type: DashboardActionType.PopUp});
      }
    },
    2500,
    [message],
  );

  if (message) {
    const isError = message.type === MessageBarType.error;
    const mbProps: IMessageBarProps = {messageBarType: message.type};
    if (isError) {
      mbProps.onDismiss = (): void => {
        dispatch({type: DashboardActionType.PopUp});
      };
    }
    return (
      <Layer>
        <AnimatePresence>
          <motion.div initial={variants.closed} exit={variants.closed} animate={variants.open}>
            {isError ? (
              <FocusTrapZone>
                <MessageBar {...mbProps}>{message.text}</MessageBar>
              </FocusTrapZone>
            ) : (
              <MessageBar {...mbProps}>{message.text}</MessageBar>
            )}
          </motion.div>
        </AnimatePresence>
      </Layer>
    );
  }

  return null;
};
