import { Checkbox } from '@fluentui/react';
import React from 'react';
import { Answer, Question, Toggle } from '../generated/graphql';
import { styles } from '../pages/Dashboard/styles';

interface QuestionsProps {
  questions?: Question[];
  answers?: Answer[];
  onChange: (answers?: Answer[]) => void;
  error?: string
}
const Questions: React.FunctionComponent<QuestionsProps> = (props) => {
  const {questions, answers, onChange,error} = props;

  const initialAnswers = React.useCallback(() => {
    const res = {};
    answers?.forEach((ans) => {
      res[ans.questionCode] = ans.answer;
    });
    return res;
  }, [answers]);

  const [answersMap, setAnswersMap] = React.useState(initialAnswers);

  const changeValue = React.useCallback(
    (code: string, answer: string) => {
      const cloneAM = {...answersMap, [code]: answer};

      if (questions) {
        if (!questions.find(({code}) => !cloneAM[code])) {
          const ans = Object.entries(cloneAM).map(([code, answer]) => {
            return {questionCode: code, answer} as Answer;
          });
          onChange(ans);
        }
      }

      setAnswersMap(cloneAM);
    },
    [answersMap, onChange, questions],
  );

  return (
    <tbody>
    {error && <tr><td colSpan={2} className={styles.error}>{error}</td></tr>}
    {questions?.map(({code, text}) => (
      <tr key={code}>
        <td className='question'>{text}</td>
        <td className='question-checkbox'>
          <Checkbox styles={{root:{paddingRight:'8px'}}}
            onChange={(_, checked): void => changeValue(code, checked ? Toggle.YES : Toggle.NO)}
            checked={answersMap[code] === Toggle.YES}
          />
        </td>
        <td>
          <Checkbox
            onChange={(_, checked): void => changeValue(code, checked ? Toggle.NO : Toggle.YES)}
            checked={answersMap[code] === Toggle.NO}
          />
        </td>
      </tr>
    ))}
    </tbody>
  );
};

export default Questions;
