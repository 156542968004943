import { useMutation, useQuery } from '@apollo/react-hooks';
import { MessageBar, MessageBarButton, MessageBarType, Modal, Stack } from '@fluentui/react';
import { useStateMachine } from 'little-state-machine';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import {settings, URL_SESSION_CHANGE} from '../../const/config';
import { MutationchangeSessionTimeArgs, Session, SessionStatus, TimeSlot } from '../../generated/graphql';
import { changeSessionTime } from '../../graphql/mutations';
import { getSession } from '../../graphql/queries';
import Spinner from '../../organisms/Spinner';
import { GlobalState } from '../../state/globalState';
import { updateSession } from '../../state/sessionState';
import { ModalTwoButtons } from '../../templates/ModalTwoButtons';
import Page from '../../templates/Page';
import { ErrorType } from '../../types/ErrorType';
import { convertErrors } from '../../utils/convertToFormErrors';
import { PADDING32 } from '../Dashboard/const';
import StageMessage from './StageMessage';
import { SessionStage, StageComponents, Stages, StageType, StatusStage, StepMessage } from './Stages';

/* eslint-disable react/jsx-pascal-case */
export const ChangeRouter: React.FunctionComponent = (props) => {
  const history = useHistory();
  const {t} = useTranslation();
  const {step, sessionCode} =
    useParams<{
      sessionCode: string;
      step: string;
    }>();

  const [error, setError] = React.useState<ErrorType>();
  const [errorMessages, setErrorMessages] = React.useState<string[]>();
  const [timeSlot, setTimeSlot] = React.useState<TimeSlot>();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const {
    action,
    state: {session},
  } = useStateMachine<GlobalState>(updateSession);

  const onSuccessLoad = React.useCallback(
    (session: Session) => {
      if (!session || session.status === SessionStatus.CANCELED) {
        history.replace(
          generatePath(URL_SESSION_CHANGE, {
            sessionCode,
            step: StatusStage[SessionStatus.CANCELED],
          }),
        );
        return;
      }

      if (
        DateTime.fromSeconds(session.timeSlot?.date,{zone:settings.TIME_ZONE}).minus({hours: 24}).toSeconds() <
        DateTime.fromObject({zone:settings.TIME_ZONE}).toSeconds()
      ) {
        setError(ErrorType.UNAVAILABLE);
        return;
      }

      setError(undefined);
      action({
        session,
        step: Stages.CHANGE[0],
        sessionCode,
      });
    },
    [action, history, sessionCode],
  );

  const [mutationChangeSessionTime] =
    useMutation<{changeSessionTime: TimeSlot}, MutationchangeSessionTimeArgs>(changeSessionTime);

  const query = useQuery<{getSession: Session}, {id: string}>(getSession, {
    variables: {
      id: sessionCode,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccessLoad(data.getSession);
    },
    onError: (error) => {
      setError(error.networkError ? ErrorType.NETWORK : ErrorType.TOKEN);
    },
  });

  const stepNorm = React.useMemo(() => {
    const idx = Stages.CHANGE.findIndex((value) => value === step);
    if (idx === -1) return Stages.CHANGE[0];
    const idxState = Stages.CHANGE.findIndex((value) => value === session.step);
    if (idxState === -1) return Stages.CHANGE[0];
    return Stages.CHANGE[idxState < idx ? idxState : idx];
  }, [step, session]);

  const onChangeTimeSlot = React.useCallback(async () => {
    setErrorMessages(undefined);
    try {
      await mutationChangeSessionTime({
        variables: {
          sessionCode: sessionCode,
          timeSlotId: timeSlot?.id || null,
        },
      });
      history.replace(
        generatePath(URL_SESSION_CHANGE, {
          sessionCode,
          step: timeSlot?.id ? SessionStage.CHANGED : SessionStage.CANCELED,
        }),
      );
    } catch (e) {
      const {globalErrors, fieldErrors} = convertErrors(e, t);
      const ge = globalErrors?.map((err) => err.message);
      const fe = fieldErrors?.map((err) => Object.values(err.constraints).join(' '));
      const err: string[] = ([] as string[]).concat(ge || []).concat(fe || []);
      setErrorMessages(err);
    }
    setIsModalVisible(false);

    return new Promise<void>((r) => r());
  }, [history, mutationChangeSessionTime, sessionCode, t, timeSlot]);

  const onChange = React.useCallback((_, sessionNew) => {
    setTimeSlot(sessionNew.timeSlot);
    setIsModalVisible(true);
  }, []);

  const onCancel = React.useCallback(() => {
    setTimeSlot(undefined);
    setIsModalVisible(true);
  }, []);

  switch (error) {
    case ErrorType.UNAVAILABLE:
      return (
        <StageMessage
          type={StageType.CHANGE}
          step={SessionStage.UNAVAILABLE}
          session={session.session || {}}
        />
      );
    case ErrorType.NETWORK:
      return (
        <Page>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            actions={
              <MessageBarButton
                onClick={(): void => {
                  query
                    .refetch()
                    .then((result) => onSuccessLoad(result.data.getSession))
                    .catch((e) => {
                      setError(ErrorType.NETWORK);
                    });
                }}>
                {t('button.retry')}
              </MessageBarButton>
            }>
            {t('page.session.error.network')}
          </MessageBar>
        </Page>
      );
    case ErrorType.TOKEN:
      return (
        <Page>
          <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
            {t('page.session.error.token')}
          </MessageBar>
        </Page>
      );
  }

  if (query.loading) return <Spinner />;

  if (StepMessage.includes(step as SessionStage)) {
    return <StageMessage step={step as SessionStage} type={StageType.CHANGE} session={{}} />;
  }

  if (step !== stepNorm)
    return <Redirect to={generatePath(URL_SESSION_CHANGE, {step: stepNorm, sessionCode})} />;

  const $Component = StageComponents.CHANGE[step];
  return $Component ? (
    <>
      <Modal isOpen={isModalVisible} isBlocking onDismiss={(): void => setIsModalVisible(false)}>
        <ModalTwoButtons
          cancelButtonProps={{
            text: t('button.no'),
            onClick: (): void => {
              setIsModalVisible(false);
            },
          }}
          saveButtonProps={{
            text: t('button.yes'),
            onClick: (): void => {
              onChangeTimeSlot();
            },
          }}>
          <Stack tokens={{childrenGap: PADDING32, padding: PADDING32}} verticalAlign='center'>
            {t(timeSlot ? 'message.confirmReschedule' : 'message.confirmCancel')}
          </Stack>
        </ModalTwoButtons>
      </Modal>
      {errorMessages && (
        <MessageBar messageBarType={MessageBarType.error}>
          <ul>
            {errorMessages.map((v) => (
              <li>{v}</li>
            ))}
          </ul>
        </MessageBar>
      )}
      <$Component
        step={step as SessionStage}
        onPrev={onCancel}
        onNext={onChange}
        type={StageType.CHANGE}
        session={session.session || {}}
      />
    </>
  ) : null;
};

export default ChangeRouter;
