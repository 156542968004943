import {DatePicker, Label, Modal, Stack, StackItem, Text, TextField} from '@fluentui/react';
import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {ModalTwoButtons} from '../templates/ModalTwoButtons';
import {PADDING, PADDING32} from './Dashboard/const';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMutation} from '@apollo/react-hooks';
import {MutationsubscribeArgs} from '../generated/graphql';
import {subscribe} from '../graphql/mutations';

export interface SubscribeFormProps {
  onClose: () => void;
  email?: string;
}

interface SubscribeData {
  Email: string;
  StartDate: Date;
}

export const SubscribeForm: React.FunctionComponent<SubscribeFormProps> = (props) => {
  const {t} = useTranslation();

  const [subscribed, setSubscribed] = useState(false);

  const [runMutation] = useMutation<boolean, MutationsubscribeArgs>(
    subscribe,
  );

  const FormSchema = React.useMemo(
    () =>
      yup.object().shape({
        Email: yup.string().required(t('constraints:isNotEmpty')).email(t('constraints:isEmail')),
        StartDate: yup.date().required(t('constraints:isNotEmpty')),
      }),
    [t],
  );

  const onSubmit = React.useCallback(async (value: SubscribeData): Promise<void> => {

    await runMutation({
      variables: {
        input: {
          email: value.Email || '',
          startDate: value.StartDate,
        },
      },
    });

    setSubscribed(true);
  }, [setSubscribed, runMutation]);

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    control,
  } = useForm<SubscribeData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      Email: props.email || '',
      StartDate: new Date(),
    },
    resolver: yupResolver(FormSchema),
  });

  return (
    <Modal isOpen isBlocking>
      <ModalTwoButtons
        cancelButtonProps={{text: t(subscribed ? 'button.close' : 'button.cancel'), onClick: props.onClose}}
        saveButtonProps={subscribed ? undefined : {text: t('button.subscribe'), onClick: handleSubmit(onSubmit)}}>
        <Stack tokens={{childrenGap: PADDING, padding: PADDING32}}>
          <StackItem>
            <Text variant='large'>{t(subscribed ? 'message.subscribed' : 'button.subscribeFull')}</Text>
          </StackItem>
          <StackItem>
            <Label>{t('field.email')}</Label>
            <Controller
              name='Email'
              control={control}
              render={({field: {onChange, onBlur, value}}) => (
                <TextField
                  onBlur={onBlur}
                  onChange={(_, newValue) => onChange(newValue)}
                  value={value || ''}
                  required={true}
                  disabled={isSubmitting||subscribed}
                  errorMessage={errors.Email?.message}></TextField>
              )}
            />
          </StackItem>
          <StackItem>
            <Label>{t('field.startDate')}</Label>
            <Controller
              name='StartDate'
              control={control}
              render={({field: {onChange, onBlur, value}}) => <DatePicker
                onBlur={onBlur}
                disabled={isSubmitting||subscribed}
                onSelectDate={onChange}
                value={value}
              />}
            />
          </StackItem>
        </Stack>
      </ModalTwoButtons>
    </Modal>
  );
};

export default SubscribeForm;
