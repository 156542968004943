import { IBreadcrumbItem, INavLinkGroup } from '@fluentui/react';
import * as React from 'react';
import { Cell, Grid } from 'styled-css-grid';

import { settings } from '../const/config';
import NavigationBar from '../organisms/NavigationBar';

interface PageProps {
  title?: string;
  breadcrumbs?: IBreadcrumbItem[];
  menu?: INavLinkGroup[];
  selectedKey?: string;
}

const Page: React.FunctionComponent<PageProps> = (props) => {
  const {title, children} = props;

  React.useEffect(() => {
    document.title = title || 'Irina Vdovichenko, BS RN';
  }, [title]);

  return (
    <Grid
      gap='0'
      height={'100vh'}
      columns={1}
      rows={'minmax(auto,4rem) auto 1fr minmax(24px,auto)'}
      className='Page'>
      <Cell className='header' height={undefined} center middle>
        {title}
      </Cell>
      <Cell className='navigation'>
        <NavigationBar />
      </Cell>
      <Cell className='content'>
        <div className='box ms-depth-4'>{children}</div>
      </Cell>
      <Cell className='footer' center middle>
        &copy; 2020 Licorne DevOps / {settings.VERSION}
      </Cell>
    </Grid>
  );
};

export default Page;
