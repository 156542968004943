import { DirectionalHint, IDropdownProps, IDropdownStyles } from '@fluentui/react';

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
  dropdownItem: { height: 'auto', padding: '20px', borderBottom: '1px solid gray' },
  callout: { maxWidth: '80%' },
  subComponentStyles: { panel: {}, label: {}, multiSelectItem: {} },
};

export const dropdownProps: Partial<IDropdownProps> = {
  dropdownWidth: 0,
  calloutProps:{
    alignTargetEdge: true,
    directionalHint: DirectionalHint.bottomCenter
  }
};
