import * as React from 'react';

import {Redirect, Route, RouteProps} from 'react-router';

interface ProtectedRouteProps extends RouteProps {
  shouldRedirect: boolean;
  redirectTo: string;
}

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = (props) => {
  const {shouldRedirect, redirectTo, ...rest} = props;
  return shouldRedirect ? (
    <Redirect to={{pathname: redirectTo, state: props.location}} />
  ) : (
    <Route {...rest} />
  );
};

export default ProtectedRoute;
