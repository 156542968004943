import { Spinner as MSSpinner } from '@fluentui/react';
import React from 'react';
import { Cell, Grid } from 'styled-css-grid';

const Spinner: React.FunctionComponent = () => {
  return (
    <Grid columns={1} rows={1} gap='0' height={'100vh'}>
      <Cell center middle>
        <MSSpinner label={'Loading / Téléchargement'} />
      </Cell>
    </Grid>
  );
};

export default Spinner;
export {Spinner};
