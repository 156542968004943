import { useQuery } from '@apollo/react-hooks';
import { FontSizes, Spinner, Stack, StackItem } from '@fluentui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useRouteMatch } from 'react-router';
import getInvoice from '../graphql/queries/getInvoice';
import Signature from '../assets/signature_black.svg';
import { settings } from '../const/config';
import { Invoice as InvoiceData, QuerygetInvoiceArgs } from '../generated/graphql';
import { ErrorType } from '../types/ErrorType';

export const Invoice: React.FunctionComponent = (props) => {
  const {
    params: { sessionCode },
  } = useRouteMatch<{ sessionCode: string }>();

  const [invoice, setInvoice] = React.useState<InvoiceData>();
  const [error, setError] = React.useState<ErrorType>();
  const [isLoading, setIsLoading] = React.useState(true);

  useQuery<{ getInvoice: InvoiceData }, QuerygetInvoiceArgs>(getInvoice, {
    variables: { id: sessionCode },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsLoading(false);
      setInvoice(data.getInvoice);
    },
    onError: (error) => {
      setIsLoading(false);
      setError(error.networkError ? ErrorType.NETWORK : ErrorType.TOKEN);
    },
  });

  const discountBlock = React.useMemo(() => {
    if (!invoice?.discount || invoice.discount<=0) return null;
    return <Stack
      horizontal
      tokens={{ childrenGap: 8 }}
      horizontalAlign="space-between"
      styles={{ root: { borderBottom: '1px gray solid' } }}>
      <StackItem>Rabais:</StackItem>
      <StackItem>${(invoice.discount / invoice.precision).toFixed(2)}</StackItem>
    </Stack>;
  }, [invoice]);

  if (isLoading) return <Spinner/>;
  if (error) return <div>Error loading invoice</div>;
  if (!invoice) return <div>Error loading invoice</div>;


  return (
    <div className="invoice">
      <Stack className="box" tokens={{ padding: 16, childrenGap: 4 }}>
        <StackItem styles={{ root: { textAlign: 'center' } }}>
          <h1>Esthétique Juvenis Inc.</h1>
        </StackItem>
        <StackItem styles={{ root: { textAlign: 'center' } }}>
          18 avenue St-Henri, Vaudreuil-Dorion, QC, J7V 2J6
        </StackItem>
        <StackItem styles={{ root: { marginBottom: 32, padding: 2, borderBottom: '2px black solid' } }}>
          <Stack horizontal horizontalAlign="space-between" wrap>
            <StackItem>514-999-0061</StackItem>
            <StackItem>https://juvenis.ca</StackItem>
            <StackItem>GST #768820060 QST #1228392801</StackItem>
          </Stack>
        </StackItem>
        <StackItem styles={{ root: { marginBottom: 32 } }}>
          <Stack horizontal horizontalAlign="space-between" wrap>
            <StackItem>
              <Stack>
                <StackItem>
                  Nom du patient:{' '}
                  <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    {invoice.client.firstName} {invoice.client.lastName}
                  </span>
                </StackItem>
                <StackItem>
                  Téléphone:{' '}
                  <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    {invoice.client.phone}
                  </span>
                </StackItem>
              </Stack>
            </StackItem>
            <StackItem>
              #<span style={{ fontWeight: 'bold' }}>{invoice.id}</span>
            </StackItem>
            <StackItem>
              Date:{' '}
              <span style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                {DateTime.fromSeconds(invoice.date, { zone: settings.TIME_ZONE }).toLocaleString()}
              </span>
            </StackItem>
          </Stack>
        </StackItem>
        <StackItem styles={{ root: { marginBottom: 8, fontSize: FontSizes.mediumPlus } }}>
          Description des services rendus: les injections esthétiques
        </StackItem>
        <StackItem>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            horizontalAlign="space-between"
            styles={{ root: { marginTop: 8, marginBottom: 8, borderBottom: '1px gray solid' } }}>
            <StackItem>
              Honoraires professionnels infrirmière spésialisée : Irina Vdovichenko #206 2677
            </StackItem>
            <StackItem>$40.00</StackItem>
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            horizontalAlign="space-between"
            styles={{ root: { marginBottom: 32, borderBottom: '1px gray solid' } }}>
            <StackItem>Frais d’exploitation, fourniture médicales, produits injectables</StackItem>
            <StackItem>${((invoice.total - 4000) / invoice.precision).toFixed(2)}</StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <table style={{ width: '100%', margin: 0, padding: 0, borderCollapse: 'collapse' }}>
            <thead>
            <tr>
              <td style={{ borderBottom: '2px gray solid', paddingLeft: 0 }}>
                Nom du produit injecté
              </td>
              <td style={{ borderBottom: '2px gray solid', width: 65, textAlign: 'right' }}>
                Tarif
              </td>
              <td style={{ borderBottom: '2px gray solid', width: 65, textAlign: 'right' }}>
                Quantité
              </td>
              <td style={{ borderBottom: '2px gray solid', width: 65, textAlign: 'right' }}>
                Total
              </td>
            </tr>
            </thead>
            <tbody>
            {invoice.lineItems.map((item) => (
              <tr key={item.name}>
                <td style={{ borderBottom: '1px gray solid' }}>{item.name}</td>
                <td style={{ borderBottom: '1px gray solid', textAlign: 'right' }}>
                  ${(item.price / invoice.precision).toFixed(2)}
                </td>
                <td style={{ borderBottom: '1px gray solid', textAlign: 'right' }}>
                  {item.quantity}
                </td>
                <td style={{ borderBottom: '1px gray solid', textAlign: 'right' }}>
                  ${((item.price * item.quantity) / invoice.precision).toFixed(2)}
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </StackItem>
        <StackItem>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            horizontalAlign="space-between"
            styles={{ root: { borderBottom: '1px gray solid' } }}>
            <StackItem>Sous total:</StackItem>
            <StackItem>${(invoice.total / invoice.precision).toFixed(2)}</StackItem>
          </Stack>
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            horizontalAlign="space-between"
            styles={{ root: { borderBottom: '1px gray solid' } }}>
            <StackItem>TPS/TVQ:</StackItem>
            <StackItem>${invoice.tax / invoice.precision}</StackItem>
          </Stack>
          {discountBlock}
          <Stack
            horizontal
            tokens={{ childrenGap: 8 }}
            horizontalAlign="space-between"
            styles={{ root: { marginBottom: 8, borderBottom: '1px gray solid' } }}>
            <StackItem>Total:</StackItem>
            <StackItem>${((invoice.tax + invoice.total - invoice.discount) / invoice.precision).toFixed(2)}</StackItem>
          </Stack>
        </StackItem>
        <StackItem>
          <Stack horizontal tokens={{ childrenGap: 8 }} wrap>
            <StackItem>Irina Vdovichenko #206 2677</StackItem>
            <StackItem>
              <img src={Signature} alt="Signature"/>
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
    </div>
  );
};
