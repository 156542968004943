import gql from 'graphql-tag';

const query = gql`
  query getNotes($params: GetNotesParams!) {
    getNotes(params: $params) {
      id
      version
      user {
        id
        email
        firstName
        lastName
      }
      archived
      dateTime
      text
    }
  }
`;
export default query;
