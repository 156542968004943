interface Identifiable {
  id: number | string;
}

function arraySet<T extends Identifiable>(source: Array<T>, key: T, doSet: boolean): Array<T> {
  if (doSet) {
    const findIdx = source.findIndex((v) => v.id === key.id);
    const copy = source.slice();
    if (findIdx === -1) {
      copy.push(key);
    } else {
      copy[findIdx] = key;
    }
    return copy;
  } else {
    return source.filter((v) => v.id !== key.id);
  }
}

function arrayStringSet<T>(source: Array<T>, key: T, doSet: boolean): Array<T> {
  if (doSet) {
    const findIdx = source.findIndex((v) => v === key);
    const copy = source.slice();
    if (findIdx === -1) {
      copy.push(key);
    } else {
      copy[findIdx] = key;
    }
    return copy;
  } else {
    return source.filter((v) => v !== key);
  }
}

export {arraySet, arrayStringSet};
