import { useLocalStorage } from '@rehooks/local-storage';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import ProtectedRoute from './components/ProtectedRoute';
import {
  URL_CONSENT,
  URL_DASHBOARD,
  URL_INFO,
  URL_INVOICE,
  URL_LOGIN,
  URL_MONTHLY_REPORT,
  URL_PRODUCT_REPORT,
  URL_REQUEST,
  URL_SESSION,
  URL_SESSION_CHANGE, URL_UNSUBSCRIBE,
} from './const/config';
import { Spinner } from './organisms/Spinner';
import { Info, Site } from './pages';
import { Consent } from './pages/Consent';
import { Invoice } from './pages/Invoice';
import { TOKEN_ACCESS, TOKEN_REFRESH } from './state';
import UnsubscribeHelper from './pages/UnsubscribeHelper';

const RequestRouter = React.lazy(() => import('./pages/Session/RequestRouter'));
const SessionRouter = React.lazy(() => import('./pages/Session/SessionRouter'));
const ChangeRouter = React.lazy(() => import('./pages/Session/ChangeRouter'));
const Login = React.lazy(() => import('./pages/Login'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/'));
const MontlyReport = React.lazy(() => import('./pages/Dashboard/MonthlyReport'));
const MontlyProductReport = React.lazy(() => import('./pages/Dashboard/MonthlyProductReport'));

const App: React.FunctionComponent = (props) => {
  const [accessToken] = useLocalStorage(TOKEN_ACCESS);
  const [refreshToken] = useLocalStorage(TOKEN_REFRESH);

  const noTokens = !(accessToken && refreshToken);

  return (
    <React.Suspense fallback={<Spinner />}>
      <Switch>
        <ProtectedRoute
          path={URL_DASHBOARD}
          component={Dashboard}
          key='dashboard'
          redirectTo={URL_LOGIN}
          shouldRedirect={noTokens}
        />
        <Route path={URL_INFO} component={Info} key='info' />
        <Route exact path='/' component={Site} key='main' />
        <Route path={URL_UNSUBSCRIBE} component={UnsubscribeHelper} key='unsubscribe' />
        <Route path={URL_REQUEST} component={RequestRouter} key='RequestRouter' />
        <Route path={URL_SESSION} component={SessionRouter} key='SessionRouter' />
        <Route path={URL_SESSION_CHANGE} component={ChangeRouter} key='ChangeRouter' />
        <Route path={URL_INVOICE} component={Invoice} key='Invoice' />
        <Route path={URL_CONSENT} component={Consent} key='Consent' />
        <Route path={URL_MONTHLY_REPORT} component={MontlyReport} key='MontlyReport' />
        <Route
          path={URL_PRODUCT_REPORT}
          component={MontlyProductReport}
          key='MontlyProductReport'
        />

        <Route path={URL_LOGIN} component={Login} key='login' />
        <Route
          path='/legacy'
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          component={() => {
            window.location.href = '/legacy/';
            return null;
          }}
        />
        <Redirect to='/' />
      </Switch>
    </React.Suspense>
  );
};

export default App;
