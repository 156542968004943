import gql from 'graphql-tag';

const query = gql`
  mutation changeTime($timeSlot: TimeSlotInput, $sessionId: ID!) {
    changeTime(timeSlot: $timeSlot, sessionId: $sessionId) {
      id
      date
      length
    }
  }
`;

export default query;
