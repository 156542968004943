import './index.scss';

import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, PrimaryButton } from '@fluentui/react';
import { useViewportScroll } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { LANGUAGE, URL_REQUEST } from '../../const/config';
import scrollToRef from '../../utils/scrollToRef';
import About from './About';
import Contact from './Contact';
import Home from './Home';
import Prices from './Prices';
import Regulations from './Regulations';
import Treatments from './Treatments';

/*
import About from "./About";
import Book from "./Book";


import Regulations from "./Regulations";
import Treatments from "./Treatments";
*/

export enum PageID {
  LANGUAGE = 'language',
  HOME = 'home',
  ABOUT = 'about',
  TREATMENTS = 'treatments',
  REGULATIONS = 'regulations',
  PRICES = 'prices',
  CONTACT = 'contact',
  BOOK = 'book',
}

export const pageNav = [
  PageID.LANGUAGE,
  PageID.HOME,
  PageID.TREATMENTS,
  PageID.REGULATIONS,
  PageID.CONTACT,
  PageID.BOOK,
];

export const pageContent = [
  PageID.HOME,
  PageID.ABOUT,
  PageID.TREATMENTS,
  PageID.REGULATIONS,
  PageID.CONTACT,
];

export type FuncOnNavigate = (page: PageID) => void;

export interface SectionProps {
  onNavigate: FuncOnNavigate;
  className?: string;
}

type SectionPages =
  | PageID.HOME
  | PageID.ABOUT
  | PageID.TREATMENTS
  | PageID.REGULATIONS
  | PageID.CONTACT;

const Sections: Record<SectionPages, React.FunctionComponent<SectionProps>> = {
  home: Home,
  about: About,
  treatments: Treatments,
  regulations: Regulations,
  contact: Contact,
};

export const Site: React.FunctionComponent = () => {
  const [currentPage, setCurrentPage] = React.useState(PageID.HOME);
  const {i18n, t} = useTranslation();
  const location = useHistory();

  const compRef = React.useMemo(() => {
    const ret: Partial<Record<PageID, React.RefObject<HTMLDivElement>>> = {};
    for (const z of pageNav) {
      ret[z] = React.createRef<HTMLDivElement>();
    }
    return ret;
  }, []);

  const onNavigate: FuncOnNavigate = React.useCallback(
    (page: PageID) => {
      setCurrentPage(page);
      switch (page) {
        case PageID.BOOK:
          location.push(generatePath(URL_REQUEST));
          break;
        case PageID.LANGUAGE:
          i18n.changeLanguage(
            i18n.language === LANGUAGE.ENGLISH ? LANGUAGE.FRENCH : LANGUAGE.ENGLISH,
          );
          break;
        default:
          const comp = compRef[page];
          if (comp) {
            scrollToRef(comp);
          }
      }
    },
    [compRef, i18n, location],
  );
  const [menuClass, setMenuClass] = React.useState('');
  const [showMenu, setShowMenu] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  useDebounce((): void => setShowModal(true), 5 * 1000);

  React.useLayoutEffect((): void => {
    window.scrollTo({left: 0, top: 0});
  }, [compRef]);

  const {scrollY} = useViewportScroll();
  React.useEffect(() => {
    function updateOpacity(): void {
      const y = scrollY.get();
      setMenuClass(y <= 0 ? '' : 'scrolled');
      setShowMenu(false);
    }

    const unsubscribeY = scrollY.onChange(updateOpacity);

    return (): void => {
      unsubscribeY();
    };
  }, [scrollY]);

  return (
    <div className={['container', menuClass].join(' ')}>
      <nav className={showMenu ? 'visible' : 'hidden'}>
        <Icon
          className='icon'
          iconName={showMenu ? 'ChromeClose' : 'GlobalNavButton'}
          onClick={(): void => {
            setShowMenu(!showMenu);
          }}
        />
        <ul className={showMenu ? 'visible' : 'hidden'}>
          {pageNav.map((pageId, idx) => {
            const currentClass = pageId === currentPage ? 'active' : '';
            return (
              <li
                key={pageId}
                className={`t${idx + 1} menuItem ${currentClass}`}
                onClick={(): void => onNavigate(pageId)}>
                {t(`menu.${pageId}`)}
              </li>
            );
          })}
        </ul>
      </nav>
      <Dialog
        onDismiss={(): void => {
          setShowModal(false);
        }}
        isBlocking={true}
        isOpen={showModal}
        dialogContentProps={{
          type: DialogType.largeHeader,
          showCloseButton: false,
          title: t('button.bookNow'),
        }}>
        {t('main:home.requestAppointmentText')}
        <DialogFooter>
          <PrimaryButton onClick={(): void => onNavigate(PageID.BOOK)}>
            {t('button.book')}
          </PrimaryButton>
          <DefaultButton
            onClick={(): void => {
              setShowModal(false);
            }}>
            {t('button.close')}
          </DefaultButton>
        </DialogFooter>
      </Dialog>
      {pageContent.map((pageid, idx) => {
        const SectionComponent = Sections[pageid];
        return (
          <div ref={compRef[pageid]} key={idx}>
            <SectionComponent onNavigate={onNavigate} key={idx} className={pageid} />
          </div>
        );
      })}
    </div>
  );
};

export default Site;
