import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { SectionProps } from '.';
import RegulationsImage from '../../assets/regulations.jpg';

const Regulations: React.FunctionComponent<SectionProps> = (props) => {
  const {t} = useTranslation();
  const {className} = props;
  return (
    <section className={className}>
      <div>
        <h2>{t('main:regulations.title')}</h2>
      </div>
      <div>
        <img src={RegulationsImage} width='100%' alt='' />
      </div>
      <div>
        <ReactMarkdown>{t('main:regulations.content')}</ReactMarkdown>
      </div>
    </section>
  );
};

export default Regulations;
