import gql from 'graphql-tag';

const query = gql`
  mutation createAppointment($params: CreateAppointmentInput!) {
    createAppointment(params: $params) {
      id
      version
      user {
        id
        version
        email
        firstName
        lastName
      }
      patient {
        id
        firstName
        lastName
      }
      timeSlot {
        id
        version
        date
      }
      sessionCode
      status
      type
      answers {
        id
      }
      photos {
        id
      }
      files {
        id
      }
    }
  }
`;

export default query;
