import gql from 'graphql-tag';

const query = gql`
  query getProducts($name: String) {
    getProducts(name: $name) {
      id
      name
      productType
      priority
      brand {
        id
        name
      }
      defaultPrice
      productVariants {
        id
        name
      }
    }
  }
`;
export default query;
