import { loadTheme } from '@fluentui/react';

export const theme = loadTheme({
  palette: {
    themePrimary: '#313d5a',
    themeLighterAlt: '#f4f5f8',
    themeLighter: '#d4d9e4',
    themeLight: '#b1b9cd',
    themeTertiary: '#717d9c',
    themeSecondary: '#414e6d',
    themeDarkAlt: '#2b3650',
    themeDark: '#252e44',
    themeDarker: '#1b2232',
    neutralLighterAlt: '#eeeeee',
    neutralLighter: '#eaeaea',
    neutralLight: '#e1e1e1',
    neutralQuaternaryAlt: '#d1d1d1',
    neutralQuaternary: '#c8c8c8',
    neutralTertiaryAlt: '#c0c0c0',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#f5f5f5',
  },
});

/*
export const theme = loadTheme({
  palette: {
    themePrimary: "#313D5A",
    themeLighterAlt: "#6176A8",
    themeLighter: "#506391",
    themeLight: "#415176",
    themeTertiary: "#c9ada7",
    themeSecondary: "#183642",
    themeDarkAlt: "#242D42",
    themeDark: "#2B364F",
    themeDarker: "#1D2435",
    neutralLighterAlt: "#eeeeee",
    neutralLighter: "#eaeaea",
    neutralLight: "#e1e1e1",
    neutralQuaternaryAlt: "#d1d1d1",
    neutralQuaternary: "#c8c8c8",
    neutralTertiaryAlt: "#c0c0c0",
    neutralTertiary: "#c2c2c2",
    neutralSecondary: "#858585",
    neutralPrimaryAlt: "#4b4b4b",
    neutralDark: "#272727",
    black: "#1d1d1d",
    white: "#eeeeee",
    whiteTranslucent40: "#ffffff",
  },
});
*/
