import gql from 'graphql-tag';

const query = gql`
    query getPatient($id: String!) {
        getPatient(id: $id) {
            id
            version
            email
            phone
            firstName
            lastName
            gender
            birthday
            comment
            existingClient
            language
            sessions {
                id
                version
                user {
                    id
                    version
                    email
                    firstName
                    lastName
                }
                patient {
                    id
                    firstName
                    lastName
                    existingClient
                }
                paymentType
                treatment {
                    clientTXRequest
                    clientTXRequestNote
                    clinicalCondition
                    clinicalConditionNote
                    contraIndications
                    treatmentPlan
                    treatmentPlanNote
                    sideEffectsExplained
                    optimalResultExplained
                    questionsAnswered
                    reactions
                    reactionsNote
                    instructionsExplained
                    instructionsSent
                    invoiceSent
                    protocolAdjustedTreatment
                    followUp
                    nextTreatment
                    nextTreatmentNote
                    schema
                    schemaBase
                    treatmentProducts {
                        units
                        spots {
                            spot
                            units
                        }
                        anesthesia
                        note
                        productId
                        productVariantId
                    }
                }
                sessionCode
                status
                type
                commentHealth
                commentProcedure
                noteTreatment
                noteFollowUp
                noteSession
                signatureSVG
                signatureTime
                signatureIP
                requestTime
                requestIP
                spots
                photos {
                    id
                    version
                    type
                    fileName
                    fileCDN
                }
                files {
                    id
                    version
                    type
                    fileName
                    fileCDN
                }
                consents {
                    id
                    signatureIP
                    signatureSVG
                    signatureTime
                    productType
                }
                answers {
                    id
                    version
                    questionCode
                    questionText
                    answer
                }
                productUsage {
                    id
                    version
                    unitsInjected
                    unitsPurchased
                    price
                    isNewPackage
                    product {
                        id
                        version
                        name
                        productType
                        productUnit
                        brand {
                            id
                            version
                            name
                        }
                    }
                }
                timeSlot {
                    id
                    version
                    date
                }
                mediaConsent
            }
        }
    }
`;
export default query;
