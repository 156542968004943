import {DefaultButton, Dialog, DialogFooter, DialogType, Link, PrimaryButton} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import {SectionProps} from '.';
import FaceImg from '../../assets/face_1080x1080.jpg';
import MarkerImg from '../../assets/spot.svg';
import MarkerSelectedImg from '../../assets/spot_selected.svg';
import Drawer from '../../components/Drawer';
import {HotSpotImage} from '../../components/HotSpotImage/HotSpotImage';
import {ProductType, SpotType} from '../../generated/graphql';
import scrollToRef from '../../utils/scrollToRef';
import {spotProduct, spots} from '../Session/Spots';

const Treatments: React.FunctionComponent<SectionProps> = (props) => {
  const {className} = props;
  const [spot, setSpot] = React.useState<SpotType>();
  const {t} = useTranslation();

  const productRef = React.createRef<HTMLDivElement>();

  const [collapsible, setCollapsible] = React.useState<Record<ProductType, boolean>>({
    BOTOX: false,
    FILLER: false,
    PRP: false,
    RADIESSE: false,
    SCULPTRA: false,
    MESO: false,
    HYALO: false,
    OTHER: false,
  });

  const dialogContentProps = React.useMemo(() => {
    return {
      type: DialogType.largeHeader,
      title: t(`spots.${spot}.name`),
      subText: t(`spots.${spot}.result`),
    };
  }, [spot, t]);

  return (
    <section className={className}>
      <div>
        <h2>{t('main:treatments.title')}</h2>
        <h5>{t('main:treatments.text')}</h5>
      </div>

      <div className='hotSpotMain'>
        <HotSpotImage
          selected={spot ? [spot] : []}
          mainSrc={FaceImg}
          markerSrc={MarkerImg}
          mappedWidth={1080}
          markerRate={0.05}
          markerSelectedSrc={MarkerSelectedImg}
          spots={spots}
          onSpotChange={(spotId, active): void => {
            /**/
          }}
          onSpotClick={(id: string): void => {
            setSpot(id as SpotType);
          }}
        />
        <h5>{t('main:treatments.textFullFace')}</h5>
        <ul>
          {spots.map((spot) => {
            return spot.wholeFace ? (
              <li
                className='pointer'
                key={spot.id}
                onClick={(): void => setSpot(spot.id as SpotType)}>
                {t(`spots.${spot.id}.name`)}
              </li>
            ) : null;
          })}
        </ul>

        <Dialog
          isOpen={!!spot}
          onDismiss={(): void => setSpot(undefined)}
          dialogContentProps={dialogContentProps}>
          {spot && ![SpotType.PRP, SpotType.RADIESSE,SpotType.SCULPTRA].includes(spot) && (
            <>
              {t('main:treatments.hotspot.product')}
              <br />
              <ul>
                {spotProduct[spot].map((product) => (
                  <li key={product}>{t(`product.${product}`)}</li>
                ))}
              </ul>
            </>
          )}
          <DialogFooter>
            <PrimaryButton
              text={t('button.learnMore')}
              onClick={(): void => {
                if (spot) {
                  const newDrawers: Record<ProductType, boolean> = {
                    BOTOX: false,
                    FILLER: false,
                    PRP: false,
                    RADIESSE: false,
                    SCULPTRA: false,
                    MESO: false,
                    HYALO: false,
                    OTHER: false,
                  };
                  for (const spotProd of spotProduct[spot]) newDrawers[spotProd] = true;
                  setCollapsible(newDrawers);
                  scrollToRef(productRef);
                  setSpot(undefined);
                }
              }}
            />
            <DefaultButton text={t('button.close')} onClick={(): void => setSpot(undefined)} />
          </DialogFooter>
        </Dialog>
        <div ref={productRef} />
        <h2>{t('main:treatments.botox.title')}</h2>
        <ReactMarkdown>{t('main:treatments.botox.textShort')}</ReactMarkdown>
        {!collapsible.BOTOX && (
          <Link
            onClick={(): void =>
              setCollapsible((v) => {
                return {...v, BOTOX: true};
              })
            }>
            {t('button.learnMore')}
          </Link>
        )}
        <Drawer isOpen={collapsible.BOTOX}>
          <ReactMarkdown>{t('main:treatments.botox.text')}</ReactMarkdown>
        </Drawer>

        <h2>{t('main:treatments.filler.title')}</h2>
        <ReactMarkdown>{t('main:treatments.filler.textShort')}</ReactMarkdown>
        {!collapsible.FILLER && (
          <Link
            onClick={(): void =>
              setCollapsible((v) => {
                return {...v, FILLER: true};
              })
            }>
            {t('button.learnMore')}
          </Link>
        )}
        <Drawer isOpen={collapsible.FILLER}>
          <ReactMarkdown>{t('main:treatments.filler.text')}</ReactMarkdown>
        </Drawer>

      </div>
    </section>
  );
};

export default Treatments;
