import LZUTF8 from 'lzutf8';

import { BasicPoint, CompositeOperations, Point, PointGroup, Tool } from './types';

/**
 * Create point with adjusted coordinates
 *
 * @param canvas
 * @param x
 * @param y
 * @returns
 */
export const createPoint = (canvas: HTMLCanvasElement, x: number, y: number): Point => {
  const rect = canvas.getBoundingClientRect() || { left: 0, top: 0 };
  return new Point(x - rect.left, y - rect.top, new Date().getTime());
};

export const drawDot = (
  ctx: CanvasRenderingContext2D,
  dotSize: number,
  tool: Tool,
  point: BasicPoint,
  color: string,
): void => {
  const { x, y } = point;
  ctx.globalCompositeOperation = CompositeOperations[tool];
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.moveTo(x, y);
  ctx.arc(x, y, dotSize / 2.5, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fill();
};

export const drawSegment = (
  ctx: CanvasRenderingContext2D,
  start: BasicPoint,
  end: BasicPoint,
  dotSize: number,
  tool: Tool,
  color: string,
): void => {
  ctx.globalCompositeOperation = CompositeOperations[tool];
  ctx.beginPath();
  ctx.lineWidth = dotSize;
  ctx.strokeStyle = color;
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  ctx.closePath();
  ctx.stroke();
};

export const mapCoordinate = (d: number, rate: number): number => (rate !== 0 ? d / rate : d);

export const redraw = (
  ctx: CanvasRenderingContext2D,
  pointGroups: PointGroup[],
  color: string,
): void => {
  pointGroups.forEach((pointGroup) => {
    const lastPointIdx = pointGroup.points.length - 1;

    pointGroup.points.forEach((point, idx, arr) => {
      drawDot(ctx, pointGroup.width, pointGroup.tool, point, color);
      if (lastPointIdx) {
        if (idx !== lastPointIdx) {
          drawSegment(ctx, point, arr[idx + 1], pointGroup.width, pointGroup.tool, color);
        }
      }
    });
  });
};

export const importString = (input: string): PointGroup[] => {
  try {
    const decoded = LZUTF8.decompress(input, { inputEncoding: 'Base64' });
    if (!decoded) return [];
    return JSON.parse(decoded);
  } catch (e) {
    return [];
  }
};
