import {SessionState} from './sessionState';
import {UserState} from './userState';

export type GlobalState = {
  session: SessionState;
  user: UserState;
  isLowDataMode: boolean;
};

export const switchDataMode = (state: GlobalState): GlobalState => {
  return {...state, isLowDataMode: !state.isLowDataMode};
};
