import { Label, MaskedTextField, PrimaryButton, Stack, StackItem } from '@fluentui/react';

import { yupResolver } from '@hookform/resolvers/yup';

// import { Turnstile } from '@marsidev/react-turnstile';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import FieldError from '../../components/FieldError';
import { settings } from '../../const/config';
import { theme } from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { PhoneRegexp } from '../../utils';
import { convertFieldErrorToString } from '../../utils/convertToFormErrors';
import { StageComponent } from './Stages';

type PhoneForm = { phone: string, token: string }

export const StagePhone: StageComponent = (props) => {
  const { session, step, onNext, type } = props;

  const { t } = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [token, setToken] = React.useState<string>();

  const ClientSchema = React.useMemo(() => {
    return yup.object<PhoneForm>().shape({
      phone: yup
        .string()
        .trim()
        .nullable()
        .required(t('constraints:isNotEmpty'))
        .matches(PhoneRegexp, t('constraints:isPhone')),
      token: yup.string().trim().required(t('constraints:isNotEmpty'))
    });
  }, [t, type]);

  const {
    handleSubmit,
    formState,
    formState: { errors },
    control,
    setValue
  } = useForm<PhoneForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {},
    resolver: yupResolver(ClientSchema),
    criteriaMode: 'all',
  });

  const onSubmit = React.useCallback(
    (values) => {
      console.log(values);
      // onNext && onNext(step, {});
    },
    [onNext, step],
  );

  const nextButton = useMemo(
    () => (
      <PrimaryButton onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [handleSubmit, onSubmit, t],
  );

  const { isSubmitting } = formState;

  // @ts-ignore
  return (
    <Page title={t('page.session.patient.title')}>
      <MultipageForm
        nextButton={nextButton}
        title={t('page.session.phone.title')}>
        <form onSubmit={onSubmit} className="formCompact">
          <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
            <StackItem>
              <Label required>{t('field.phone')}:</Label>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <MaskedTextField
                    onChange={(_, newValue) => onChange(newValue)}
                    onBlur={onBlur}
                    mask="(999) 999-9999"
                    value={value || ''}
                    placeholder={'514-000-0000'}
                    disabled={isSubmitting}
                    errorMessage={convertFieldErrorToString(errors.phone)}
                  />
                )}
              />
            </StackItem>
            <StackItem>
              {/*<Turnstile siteKey={settings.CAPTCHA_CLIENT_KEY} onSuccess={(t) => setValue('token', t)}/>*/}
              {errors.token && (
                <FieldError>{convertFieldErrorToString(errors.token)}</FieldError>
              )}
            </StackItem>
          </Stack>
        </form>
      </MultipageForm>
    </Page>
  );
};

export default StagePhone;
