import { Stack, StackItem } from '@fluentui/react';
import React from 'react';

import { theme } from '../styles/theme';

type MultipageFormProps = {
  prevButton?: JSX.Element;
  nextButton?: JSX.Element;
  title: string;
};

const MultipageForm: React.FunctionComponent<MultipageFormProps> = (props) => {
  const {children, title, nextButton, prevButton} = props;
  return (
    <Stack tokens={{childrenGap: theme.spacing.m}}>
      <StackItem className='stepTitle'>{title}</StackItem>
      <StackItem>{children}</StackItem>
      <StackItem>
        {prevButton || null} {nextButton || null}
      </StackItem>
    </Stack>
  );
};

export default MultipageForm;
