import { IconButton, Stack, StackItem, Toggle } from '@fluentui/react';
import LZUTF8 from 'lzutf8';
import * as React from 'react';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import useResizeObserver from 'use-resize-observer';

import { theme } from '../../styles/theme';
import throttle from '../../utils/throttle';
import { DEFAULT_ERASER_WIDTH, DEFAULT_STROKE_WIDTH, MAIN_BUTTON, MIN_DISTANCE, THROTTLE } from './constants';
import { BasicPoint, RefState, Size, Tool, VoidFunc } from './types';
import { createPoint, drawDot, drawSegment, importString, mapCoordinate, redraw } from './utils';

export interface DrawCanvasProps {
  imageURL: string;
  imageWidth: number;
  imageHeight: number;
  color: string;
  value?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const DrawCanvas: React.FunctionComponent<DrawCanvasProps> = (props) => {
  const {imageURL, imageWidth, imageHeight, color, value: inputData, onChange, disabled} = props;

  const {t} = useTranslation();

  const isDrawing = React.useRef<boolean>(false);
  const refState = React.useRef<RefState>({
    data: [],
  });
  const contextRef = React.useRef<CanvasRenderingContext2D>();
  const canvasRef = React.createRef<HTMLCanvasElement>();

  // states
  const [size, setSize] = React.useState<Size>();
  const [tool, setTool] = React.useState<Tool>(Tool.PEN);
  const [strokeWidth] = React.useState<Record<Tool, number>>({
    pen: DEFAULT_STROKE_WIDTH,
    eraser: DEFAULT_ERASER_WIDTH,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResize = React.useCallback(throttle(setSize, 50), []);
  const {ref} = useResizeObserver<HTMLDivElement>({onResize});

  // Memoized
  const width = React.useMemo(() => strokeWidth[tool], [tool, strokeWidth]);
  const rate = React.useMemo(() => (size?.width || 0) / imageWidth, [imageWidth, size?.width]);

  // Callbacks

  const drawCanvas = React.useCallback(() => {
    const ctx = contextRef.current;
    if (ctx && size) {
      ctx.strokeStyle = color;
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.scale(rate, rate);
      ctx.beginPath();
      ctx.clearRect(0, 0, imageWidth, imageHeight);
      ctx.closePath();
      ctx.stroke();
      redraw(ctx, refState?.current.data || [], color);
    }
  }, [color, imageHeight, imageWidth, rate, size]);

  const resetCanvas = React.useCallback(() => {
    refState.current.data = [];
    drawCanvas();
  }, [drawCanvas]);

  // Effects

  // Load passed data
  React.useEffect(() => {
    if (typeof inputData === 'string') refState.current.data = importString(inputData);
    else refState.current.data = inputData || [];
  }, [inputData]);

  // Initialize context
  React.useLayoutEffect(() => {
    if (canvasRef.current && !contextRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        contextRef.current = ctx;
      }
    }
  }, [contextRef, canvasRef]);

  useDebounce(drawCanvas, 100, [size]);

  const onStrokeUpdate = React.useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (!canvasRef.current || !contextRef.current) return;
      const {data} = refState.current;

      // if didn't start properly
      if (data.length === 0) {
        const newPointGroup = {
          tool,
          width,
          color,
          points: [],
        };
        data.push(newPointGroup);
      }

      const {clientX: x, clientY: y} = event instanceof MouseEvent ? event : event.touches[0];
      const point = createPoint(canvasRef.current, mapCoordinate(x, rate), mapCoordinate(y, rate));

      const lastPointGroup = data[data.length - 1];
      const lastPoints = lastPointGroup.points;
      const lastPoint = lastPoints.length > 0 && lastPoints[lastPoints.length - 1];
      const isLastPointTooClose = lastPoint ? point.distanceTo(lastPoint) <= MIN_DISTANCE : false;
      const lastTool = lastPointGroup.tool;
      const lastWidth = lastPointGroup.width;

      // Skip this point if it's too close to the previous one
      if (!lastPoint || !(lastPoint && isLastPointTooClose)) {
        drawDot(contextRef.current, lastWidth, lastTool, point, color);
        const nextPoint: BasicPoint = {
          time: point.time,
          x: point.x,
          y: point.y,
        };
        if (lastPoint) {
          drawSegment(contextRef.current, lastPoint, nextPoint, lastWidth, lastTool, color);
        }

        lastPoints.push(nextPoint);
      }
    },
    [canvasRef, rate, tool, width, color],
  );

  const onStrokeBegin = React.useCallback(
    (event: MouseEvent | TouchEvent) => {
      const newPointGroup = {
        tool,
        width,
        points: [],
      };
      refState.current?.data.push(newPointGroup);

      const coords = event instanceof TouchEvent ? event.touches[0] : event;
      contextRef.current?.moveTo(coords.clientX, coords.clientY);

      onStrokeUpdate(event);
    },
    [onStrokeUpdate, tool, width],
  );

  const onPointerDown = React.useCallback(
    (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      if (
        !disabled &&
        ((event instanceof MouseEvent && event.button === MAIN_BUTTON) || event instanceof Touch)
      ) {
        isDrawing.current = true;
        onStrokeBegin(event);
      }
    },
    [onStrokeBegin, disabled],
  );

  const onPointerUp = React.useCallback(
    (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      if (
        !disabled &&
        isDrawing.current &&
        ((event instanceof MouseEvent && event.button === MAIN_BUTTON) || event instanceof Touch)
      ) {
        isDrawing.current = false;
        onStrokeUpdate(event);
        if (onChange) {
          onChange(
            LZUTF8.compress(JSON.stringify(refState?.current.data || []), {
              outputEncoding: 'Base64',
            }),
          );
        }
      }
    },

    [disabled, onStrokeUpdate, onChange],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPointerMove = React.useCallback(
    throttle((event: MouseEvent | TouchEvent) => {
      if (isDrawing.current && !disabled) {
        onStrokeUpdate(event);
      }
    }, THROTTLE),
    [disabled, onStrokeUpdate],
  );

  // Init event handlers
  // eslint-disable-next-line consistent-return
  React.useEffect((): VoidFunc | undefined => {
    if (canvasRef.current) {
      const hackFn = (e: TouchEvent) => e.preventDefault();
      const cr = canvasRef.current;
      cr.addEventListener('pointerup', onPointerUp);
      cr.addEventListener('pointerdown', onPointerDown);
      cr.addEventListener('pointermove', onPointerMove);
      cr.addEventListener('touchmove', hackFn);
      document.addEventListener('pointerup', onPointerUp);
      return (): void => {
        cr?.removeEventListener('pointerup', onPointerUp);
        cr?.removeEventListener('pointerdown', onPointerDown);
        cr?.removeEventListener('pointermove', onPointerMove);
        cr?.removeEventListener('touchmove', hackFn);
        document.removeEventListener('pointerup', onPointerUp);
      };
    }
    return;
  }, [canvasRef, onPointerUp, onPointerDown, onPointerMove]);

  return (
    <Stack>
      <StackItem>
        <div
          style={{
            width: '100%',
            maxWidth: `${imageWidth}px`,
          }}>
          <div
            style={{
              position: 'relative',
              userSelect: 'none',
            }}
            ref={ref}>
            <img
              alt='Face'
              src={imageURL}
              style={{
                display: 'block',
                width: '100%',
                height: 'auto',
                userSelect: 'none',
                pointerEvents: 'none',
              }}
            />
            <canvas
              ref={canvasRef}
              width={size?.width || 0}
              height={size?.height || 0}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </div>
        </div>
      </StackItem>
      <StackItem>
        <Stack
          horizontal
          wrap
          horizontalAlign='space-between'
          tokens={{childrenGap: theme.spacing.m}}
          style={{
            minWidth: 300,
            width: '100%',
            maxWidth: `${imageWidth}px`,
          }}>
          <StackItem>
            <Toggle
              label={t('label.tool.title')}
              onText={t('label.tool.eraser')}
              offText={t('label.tool.pen')}
              disabled={disabled}
              onChange={(_, checked) => setTool(checked ? Tool.ERASER : Tool.PEN)}
              checked={tool === Tool.ERASER}
            />
          </StackItem>
          <StackItem>
            <IconButton
              disabled={disabled}
              iconProps={{iconName: 'Refresh'}}
              onClick={() => resetCanvas()}
            />
          </StackItem>
        </Stack>
      </StackItem>
    </Stack>
  );
};

export default DrawCanvas;
