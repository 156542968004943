import './idb';
import './index.scss';

import { ApolloProvider } from '@apollo/react-common';
import { createTheme, initializeIcons, ThemeProvider } from '@fluentui/react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient, DefaultOptions } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { StateMachineProvider } from 'little-state-machine';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { settings } from './const/config';
import { refreshToken } from './graphql/mutations';
import i18n from './i18n';
import initStateStorage, { getToken, TokenType } from './state';
import { onApolloError } from './utils/onApolloError';

initializeIcons();
initStateStorage();

const httpLink = createHttpLink({
  uri: settings.API_URL,
  credentials: 'include',
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

const authLink = setContext((req, { headersReq }) => {
  const { language } = i18n;
  const token = getToken(TokenType.ACCESS);

  const headers = {
    ...headersReq,
    'Accept-Language': language,
  };

  //console.log(req.operationName);
  if (token && req.operationName !== 'refreshToken') {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return { headers };
});

const client = new ApolloClient({
  link: authLink.concat(onError(onApolloError).concat(httpLink)),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getNewToken = async (token: string): Promise<string> => {
  const ret = await client.mutate<{ refreshToken: string }>({
    mutation: refreshToken,
    variables: { token },
  });
  //ret?.data?.refreshToken;
  if (ret.data?.refreshToken) {
    return ret.data?.refreshToken;
  } else {
    throw new Error('No token');
  }
};

const theme = createTheme({ defaultFontStyle: { fontSize: 'large' },
  palette: {
    themePrimary: '#313d5a',
  }
});

ReactDOM.render(
  // @ts-ignore
  <ErrorBoundary>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <StateMachineProvider>
          <ThemeProvider className="fabric" theme={theme}>
            <App/>
          </ThemeProvider>
        </StateMachineProvider>
      </ApolloProvider>
    </BrowserRouter>
  </ErrorBoundary>,

  document.getElementById('root'),
)
;
