import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES } from './const/config';

i18n
  .use(resourcesToBackend((language: string, namespace: string) => import(`./assets/locales/${language}/${namespace}.json`)))
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: LANGUAGES,
    defaultNS: 'common',
    preload: LANGUAGES,
    ns: ['common', 'constraints', 'calendar', 'main'],
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  }).then(() => {
});


export default i18n;
