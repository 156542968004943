export interface Settings {
  API_URL: string;
  CAPTCHA_CLIENT_KEY: string;
  URL_UPLOAD: string;
  URL_UPLOAD_FILES: string;
  URL_SYNC: string;
  SENTRY_URL?: string;
  LOG_ROCKET?: string;
  TIME_ZONE?: string;
  DEV_PREFILL_LOGIN?: string;
  DEV_PREFILL_PASSWORD?: string;
  TEST_FLAG: boolean;
  VERSION: string;
}

declare global {
  interface Window {
    env?: Settings;
  }
}

export const settings: Settings = {
  API_URL: window.env?.API_URL || 'http://localhost:4000/graphql',
  CAPTCHA_CLIENT_KEY:
    window.env?.CAPTCHA_CLIENT_KEY || '0x4AAAAAAAzcM1I6XQ2Ay_Fe',
  URL_UPLOAD: window.env?.URL_UPLOAD || 'http://localhost:4000/upload',
  URL_SYNC: window.env?.URL_SYNC || 'http://localhost:4000/sync',
  URL_UPLOAD_FILES: window.env?.URL_UPLOAD_FILES || 'http://localhost:4000/files/',
  SENTRY_URL: window.env?.SENTRY_URL,
  LOG_ROCKET: window.env?.LOG_ROCKET,
  TIME_ZONE: window.env?.TIME_ZONE || 'America/Toronto',
  DEV_PREFILL_LOGIN: window.env?.DEV_PREFILL_LOGIN || undefined,
  DEV_PREFILL_PASSWORD: window.env?.DEV_PREFILL_PASSWORD || undefined,
  TEST_FLAG: window.env?.TEST_FLAG || false,
  VERSION: window.env?.VERSION || 'unknown',
};

//console.log(window.env);

export const PARAM_STEP = ':step?';
export const PARAM_SESSION = ':sessionCode';
export const PARAM_IS_EXISTING = ':isExisting?';
export const PARAM_DATE = ':date?';
export const PARAM_PRODUCT_TYPES = ':productTypes';
export const PARAM_EMAIL = ':email'

export const URL_UNSUBSCRIBE = `/unsubscribe/${PARAM_EMAIL}`;
export const URL_LOGIN = '/login';
export const URL_DASHBOARD = '/dashboard';
export const URL_MONTHLY_REPORT = `/montlyReport/${PARAM_DATE}`;
export const URL_PRODUCT_REPORT = `/productReport/${PARAM_DATE}`;
export const URL_INVOICE = `/invoice/${PARAM_SESSION}`;
export const URL_REQUEST = `/request/${PARAM_STEP}`;
export const URL_CONSENT = `/consent/${PARAM_SESSION}/${PARAM_PRODUCT_TYPES}`;
export const URL_INFO = '/info/:page';
export const URL_SESSION = `/session/${PARAM_SESSION}/${PARAM_STEP}`; //${PARAM_IS_EXISTING}
export const URL_SESSION_CHANGE = `/change/${PARAM_SESSION}/${PARAM_STEP}`;

export enum LANGUAGE {
  ENGLISH = 'en',
  FRENCH = 'fr',
  RUSSIAN = 'ru',
}

export const LANGUAGES = Object.values(LANGUAGE); // ,
export const SIGNATURE_MIN_STROKE = 10;
export const PRECISION = 100;
