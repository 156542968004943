/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from 'apollo-client';
import { GraphQLError } from 'graphql';
import { TFunction } from 'i18next';
import { FieldError } from 'react-hook-form';

import { ValidationError } from '../types/ValidationError';
import { isEmpty } from './isEmpty';

export const GLOBAL_ERROR = '_form';

export type FormErrors = {[key: string]: string[]};

export const convertToFormErrors = (e: ApolloError, t?: TFunction): FormErrors => {
  const errors: FormErrors = {};

  if (e.networkError !== null) {
    errors[GLOBAL_ERROR] = [t ? t('error.network') : 'Network error'];
  }

  if (e.graphQLErrors instanceof Array) {
    e.graphQLErrors.forEach((error: GraphQLError | & {validationErrors: ValidationError[]}) => {
      if ('validationErrors' in error) {
        error.validationErrors.forEach((validationError: ValidationError) => {
          if (validationError.constraints)
            Object.values(validationError.constraints).forEach((errorMessage) => {
              if (validationError.property) {
                if (!(validationError.property in errors))
                  errors[validationError.property] = Array<string>();
                errors[validationError.property].push(errorMessage);
              }
            });
          else {
            if (validationError.path) {
              if (!(validationError.path in errors)) errors[validationError.path] = Array<string>();
              errors[validationError.path].push(validationError.message || '');
            }
          }
        });
      } else {
        if ((error as any).hasOwnProperty('message')) {
          if (!(GLOBAL_ERROR in errors)) errors[GLOBAL_ERROR] = Array<string>();
          errors[GLOBAL_ERROR].push((error as any).message);
        }
      }
    });
  }

  if (isEmpty(errors)) {
    errors[GLOBAL_ERROR] = ['error.unknown'];
  }

  return errors;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const convertFieldErrorToString = (
  error: FieldError | (FieldError | undefined)[] | undefined,
): string | undefined => {
  if (error === undefined) return;
  const errors = error instanceof Array ? error : [error];
  return errors
    .flatMap((err: FieldError | undefined) => {
      return err ? err.message || (err.types && Object.values(err.types)) : [];
    })
    .join('\n');
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const convertFieldName = <FormFields>(v: string): string => {
  return v as Extract<keyof FormFields, string>;
};

export const convertErrors = (
  e: ApolloError | unknown,
  t: TFunction,
): {fieldErrors?: ValidationError[]; globalErrors?: Error[]} => {
  const globalErrors: Error[] = [];
  let fieldErrors: ValidationError[] = [];
  if (e instanceof ApolloError) {
    if (e.networkError !== null) {
      globalErrors.push({
        name: 'network',
        message: t('error.network'),
      });
    }

    if (e.graphQLErrors instanceof Array) {
      e.graphQLErrors.forEach((error: GraphQLError | {validationErrors: ValidationError[]}) => {
        if ('validationErrors' in error) {
          fieldErrors = error.validationErrors;
        } /*else if (error.hasOwnProperty('message')) {
        globalErrors.push({
          name: 'unknown',
          message: error.message,
        });
      }*/
      });
    }

    if (globalErrors.length === 0 && fieldErrors.length === 0) {
      globalErrors.push({
        name: 'unknown',
        message: t('error.unknown'),
      });
    }

    return {
      fieldErrors: fieldErrors.length !== 0 ? fieldErrors : undefined,
      globalErrors: globalErrors.length !== 0 ? globalErrors : undefined,
    };
  }
  return {globalErrors: [e as Error]};
};
