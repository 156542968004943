import {DefaultButton, Label, PrimaryButton, Stack, StackItem, TextField} from '@fluentui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import FaceImg from '../../assets/face_1080x1080.jpg';
import MarkerImg from '../../assets/spot.svg';
import MarkerSelectedImg from '../../assets/spot_selected.svg';
import {HotSpotImage} from '../../components/HotSpotImage/HotSpotImage';
import {SpotType} from '../../generated/graphql';
import {theme} from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import {availableSpots, spotProduct} from './Spots';
import {StageComponent} from './Stages';

interface ProcedureForm {
  spots: SpotType[];
  commentProcedure: string;
}

export const StageProcedure: StageComponent = (props) => {
  const {session, step, onNext, onPrev} = props;
  const {t} = useTranslation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ProcedureSchema = React.useMemo(
    () =>
      yup.object<ProcedureForm>().shape({
        spots: yup.array().min(0),
        commentProcedure: yup.string().nullable(),
      }),
    [],
  );

  const {handleSubmit, formState, control, watch} = useForm<ProcedureForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      spots: session.spots || [],
      commentProcedure: session.commentProcedure || '',
    },
    resolver: yupResolver(ProcedureSchema),
    criteriaMode: 'all',
  });

  const spots = watch('spots');

  const [hlSpot, setHlSpot] = React.useState<SpotType>();

  const productTypes = React.useMemo(() => {
    const mappedProduct = spots.map((v) => spotProduct[v]);
    if (mappedProduct.length === 0) return [];
    return mappedProduct.reduce((acc, current) => {
      const curAcc = acc ? [...acc] : [];
      current &&
        current.forEach((v) => {
          if (!curAcc.includes(v)) curAcc.push(v);
        });
      return curAcc;
    });
  }, [spots]);

  const spotsDisplay = React.useMemo(() => {
    return (
      availableSpots
        .filter((spot) => spots.includes(spot.id as SpotType) && !spot.wholeFace)
        .map((spot) => spot.id) || []
    );
  }, [spots]);

  const onSubmit = React.useCallback(
    (values) => {
      onNext && onNext(step, {...values});
    },
    [onNext, step],
  );

  const {isSubmitting} = formState;

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton
        disabled={isSubmitting || !formState.isValid}
        onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [isSubmitting, formState.isValid, t, handleSubmit, onSubmit],
  );

  const prevButton = React.useMemo(() => {
    return onPrev ? (
      <DefaultButton disabled={isSubmitting} onClick={async (): Promise<void> => onPrev()}>
        {t('button.previous')}
      </DefaultButton>
    ) : undefined;
  }, [isSubmitting, onPrev, t]);

  return (
    <Page title={t('page.session.title')}>
      <MultipageForm
        nextButton={nextButton}
        prevButton={prevButton}
        title={t(`page.session.${step}.title`)}>
        <form onSubmit={onSubmit} className='formCompact'>
          <Stack tokens={{childrenGap: theme.spacing.m}}>
            <StackItem>{t(`page.session.${step}.header`)}</StackItem>

            <StackItem className='hsp'>
              <div className='hotSpot'>
                <Controller
                  control={control}
                  name='spots'
                  render={({field: {onChange, value}}) => (
                    <HotSpotImage
                      mainSrc={FaceImg}
                      markerSrc={MarkerImg}
                      mappedWidth={1080}
                      markerRate={0.05}
                      markerSelectedSrc={MarkerSelectedImg}
                      spots={availableSpots}
                      selected={spots}
                      showFullFace={true}
                      onSpotChange={(spotId: string, active: boolean): void => {
                        setHlSpot(active ? (spotId as SpotType) : undefined);
                      }}
                      onSpotClick={(id) => {
                        onChange(
                          spots.includes(id as SpotType)
                            ? spots.filter((i) => i !== id)
                            : [...spots, id as SpotType],
                        );
                      }}
                    />
                  )}
                />
              </div>
            </StackItem>
            <StackItem>{hlSpot ? t(`spots.${hlSpot}.name`) : '\u00A0'}</StackItem>
            <StackItem>
              {spotsDisplay.length > 0 && t('page.session.procedure.text.desiredResult')}
              <ul>
                {spotsDisplay.map((spotId) => (
                  <li key={spotId}>{t(`spots.${spotId}.result`)}</li>
                ))}
              </ul>
              {productTypes.length > 0 && t('page.session.procedure.text.requiredProduct')}
              <ul>
                {productTypes.map((v) => (
                  <li key={v}>{t(`product.${v}`)}</li>
                ))}
              </ul>
            </StackItem>
          </Stack>
        </form>
      </MultipageForm>
    </Page>
  );
};

export default StageProcedure;
