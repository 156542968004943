import { DefaultButton } from '@fluentui/react';
import { useLiveQuery } from 'dexie-react-hooks';
import { FC, useState } from 'react';

import { settings } from '../const/config';
import db from '../idb';
import SyncWorker from '../worker';

const syncWorker = new SyncWorker();

const FileSyncer: FC = () => {
  const pendingUploads = useLiveQuery(() => db.uploads.toArray());
  const [isSyncing, setIsSyncing] = useState(false);

  return pendingUploads?.length ? (
    <div>
      <DefaultButton
        disabled={(pendingUploads?.length || 0) === 0 || isSyncing}
        text={`Sync ${pendingUploads?.length} files`}
        onClick={async () => {
          try {
            setIsSyncing(true);
            const files = await db.uploads.toArray();
            const result = await syncWorker.syncFiles(settings.URL_SYNC, files);
            await Promise.all(result.map((id) => db.uploads.delete(id)));
          } catch (errorClick) {
            console.error(errorClick);
          } finally {
            setIsSyncing(false);
          }
        }}
      />
    </div>
  ) : null;
};

export default FileSyncer;
