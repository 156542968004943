import { useMutation } from '@apollo/react-hooks';
import { MessageBar, MessageBarType, PrimaryButton, Spinner, Stack, StackItem } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { URL_SESSION } from '../../const/config';
import { MediaConsentType, MutationsubmitAppointmentDetailsArgs } from '../../generated/graphql';
import { submitAppointmentDetails } from '../../graphql/mutations';
import { theme } from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { convertToFormErrors, FormErrors, GLOBAL_ERROR } from '../../utils/convertToFormErrors';
import { isEmpty } from '../../utils/isEmpty';
import { SessionStage, StageComponent, StageProps } from './Stages';

export const StageSubmit: StageComponent = (props: StageProps) => {
  const { session, step, onNext } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [runMutation, result] = useMutation<boolean, MutationsubmitAppointmentDetailsArgs>(
    submitAppointmentDetails,
  );
  const isMounted = React.useRef<boolean>();
  React.useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);
  const [errors, setErrors] = React.useState<FormErrors>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { called, loading } = result;

  const onSubmit = React.useCallback(() => {
    let errors: FormErrors;
    return new Promise<void>(async (resolve, reject) => {
      const {
        patient,
        spots,
        photos,
        answers,
        sessionCode,
        signatureSVG,
        commentHealth,
        commentProcedure,
        mediaConsent = MediaConsentType.NONE
      } = session;
      try {
        //console.log(session);

        if (!patient) throw new Error();

        const { firstName, lastName, phone, email, birthday, gender } = patient;

        await runMutation({
          variables: {
            params: {
              patient: {
                firstName: firstName?.trim() || '',
                lastName: lastName?.trim() || '',
                phone: phone?.replace(/[^0-9]/g, '')?.trim() || '',
                gender,
                email: email?.trim() || '',
                birthday: new Date(birthday).getTime() / 1000,
              },
              mediaConsent,
              sessionCode: sessionCode?.trim() || '',
              commentHealth: commentHealth?.trim() || '',
              commentProcedure: commentProcedure?.trim() || '',
              spots: spots || [],
              photos: photos
                ? photos
                  .map((v) => {
                    if (v) delete v.__typename;
                    return v;
                  })
                  .filter((v) => !!v)
                : [],
              answers: answers
                ? answers.map((v) => {
                  delete v.__typename;
                  return v;
                })
                : [],
              signatureSVG: signatureSVG?.trim() || '',
            },
          },
        });

        onNext && onNext(SessionStage.SUBMIT, {}, true);

        history.replace(
          URL_SESSION.replace(/:sessionCode/, sessionCode || '').replace(
            /:step\?/,
            SessionStage.PENDING,
          ),
        );
      } catch (e) {
        errors = convertToFormErrors(e as ApolloError);
        //console.log(e);
      } finally {
        isMounted.current && setErrors(errors);
        resolve();
      }
    });
  }, [history, onNext, runMutation, session]);

  React.useEffect(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Page>
      <MultipageForm
        title={t(`page.session.${step}.title`, {
          context: loading ? 'submit' : !isEmpty(errors) ? 'error' : '',
        })}>
        <Stack tokens={{ childrenGap: theme.spacing.m }}>
          <StackItem>
            {!called || loading ? (
              <Spinner label={t('message.progress', { context: 'submit' })}/>
            ) : (
              errors && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                  {Object.entries(errors).map(([key, value]) => (
                    <div key={key}>
                      {value.map((message, idx) => (
                        <div key={key + idx}>{key === GLOBAL_ERROR ? t(message) : message}</div>
                      ))}
                    </div>
                  ))}
                </MessageBar>
              )
            )}
          </StackItem>
          {errors && errors[GLOBAL_ERROR] && errors[GLOBAL_ERROR].includes('error.network') && (
            <StackItem>
              <PrimaryButton
                onClick={onSubmit}>
                {t('button.retry')}
              </PrimaryButton>
            </StackItem>
          )}
        </Stack>
      </MultipageForm>
    </Page>
  );
};

export default StageSubmit;
