import {DefaultButton, PrimaryButton, Stack, StackItem} from '@fluentui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import SampleFrown from '../../assets/frown.svg';
import SampleLeft from '../../assets/left.svg';
import SampleNeutral from '../../assets/neutral.svg';
import SampleRight from '../../assets/right.svg';
import SampleSurprise from '../../assets/surprise.svg';
import {settings} from '../../const/config';
import {Photo, PhotoType, Toggle} from '../../generated/graphql';
import ImageUpload from '../../organisms/ImageUpload';
import {theme} from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import {StageComponent} from './Stages';

interface PhotoForm {
  LEFT: Photo;
  NEUTRAL: Photo;
  RIGHT: Photo;
  FROWN: Photo;
  SURPRISE: Photo;
}

const PhotoTypes = [
  PhotoType.LEFT,
  PhotoType.NEUTRAL,
  PhotoType.RIGHT,
  PhotoType.FROWN,
  PhotoType.SURPRISE,
];

const samples: {[key: string]: string} = {};
samples[PhotoType.LEFT] = SampleLeft;
samples[PhotoType.NEUTRAL] = SampleNeutral;
samples[PhotoType.RIGHT] = SampleRight;
samples[PhotoType.FROWN] = SampleFrown;
samples[PhotoType.SURPRISE] = SampleSurprise;

const arrayToMap = (photos?: Photo[]): {[key: string]: Photo} => {
  const ret = {};
  if (photos) for (const f of photos) if (f) ret[f.type] = f;
  return ret;
};

export const StagePhoto: StageComponent = (props) => {
  const {session, step, onNext, onPrev} = props;

  const isExistingClient = session.patient?.existingClient === Toggle.YES;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {t} = useTranslation();

  const PhotoSchema = React.useMemo(
    () =>
      isExistingClient || true
        ? yup.mixed()
        : yup.object<PhotoForm>().shape({
            LEFT: yup.mixed().required(t('constraints:isNotEmpty')),
            RIGHT: yup.mixed().required(t('constraints:isNotEmpty')),
            NEUTRAL: yup.mixed().required(t('constraints:isNotEmpty')),
            FROWN: yup.mixed().required(t('constraints:isNotEmpty')),
            SURPRISE: yup.mixed().required(t('constraints:isNotEmpty')),
          }),
    [isExistingClient, t],
  );

  const {handleSubmit, formState, control} = useForm<PhotoForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(PhotoSchema),
    defaultValues: session.photos ? arrayToMap(session.photos) : {},
    criteriaMode: 'all',
  });

  const onSubmit = React.useCallback(
    (values) => {
      onNext && onNext(step, {photos: Object.values(values)});
    },
    [onNext, step],
  );

  const {isSubmitting} = formState;

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton
        disabled={isSubmitting || !formState.isValid}
        onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [isSubmitting, formState.isValid, t, handleSubmit, onSubmit],
  );

  const prevButton = React.useMemo(() => {
    return onPrev ? (
      <DefaultButton disabled={isSubmitting} onClick={async (): Promise<void> => onPrev()}>
        {t('button.previous')}
      </DefaultButton>
    ) : undefined;
  }, [isSubmitting, onPrev, t]);

  return (
    <Page title={t('page.session.title')}>
      <MultipageForm
        nextButton={nextButton}
        prevButton={prevButton}
        title={t(`page.session.${step}.title`)}>
        <Stack tokens={{childrenGap: theme.spacing.m}}>
          <StackItem>{t(`page.session.${step}.header`)}</StackItem>
          <StackItem>
            <div
              style={{
                display: 'grid',
                gridGap: '2px 2px',
                gridTemplateColumns: 'repeat(auto-fit,minmax(200px,1fr))',
              }}>
              {PhotoTypes.map((pt) => (
                <Controller
                  key={pt}
                  control={control}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  name={pt as any}
                  render={({field: {onChange, value}}) => (
                    <ImageUpload
                      token={session.sessionCode}
                      uploadURL={settings.URL_UPLOAD}
                      sampleImage={samples[pt]}
                      imageType={pt}
                      imageName={t(`page.session.photo.sample.${pt}`)}
                      onChange={(_, file) => {
                        onChange(file);
                      }}
                      value={value}
                    />
                  )}
                />
              ))}
            </div>
          </StackItem>
        </Stack>
      </MultipageForm>
    </Page>
  );
};

export default StagePhoto;
