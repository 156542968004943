import {deleteFromStorage, writeStorage} from '@rehooks/local-storage';
import {createStore, setStorageType} from 'little-state-machine';
import {DateTime} from 'luxon';

import {SessionStatus} from '../generated/graphql';
import {PeriodType} from '../pages/Dashboard/types';
import {GlobalState} from './globalState';
import {settings} from '../const/config';

const initGlobalStorage = (): void => {
  setStorageType(localStorage);
  createStore<GlobalState>(
    {
      session: {},
      isLowDataMode: false,
      user: {
        selectedDate: DateTime.local(),
        periodType: PeriodType.WORKWEEK,
        filterStatus: [SessionStatus.REQUEST, SessionStatus.PENDING],
        filterText: '',
      },
    },
    {
      name: 'juvenis',
      syncStores: {
        externalStoreName: 'juvenis',
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        transform: ({externalStoreData, currentStoreData}) => {
          let {
            user: {selectedDate, periodType},
          } = externalStoreData as GlobalState;
          const {
            user: {selectedDate: _, periodType: __, ...rest},
          } = externalStoreData as GlobalState;
          switch (typeof selectedDate) {
            case 'string':
              selectedDate = DateTime.fromISO(selectedDate,{zone:settings.TIME_ZONE});
              break;
            case 'object':
              break;
            default:
              selectedDate = DateTime.fromObject({zone:settings.TIME_ZONE});
          }
          if (!periodType) periodType = PeriodType.MONTH;
          const ret = {
            ...externalStoreData,
            ...currentStoreData,
            ...{user: {selectedDate, periodType, ...rest}},
          };
          return ret;
        },
      },
    },
  );
};

//const storage = localStorage;

export const TOKEN_ACCESS = 'accessToken';
export const TOKEN_REFRESH = 'refreshToken';

export enum TokenType {
  ACCESS,
  REFRESH,
}

export const getToken = (tokenType: TokenType): string | null =>
  localStorage.getItem(tokenType === TokenType.ACCESS ? TOKEN_ACCESS : TOKEN_REFRESH);

export const setToken = (tokenType: TokenType, token: string): void => {
  //console.log("Write storage", tokenType, token);
  writeStorage(tokenType === TokenType.ACCESS ? TOKEN_ACCESS : TOKEN_REFRESH, token);
};

export const logOut = (): void => {
  deleteFromStorage(TOKEN_ACCESS);
  deleteFromStorage(TOKEN_REFRESH);
};

export default initGlobalStorage;
