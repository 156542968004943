import gql from 'graphql-tag';

const query = gql`
  mutation addSlot($timeSlot: TimeSlotInput!) {
    addSlot(timeSlot: $timeSlot) {
      id
      version
      user {
        id
      }
      date
      length
      availability
    }
  }
`;

export default query;
