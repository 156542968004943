import React from 'react';
import { Session, SessionStatus } from '../../generated/graphql';
import { StageConsentSign } from './StageConsent';
import StagePatient from './StagePatient';
import StagePhone from './StagePhone';
import StagePhoto from './StagePhoto';
import StageProcedure from './StageProcedure';
import { StageQuestionary } from './StageQuestionary';
import StageSubmit from './StageSubmit';
import StageSubmitRequest from './StageSubmitRequest';
import StageTerms from './StageTerms';
import StageTimeslot, { StageConsentChange } from './StageTimeslot';

export enum SessionStage {
  INIT = 'init',
  PATIENT = 'patient',
  PROCEDURE = 'procedure',
  QUESTIONARY = 'questionary',
  TIME = 'time',
  TERMS = 'terms',
  PHOTO = 'photo',
  CONSENT = 'consent',
  SUBMIT = 'submit',
  COMPLETED = 'completed',
  CHANGED = 'changed',
  CANCELED = 'cancelled',
  PENDING = 'success',
  PENDING_EXISTING = 'success_existing',
  REQUEST = 'sent',
  UNAVAILABLE = 'unavailable',
  STANDBY = 'standby',
  PHONE = 'phone'
}

export const StatusStage: { [key: string]: string } = {};
StatusStage[SessionStatus.CANCELED] = SessionStage.CANCELED;
StatusStage[SessionStatus.COMPLETED] = SessionStage.COMPLETED;
StatusStage[SessionStatus.PENDING] = SessionStage.PENDING;

export const StepMessage: SessionStage[] = [
  SessionStage.STANDBY,
  SessionStage.CANCELED,
  SessionStage.CHANGED,
  SessionStage.COMPLETED,
  SessionStage.PENDING,
  SessionStage.REQUEST,
  SessionStage.PENDING_EXISTING
];

export const getPreviousStage = (
  stageType: StageType,
  currentStage: SessionStage,
): SessionStage => {
  const stages = Stages[stageType];
  return stages[Math.max(stages.indexOf(currentStage) - 1, 0)];
};

export const getNextStage = (stageType: StageType, currentStage: SessionStage): SessionStage => {
  const stages = Stages[stageType];
  return stages[Math.min(stages.indexOf(currentStage) + 1, stages.length - 1)];
};

export type NextHandler = (step: SessionStage, update: Partial<Session>, doReset?: boolean) => void;

export enum StageType {
  REQUEST = 'REQUEST',
  DETAILS = 'DETAILS',
  CHANGE = 'CHANGE',
  DETAILS_EXISTING = 'DETAILS_EXISTING'
}

export interface StageProps {
  session: Partial<Session>;
  step: SessionStage;
  type: StageType;
  isLastOne?: boolean;
  onPrev?: () => void;
  onNext?: NextHandler;
}

export type StageComponent<T = unknown> = React.FunctionComponent<StageProps & T>;

type StageComponentsType = { [key in SessionStage]?: StageComponent };

export const StageComponents: Record<StageType, StageComponentsType> = {
  REQUEST: {},
  DETAILS: {},
  CHANGE: {},
  DETAILS_EXISTING: {}
};

export const Stages: Record<StageType, SessionStage[]> = {
  DETAILS: [
    SessionStage.PATIENT,
    SessionStage.PROCEDURE,
    SessionStage.QUESTIONARY,
    SessionStage.PHOTO,
    SessionStage.CONSENT,
    SessionStage.TERMS,
    SessionStage.SUBMIT,
  ],
  DETAILS_EXISTING: [
    SessionStage.TERMS,
    SessionStage.SUBMIT,
  ],
  REQUEST: [SessionStage.PATIENT, SessionStage.TIME, SessionStage.SUBMIT],
  CHANGE: [SessionStage.TIME],
};


StageComponents.DETAILS[SessionStage.PATIENT] = StagePatient;
StageComponents.DETAILS[SessionStage.PROCEDURE] = StageProcedure;
StageComponents.DETAILS[SessionStage.QUESTIONARY] = StageQuestionary;
StageComponents.DETAILS[SessionStage.TERMS] = StageTerms;
StageComponents.DETAILS[SessionStage.PHOTO] = StagePhoto;
StageComponents.DETAILS[SessionStage.CONSENT] = StageConsentSign;
StageComponents.DETAILS[SessionStage.SUBMIT] = StageSubmit;
StageComponents.REQUEST[SessionStage.PHONE] = StagePhone;
StageComponents.REQUEST[SessionStage.PATIENT] = StagePatient;
StageComponents.REQUEST[SessionStage.PROCEDURE] = StageProcedure;
StageComponents.REQUEST[SessionStage.TIME] = StageTimeslot;
StageComponents.REQUEST[SessionStage.SUBMIT] = StageSubmitRequest;
StageComponents.CHANGE[SessionStage.TIME] = StageConsentChange;
StageComponents.DETAILS_EXISTING[SessionStage.TERMS] = StageTerms;
StageComponents.DETAILS_EXISTING[SessionStage.SUBMIT] = StageSubmit;
