import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Cell, Grid } from 'styled-css-grid';

import { URL_LOGIN } from '../const/config';
import LanguageSelector from './LanguageSelector';

interface NavigationBarProps {
  showSignIn?: boolean;
}

const NavigationBar: React.FunctionComponent<NavigationBarProps> = (props) => {
  const {t} = useTranslation();

  return (
    <Grid columns={'1fr auto auto'}>
      <Cell className='navLinks' middle>
        <Link to='/'>{t('navigation.home')}</Link>
      </Cell>
      <Cell>
        <LanguageSelector />
      </Cell>
      <Cell middle className='navLinks'>
        {props.showSignIn && <Link to={URL_LOGIN}>{t('navigation.login')}</Link>}
      </Cell>
    </Grid>
  );
};

export default NavigationBar;
