export enum Tool {
  PEN = 'pen',
  ERASER = 'eraser',
}

export enum CompositeOperations {
  pen = 'source-over',
  eraser = 'destination-out',
}

export interface BasicPoint {
  x: number;
  y: number;
  time: number;
}

export class Point implements BasicPoint {
  public time: number;

  constructor(public x: number, public y: number, time?: number) {
    this.time = time || Date.now();
  }

  public distanceTo(start: BasicPoint): number {
    return Math.sqrt((this.x - start.x) ** 2 + (this.y - start.y) ** 2);
  }

  public equals(other: BasicPoint): boolean {
    return this.x === other.x && this.y === other.y && this.time === other.time;
  }

  public velocityFrom(start: BasicPoint): number {
    return this.time !== start.time ? this.distanceTo(start) / (this.time - start.time) : 0;
  }
}

export interface PointGroup {
  tool: Tool;
  width: number;
  points: BasicPoint[];
}

export interface RefState {
  data: PointGroup[];
}

export type Size = {
  width: number;
  height: number;
};

export type VoidFunc = () => void;
