import { Link } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { SectionProps } from '.';
import Drawer from '../../components/Drawer';

//import AboutImage from "../../assets/about.jpg";
const About: React.FunctionComponent<SectionProps> = (props) => {
  const {className} = props;
  const {t} = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  return (
    <section className={className}>
      {/*
      <div>
        <h2>{t("main:about.title")}</h2>
      </div>
      
        <div>
          <img src={AboutImage} width="100%" alt="" />
      </div>
      */}
      <div>
        <ReactMarkdown>{t('main:about.short')}</ReactMarkdown>
      </div>
      {!showMore && (
        <Link
          onClick={(): void => {
            setShowMore(true);
          }}>
          {t('button.learnMore')}
        </Link>
      )}
      <Drawer isOpen={showMore}>
        <ReactMarkdown>{t('main:about.more')}</ReactMarkdown>
      </Drawer>
    </section>
  );
};

export default About;
