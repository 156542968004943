import {ITextFieldProps, TextField} from '@fluentui/react';

export const TextArea: React.FunctionComponent<ITextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      rows={1}
      multiline
      styles={{fieldGroup: {minHeight: 32, height: 'auto'}}}
    />
  );
};
