import {
  CommandButton,
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuItem,
  IContextualMenuProps,
} from '@fluentui/react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../const/config';

const LanguageSelector: React.FunctionComponent = (props) => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const menuProps = React.useMemo((): IContextualMenuProps => {
    const langMenu: IContextualMenuItem[] = LANGUAGES.map((key, idx) => {
      return {
        key: key,
        checked: key === i18n.language,
        text: t(`languages.${key}.name`),
        canCheck: true,
        onClick: (): void => {
          i18n.changeLanguage(key);
        },
      };
    });

    return {
      isBeakVisible: false,
      alignTargetEdge: true,
      directionalHint: DirectionalHint.bottomLeftEdge,
      items: [
        {
          key: 'lang',
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            bottomDivider: true,
            title: t('menu.languages'),
            items: langMenu,
          },
        },
      ],
    };
  }, [i18n, t]);

  return (
    <CommandButton menuProps={menuProps}>{t(`languages.${currentLanguage}.name`)}</CommandButton>
  );
};

export default LanguageSelector;
