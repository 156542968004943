import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Cell, Grid } from 'styled-css-grid';

import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { SessionStage, StageComponent, StageType } from './Stages';

export const StageMessage: StageComponent = (props) => {
  const {step, type} = props;
  const {t} = useTranslation();
  return (
    <Page title={t(type === StageType.DETAILS ? 'page.session.title' : 'page.request.title')}>
      <MultipageForm title={t(`page.session.${step}.title`)}>
        <Grid style={{width: '100%', minHeight: '50vh'}} columns={'1fr'} rows={'1fr'}>
          <Cell center middle className='messageText'>
            <ReactMarkdown transformLinkUri={null} children={t(`page.session.${step}.text`)} />
          </Cell>
        </Grid>
      </MultipageForm>
    </Page>
  );
};

export default StageMessage;
