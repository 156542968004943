import {
  FontSizes,
  Icon,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Modal,
  ProgressIndicator,
  Stack,
  StackItem,
} from '@fluentui/react';
import axios, { AxiosRequestConfig } from 'axios';
import { useStateMachine } from 'little-state-machine';
import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import db from '../idb';
import { GlobalState } from '../state/globalState';
import { theme } from '../styles/theme';
import { UploadedFile } from '../types/UploadedFile';

const styles = mergeStyleSets({
  root: {
    width: '100%',
    minHeight: '150px',
    height: '100%',
    position: 'relative',
    border: '1px dashed gray',
    cursor: 'pointer',
    userSelect: 'none',
  },

  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    fontSize: FontSizes.xxLarge,
    transform: 'translate(-50%,-50%)',
  },

  modal: {
    width: '300px',
    //height: '50px',
  },
});

interface UploaderProps {
  uploadURL: string;
  maxContentLength?: number;
  token?: string;
  isPDFAllowed?: boolean;
  notifyUpload?: (files: UploadedFile[]) => void;
}

const Uploader: React.FunctionComponent<UploaderProps> = (props) => {
  const {t} = useTranslation();
  const [isOpen, setOpen] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [progress, setProgress] = React.useState(0);

  const {
    state: {isLowDataMode},
  } = useStateMachine<GlobalState>();

  const {maxContentLength, token, uploadURL, notifyUpload, isPDFAllowed} = props;

  const source = useMemo(() => axios.CancelToken.source(), []);

  const config: AxiosRequestConfig = useMemo(() => {
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      maxContentLength,
      cancelToken: source.token,
      onUploadProgress: (progressEvent: ProgressEvent): void => {
        if (progressEvent.lengthComputable) {
          setProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
        }
      },
    };
  }, [maxContentLength, source.token]);

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setOpen(true);
      if (isLowDataMode) {
        try {
          const data = await Promise.all(
            acceptedFiles.map((acceptedFile) => db.storeFile(acceptedFile)),
          );
          if (notifyUpload && data && data instanceof Array) {
            notifyUpload(data);
          }
        } catch (err) {
          setError(t('error.uploadFailed'));
        }
      } else {
        const fd = new FormData();
        token && fd.append('token', token);
        acceptedFiles.forEach((file: File, index: number) => {
          fd.append('File' + index, file);
        });
        try {
          const response = await axios.post<UploadedFile[]>(uploadURL, fd, config);
          const {data} = response;
          if (notifyUpload && data && data instanceof Array) {
            notifyUpload(data);
          }
        } catch (err) {
          setError(t('error.uploadFailed'));
        } finally {
        }
      }
      setOpen(false);
    },

    [config, notifyUpload, t, token, uploadURL, isLowDataMode],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png', 'image/gif', ...(isPDFAllowed ? ['application/pdf'] : [])],
  });

  if (error) {
    return (
      <MessageBar
        messageBarType={MessageBarType.error}
        onDismiss={(): void => {
          setError(undefined);
        }}>
        {error}
      </MessageBar>
    );
  }

  return (
    <Stack tokens={{childrenGap: theme.spacing.s2}}>
      <StackItem>
        <div className={styles.root} {...getRootProps()}>
          {isDragActive ? (
            <Icon className={styles.icon} iconName='CloudUpload' title={t('file.drop')} />
          ) : (
            <Icon className={styles.icon} iconName='CloudAdd' title={t('file.upload')} />
          )}
        </div>
        <input {...getInputProps()} />
        <Modal isModeless={false} isBlocking={true} isOpen={isOpen}>
          <div className={styles.modal}>
            <MessageBar
              isMultiline={false}
              onDismiss={(): void => {
                source.cancel();
                setOpen(false);
              }}
              dismissButtonAriaLabel={t('cancel')}
              truncated={false}>
              <ProgressIndicator
                label={t('progress')}
                percentComplete={progress}
                styles={{root: {width: '200px'}}}
              />
            </MessageBar>
          </div>
        </Modal>
      </StackItem>
    </Stack>
  );
};

export default Uploader;
