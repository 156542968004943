import { FontSizes, FontWeights, mergeStyleSets } from '@fluentui/react';
import { theme } from '../../styles/theme';
import { PADDING, PADDING32 } from './const';

export const styles = mergeStyleSets({
  container: {
    paddingTop: PADDING,
  },

  treatmentHeader: {
    fontSize: FontSizes.large,
    backgroundColor: theme.palette.themeLighter,
    padding: PADDING,
    cursor: 'pointer',

    fontWeight: FontWeights.bold,

    display: 'grid',
    gridTemplateColumns: '1fr repeat(3, auto)',
    gridTemplateRows: '1fr',
    gap: '4px',
    alignItems: 'center',

    selectors: {
      '@media(max-width: 768px)': {
        gridAutoFlow: 'column',
        gridTemplateRows: 'repeat(2,32px)',
        gridTemplateColumns: '1fr',
      },
    },
  },

  bgColor: {
    backgroundColor: '#D4D9E49F'
  },

  error: {
    color: '#CC0000'
  },

  icon: {
    fontSize: '16px',
    cursor: 'pointer',
  },

  valign: {
    display: 'flex',
    alignItems: 'center',
  },

  sectionHeader: {
    fontSize: FontSizes.medium,
    backgroundColor: theme.palette.neutralLighter,
    padding: PADDING,
    marginTop: PADDING32,
    marginBottom: PADDING,
    gridArea: 'sectionHeader',
  },

  img: { width: '100%', objectFit: 'contain', maxWidth: '90vw', maxHeight: '90vh' },
  thumb: { maxHeight: '200px' },

  photo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5,1fr)',
    gap: '1px 1px',
    gridTemplateAreas:
      '"sectionHeader sectionHeader sectionHeader sectionHeader sectionHeader" ". . . . ."',
  },

  procedure: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateAreas: '"sectionHeader sectionHeader" ". ."',
    selectors: {
      '@media(max-width: 768px)': {
        gridTemplateAreas: '"sectionHeader" "." "."',
        gridAutoFlow: 'column',
        gridAutoRows: 'auto',
        gridTemplateColumns: '1fr',
      },
    },
  },

  medical: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '1px 0px',
    gridTemplateAreas: '"sectionHeader sectionHeader" "merge2 merge2" ". ."',
  },

  question: {
    fontSize: FontSizes.smallPlus,
    borderTop: '1px solid black',
  },

  questionSize: {
    fontSize: FontSizes.smallPlus,
  },

  marginV: {
    marginTop: PADDING,
    marginBottom: PADDING,
  },

  merge2: {
    gridArea: 'merge2',
  },

  hotSpot: {
    minWidth: '300px',
    margin: 'auto',
    width: '50%',
  },

  consent: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '1px 1px',
    gridTemplateAreas: '"sectionHeader sectionHeader" "merge2 merge2" ". ."',
    selectors: {
      '@media(max-width: 768px)': {
        gridTemplateAreas: '"sectionHeader" "merge2" "." "."',
        gridAutoFlow: 'column',
        gridAutoRows: 'auto',
        gridTemplateColumns: '1fr',
      },
    },
  },

  signature: {
    maxWidth: '50vw',
  },
});
