import { DateTime } from 'luxon';

import { TimeSlot } from '../../generated/graphql';
import { DashboardAction, DashboardActionType, DashboardState } from './Context';
import { DateTimeRange, PeriodType } from './types';

export const DashboardReducer = (
  state: DashboardState,
  action: DashboardAction,
): DashboardState => {
  switch (action.type) {
    case DashboardActionType.Filter:
      const periodType = action.periodType || state.periodType;
      const selectedDate = action.selectedDate || state.selectedDate;
      const searchTextDB = action.searchTextDB || state.searchTextDB;
      return {
        ...state,
        selectedDate,
        periodType,
        searchTextDB,
        range: getRange(selectedDate, periodType),
      };
    case DashboardActionType.LoadTimeSlots: {
      const {timeSlots} = action;
      return {...state, timeSlots};
    }
    case DashboardActionType.TimeSlot:
      const {timeSlot} = action;
      return {...state, timeSlot};
    case DashboardActionType.Patient:
      const {patient} = action;
      return {...state, patient};
    case DashboardActionType.PopUp:
      const {message} = action;
      return {...state, message};
    case DashboardActionType.UpdatePatient: {
      const {patient} = action;
      const {timeSlots} = state;
      const newTimeSlots = timeSlots?.map((ts) => {
        if (ts.session?.patient.id === patient.id) {
          return {...ts, session: {...ts.session, patient}};
        }
        return ts;
      });
      return {...state, timeSlots: newTimeSlots};
    }
    case DashboardActionType.UpdateTimeSlot: {
      const {timeSlot, action: actionCode} = action;
      const {
        range: {startDate, endDate},
        timeSlots,
      } = state;
      switch (actionCode) {
        case 'remove':
          return {...state, timeSlots: timeSlots?.filter((ts) => ts.id !== timeSlot.id)};
        case 'create':
          //console.log(timeSlot);
          if (startDate.toSeconds() <= timeSlot.date && timeSlot.date <= endDate.toSeconds()) {
            return {...state, timeSlots: timeSlots ? timeSlots.concat(timeSlot) : [timeSlot]};
          }
      }
      return state;
    }
    case DashboardActionType.UpdateSession: {
      const {session} = action;
      const {timeSlots, range} = state;
      const {id: sessionId, timeSlot} = session;
      let newTimeSlots: TimeSlot[];
      if (timeSlot) {
        // TODO userID
        newTimeSlots =
          timeSlots?.slice().map((ts) => {
            return ts?.session?.id === session.id ? {...ts, session: undefined} : ts;
          }) || [];

        if (
          range.startDate.toSeconds() < timeSlot.date &&
          timeSlot.date < range.endDate.toSeconds()
        ) {
          const idx = newTimeSlots.findIndex((v) => v.date === timeSlot.date);
          if (idx !== -1) {
            newTimeSlots[idx].session = session;
          } else {
            session.timeSlot && newTimeSlots.push({...session.timeSlot, session});
          }
        }
      } else {
        newTimeSlots =
          timeSlots?.map((ts) => {
            return ts?.session?.id === sessionId ? {...ts, session: undefined} : ts;
          }) || [];
      }
      return {...state, timeSlots: newTimeSlots};
    }
    default:
      console.log('UNKNOWN', action);
      return state;
  }
};

export const getRange = (selectedDate: DateTime, periodType: PeriodType): DateTimeRange => {
  switch (periodType) {
    case PeriodType.MONTH:
      const ret = {
        startDate: selectedDate.startOf('month'),
        endDate: selectedDate.endOf('month'),
      };

      ret.startDate = ret.startDate.minus({days: ret.startDate.weekday - 1});
      ret.endDate = ret.endDate.plus({days: 7 - ret.endDate.weekday});
      return ret;
    case PeriodType.WORKWEEK:
    case PeriodType.WEEK:
      return {startDate: selectedDate.startOf('week'), endDate: selectedDate.endOf('week')};
    default:
      return {startDate: selectedDate.startOf('day'), endDate: selectedDate.endOf('day')};
  }
};
