import {useMutation} from '@apollo/react-hooks';
import {
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  IChoiceGroupOption,
  mergeStyleSets,
  MessageBarType,
  TextField,
} from '@fluentui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import {ApolloError} from 'apollo-client';
import {DateTime} from 'luxon';
import React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import FieldError from '../../components/FieldError';
import {Gender, MutationsavePatientArgs, Patient, Toggle} from '../../generated/graphql';
import {savePatient} from '../../graphql/mutations';
import {InlineChoiceItemStyle} from '../../styles/InlineChoiceStyle';
import {PhoneRegexp} from '../../utils';
import {convertErrors, convertFieldErrorToString} from '../../utils/convertToFormErrors';
import {formatPhone} from '../../utils/formatPhone';
import {PADDING} from './const';
import {DashboardActionType, useDashboardContext} from './Context';
import {settings} from '../../const/config';

/* eslint-disable quotes */
interface PatientFormProps {
  patient?: Patient;
  readOnly?: boolean;
  onUpdate?: (patient: Patient) => void;
  onDismiss?: () => void;
}

type PatientData = Pick<Patient, 'firstName' | 'phone' | 'birthday' | 'lastName' | 'existingClient' | 'gender' | 'email'>

const styles = mergeStyleSets({
  gridContainer: {
    display: 'grid',
    gap: '4px 4px',
    selectors: {
      '@media(max-width: 768px)': {
        gridTemplateColumns: '1fr',
        gridTemplateAreas:
          '\'firstName\' \'lastName\' \'birthday\' \'existingClient\' \'gender\' \'phone\' \'email\' \'buttons\'',
      },
    },
    gridTemplateColumns: 'repeat(2,1fr)',
    gridTemplateAreas:
      '\'firstName lastName\' \'existingClient gender\' \'phone email\' \'birthday buttons\'',
  },

  gender: {gridArea: 'gender'},
  existingClient: {gridArea: 'existingClient'},
  birthday: {gridArea: 'birthday'},
  phone: {gridArea: 'phone'},
  email: {gridArea: 'email'},
  firstName: {gridArea: 'firstName'},
  lastName: {gridArea: 'lastName'},
  close: {gridArea: 'close'},
  buttons: {gridArea: 'buttons', textAlign: 'right'},
});

//type FormFieldNames = Extract<keyof Patient, string>;

export const PatientForm: React.FunctionComponent<PatientFormProps> = (props) => {
  const {t} = useTranslation();

  const [patient, setPatient] = React.useState(props.patient);

  const [mutationSavePatient] = useMutation<{savePatient: Patient}, MutationsavePatientArgs>(
    savePatient,
  );

  const {onUpdate, onDismiss} = props;

  const {dispatch} = useDashboardContext();

  const genderOptions: IChoiceGroupOption[] = React.useMemo(
    () => [
      {
        styles: InlineChoiceItemStyle,
        key: Gender.FEMALE,
        text: t('option.gender.female'),
      },
      {
        styles: InlineChoiceItemStyle,
        key: Gender.MALE,
        text: t('option.gender.male'),
      },
      {
        styles: InlineChoiceItemStyle,
        key: Gender.X,
        text: t('option.gender.x'),
      },
    ],
    [t],
  );

  const existingOptions: IChoiceGroupOption[] = React.useMemo(
    () => [
      {
        key: Toggle.NO,
        styles: InlineChoiceItemStyle,
        text: t('option.client.new'),
      },
      {
        key: Toggle.YES,
        styles: InlineChoiceItemStyle,
        text: t('option.client.existing'),
      },
    ],
    [t],
  );

  const PatientSchema = React.useMemo(() => {
    return yup.object<Patient>().shape({
      firstName: yup.string().nullable().required(t('constraints:isNotEmpty')),
      lastName: yup.string().nullable().required(t('constraints:isNotEmpty')),
      email: yup
        .string()
        .nullable()
        //.required(t("constraints:isNotEmpty"))
        .trim()
        .email(t('constraints:isEmail')),
      phone: yup
        .string()
        .trim()
        .required(t('constraints:isNotEmpty'))
        .nullable()
        .matches(PhoneRegexp, t('constraints:isPhone')),
      gender: yup
        .mixed()
        .required(t('constraints:isNotEmpty'))
        .oneOf([Gender.MALE, Gender.FEMALE, Gender.X], t('constraints:isNotEmpty')),
      birthday: yup.date(),
      existingClient: yup
        .mixed()
        .required(t('constraints:isNotEmpty'))
        .oneOf([Toggle.YES, Toggle.NO], t('constraints:isNotEmpty')),
    });
  }, [t]);

  const {
    handleSubmit,
    formState,
    formState: {errors},
    control,
    watch,
  } = useForm<PatientData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...patient,
      phone: patient?.phone ? formatPhone(patient?.phone) : undefined,
      birthday: patient?.birthday ? DateTime.fromSeconds(patient.birthday,{zone:settings.TIME_ZONE}).toJSDate() : undefined,
    },
    resolver: yupResolver(PatientSchema),
  });

  const birthday: Date = watch('birthday');

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = new Date(birthday || '');
      let year = previousValue.getFullYear();
      let month = previousValue.getMonth();
      let day = previousValue.getDate();

      const newValueParts = (newValue || '').trim().split(/[^0-9]/);

      if (newValueParts.length > 0) {
        year = parseInt(newValueParts[0], 10);
        if (year < 100) year += 1900;
      }

      if (newValueParts.length > 1) {
        month = Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1;
      }

      if (newValueParts.length > 2) {
        day = Math.max(1, Math.min(31, parseInt(newValueParts[2], 10)));
      }

      return new Date(year, month, day);
    },
    [birthday],
  );

  const dayPickerStrings = React.useMemo(() => {
    return {
      months: [
        t('calendar:month.January'),
        t('calendar:month.February'),
        t('calendar:month.March'),
        t('calendar:month.April'),
        t('calendar:month.May'),
        t('calendar:month.June'),
        t('calendar:month.July'),
        t('calendar:month.August'),
        t('calendar:month.September'),
        t('calendar:month.October'),
        t('calendar:month.November'),
        t('calendar:month.December'),
      ],
      shortMonths: [
        t('calendar:shortMonth.January'),
        t('calendar:shortMonth.February'),
        t('calendar:shortMonth.March'),
        t('calendar:shortMonth.April'),
        t('calendar:shortMonth.May'),
        t('calendar:shortMonth.June'),
        t('calendar:shortMonth.July'),
        t('calendar:shortMonth.August'),
        t('calendar:shortMonth.September'),
        t('calendar:shortMonth.October'),
        t('calendar:shortMonth.November'),
        t('calendar:shortMonth.December'),
      ],
      days: [
        t('calendar:day.Sunday'),
        t('calendar:day.Monday'),
        t('calendar:day.Tuesday'),
        t('calendar:day.Wednesday'),
        t('calendar:day.Thursday'),
        t('calendar:day.Friday'),
        t('calendar:day.Saturday'),
      ],
      shortDays: [
        t('calendar:shortDay.Sunday'),
        t('calendar:shortDay.Monday'),
        t('calendar:shortDay.Tuesday'),
        t('calendar:shortDay.Wednesday'),
        t('calendar:shortDay.Thursday'),
        t('calendar:shortDay.Friday'),
        t('calendar:shortDay.Saturday'),
      ],
      goToToday: t('calendar:goToToday'),
      weekNumberFormatString: t('calendar:weekNumberFormatString'),
      prevMonthAriaLabel: t('calendar:prevMonthAriaLabel'),
      nextMonthAriaLabel: t('calendar:nextMonthAriaLabel'),
      prevYearAriaLabel: t('calendar:prevYearAriaLabel'),
      nextYearAriaLabel: t('calendar:nextYearAriaLabel'),
      prevYearRangeAriaLabel: t('calendar:prevYearRangeAriaLabel'),
      nextYearRangeAriaLabel: t('calendar:nextYearRangeAriaLabel'),
      closeButtonAriaLabel: 'calendar:closeButtonAriaLabel',
    };
  }, [t]);

  const onSubmit = React.useCallback(
    async (value: PatientData): Promise<void> => {
      return new Promise(async (resolve) => {
        try {
          const result = await mutationSavePatient({
            variables: {
              id: patient ? Number(patient.id) : undefined,
              version: patient?.version || 1,
              params: {
                firstName: value.firstName?.trim() || '',
                lastName: value.lastName?.trim() || '',
                phone: value.phone?.trim().replace(/[^0-9]/g, '') || '',
                email: value.email?.trim() || '',
                existingClient: value.existingClient,
                gender: value.gender,
                birthday: value.birthday
                  ? DateTime.fromJSDate(value.birthday,{zone:settings.TIME_ZONE}).startOf('day').toSeconds()
                  : undefined,
              },
            },
          });

          const newPatient = result.data?.savePatient;

          if (newPatient) {
            dispatch({
              type: DashboardActionType.PopUp,
              message: {type: MessageBarType.success, text: t('message.successfulySaved')},
            });
            const newPatientCopy: Patient = {} as Patient;
            Object.assign(newPatientCopy, patient);
            Object.assign(newPatientCopy, newPatient);
            setPatient(newPatientCopy);
            onUpdate && onUpdate(newPatientCopy);
            dispatch({type: DashboardActionType.UpdatePatient, patient: newPatientCopy});
          }

          resolve();
        } catch (e) {
          if (e instanceof ApolloError) {
            const {globalErrors, fieldErrors} = convertErrors(e, t);

            if (globalErrors)
              dispatch({
                type: DashboardActionType.PopUp,
                message: {
                  type: MessageBarType.error,
                  text: globalErrors.map((v) => v.message).join(' '),
                },
              });

            fieldErrors?.forEach((e, idx) => {
              //setError(e.path as FormFieldNames, {types:{[id]: e.message}});
            });
            //console.log(e);
          }

          resolve();
        }
        return;
      });
    },
    [dispatch, mutationSavePatient, onUpdate, patient, t],
  );

  const {isSubmitting} = formState;

  // @ts-ignore
  return (
    <div className={styles.gridContainer}>
      <div className={styles.firstName}>
        <Controller
          name='firstName'
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <TextField
              onBlur={onBlur}
              onChange={(_, newValue) => onChange(newValue)}
              placeholder={t('field.firstName')}
              underlined={true}
              borderless={true}
              value={value || ''}
              disabled={isSubmitting}
              errorMessage={convertFieldErrorToString(errors.firstName)}
            />
          )}
        />
      </div>
      <div className={styles.lastName}>
        <Controller
          name='lastName'
          control={control}
          render={({field: {onBlur, onChange, value}}) => (
            <TextField
              onBlur={onBlur}
              onChange={(_, newValue) => onChange(newValue)}
              placeholder={t('field.lastName')}
              underlined={true}
              borderless={true}
              value={value || ''}
              disabled={isSubmitting}
              errorMessage={convertFieldErrorToString(errors.lastName)}
            />
          )}
        />
      </div>
      <div className={styles.existingClient}>
        <Controller
          control={control}
          name='existingClient'
          render={({field: {value, onChange, onBlur}}) => (
            <ChoiceGroup
              label={t('field.existing')}
              styles={{
                flexContainer: {display: 'flex'},
                label: {display: 'inline-block', margin: '0 0 0 10px'},
              }}
              onBlur={onBlur}
              onChange={(_, option) => onChange(option?.key)}
              selectedKey={value as string}
              options={existingOptions}
              disabled={isSubmitting}
            />
          )}
        />
        {errors.existingClient && (
          <FieldError>{convertFieldErrorToString(errors.existingClient)}</FieldError>
        )}
      </div>
      <div className={styles.gender}>
        <Controller
          name='gender'
          control={control}
          render={({field: {value, onChange, onBlur}}) => (
            <ChoiceGroup
              label={t('field.gender')}
              styles={{
                flexContainer: {display: 'flex'},
                label: {display: 'inline-block', margin: '0 0 0 10px'},
              }}
              onChange={(_, option) => onChange(option?.key)}
              onBlur={onBlur}
              options={genderOptions}
              selectedKey={value as Gender}
              disabled={isSubmitting}
            />
          )}
        />
        {errors.gender && <FieldError>{convertFieldErrorToString(errors.gender)}</FieldError>}
      </div>
      <div className={styles.phone}>
        <Controller
          name='phone'
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <TextField
              onChange={(_, newValue) => onChange(newValue)}
              onBlur={onBlur}
              placeholder={'514-000-0000'}
              label={t('field.phone')}
              underlined={true}
              borderless={true}
              value={value || ''}
              disabled={isSubmitting}
              errorMessage={convertFieldErrorToString(errors.phone)}
            />
          )}
        />
      </div>
      <div className={styles.email}>
        <Controller
          control={control}
          name='email'
          render={({field: {onChange, onBlur, value}}) => (
            <TextField
              onChange={(_, newValue) => onChange(newValue)}
              onBlur={onBlur}
              label={t('field.email')}
              underlined={true}
              borderless={true}
              value={value || ''}
              disabled={isSubmitting}
              errorMessage={convertFieldErrorToString(errors.email)}
            />
          )}
        />
      </div>
      <div className={styles.birthday}>
        <Controller
          name='birthday'
          control={control}
          render={({field: {onChange, onBlur, value}}) => (
            <DatePicker
              underlined
              allowTextInput
              value={value}
              openOnClick={false}
              onBlur={onBlur}
              disabled={isSubmitting}
              strings={dayPickerStrings}
              onSelectDate={(date?: Date | null) => onChange(date || undefined)}
              formatDate={(date?: Date): string => {
                if (!date) return '';
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
                  day < 10 ? `0${day}` : day
                }`;
              }}
              parseDateFromString={onParseDateFromString}
            />
          )}
        />
        {// @ts-ignore
          errors.birthday && <FieldError>{convertFieldErrorToString(errors.birthday)}</FieldError>}
      </div>
      <div className={styles.buttons}>
        {onDismiss && (
          <DefaultButton
            disabled={isSubmitting}
            onClick={onDismiss}
            styles={{root: {marginRight: PADDING}}}>
            {t('button.close')}
          </DefaultButton>
        )}
        <DefaultButton
          disabled={isSubmitting || props.readOnly}
          primary={!patient}
          onClick={handleSubmit(onSubmit)}>
          {t('button.save')}
        </DefaultButton>
      </div>
    </div>
  );
};
