import { useMutation } from '@apollo/react-hooks';
import {
  FocusTrapZone,
  IconButton,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
  Toggle as ToggleBox,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { MutationsaveNoteArgs, Note, Toggle } from '../generated/graphql';
import saveNote from '../graphql/mutations/saveNote';
import { DashboardActionType, useDashboardContext } from '../pages/Dashboard/Context';
import { theme } from '../styles/theme';
import { convertErrors, convertFieldErrorToString } from '../utils/convertToFormErrors';
import {settings} from '../const/config';

interface NoteEditProp {
  note?: Note;
  onUpdate: (note: Note) => void;
  onDismiss: () => void;
}

type NoteData=Pick<Note, 'text' | 'archived'>

const NoteEdit: React.FunctionComponent<NoteEditProp> = (props) => {
  const {note, onDismiss, onUpdate} = props;
  const {t} = useTranslation();

  const FormSchema = React.useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required(t('constraints:isNotEmpty')),
        archived: yup.string().required(t('constraints:isNotEmpty')),
      }),
    [t],
  );

  const {dispatch} = useDashboardContext();
  const [mutationSaveNote] = useMutation<{saveNote: Note}, MutationsaveNoteArgs>(saveNote);

  const onSubmit = React.useCallback(
    async (value: NoteData): Promise<void> =>
      new Promise(async (resolve) => {
        try {
          const result = await mutationSaveNote({
            variables: {
              id: note?.id || undefined,
              params: {
                text: value.text,
                dateTime: note?.dateTime || DateTime.fromObject({zone:settings.TIME_ZONE}).toSeconds(),
                archived: value.archived,
              },
            },
          });
          const noteUpdated = result.data?.saveNote;

          if (noteUpdated) {
            dispatch({
              type: DashboardActionType.PopUp,
              message: {type: MessageBarType.success, text: t('message.successfulySaved')},
            });
            onUpdate && onUpdate(noteUpdated);
          }

          resolve();
        } catch (e) {
          const {globalErrors} = convertErrors(e, t);
          if (globalErrors)
            dispatch({
              type: DashboardActionType.PopUp,
              message: {
                type: MessageBarType.error,
                text: globalErrors.map((v) => v.message).join(' '),
              },
            });

          resolve();
        }
      }),

    [dispatch, mutationSaveNote, onUpdate, t, note],
  );

  const {
    handleSubmit,
    formState: {errors, isSubmitting, isDirty},
    control,
  } = useForm<NoteData>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...note,
    },
    resolver: yupResolver(FormSchema),
  });

  return (
    <FocusTrapZone disabled={false}>
      <Stack
        tokens={{childrenGap: theme.spacing.s1}}
        styles={{
          root: {backgroundColor: theme.palette.themeLighterAlt, paddingBottom: theme.spacing.m},
        }}>
        <StackItem>
          <Stack horizontal horizontalAlign='space-between'>
            <div>
              {note?.id && (
                <Controller
                  name='archived'
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <ToggleBox
                      inlineLabel
                      label={t('button.archived')}
                      checked={value === Toggle.YES}
                      onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                    />
                  )}
                />
              )}
            </div>
            <IconButton iconProps={{iconName: 'ChromeClose'}} onClick={onDismiss} />
          </Stack>
        </StackItem>
        <StackItem>
          <Controller
            name='text'
            control={control}
            render={({field: {onChange, onBlur, value}}) => (
              <TextField
                onBlur={onBlur}
                onChange={(_, newValue) => onChange(newValue)}
                multiline
                rows={5}
                value={value || ''}
                disabled={isSubmitting}
                errorMessage={convertFieldErrorToString(errors.text)}></TextField>
            )}
          />
        </StackItem>
        <StackItem>
          <Stack horizontal horizontalAlign='space-between'>
            <div></div>
            <PrimaryButton
              disabled={isSubmitting || !isDirty}
              onClick={handleSubmit(onSubmit)}>
              {t(note?.id ? 'button.save' : 'button.create')}
            </PrimaryButton>
          </Stack>
        </StackItem>
      </Stack>
    </FocusTrapZone>
  );
};

export default NoteEdit;
