import {
  DefaultButton,
  Label,
  Link,
  PrimaryButton,
  Stack,
  StackItem,
} from '@fluentui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import React, {useMemo, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import SignatureCanvas from 'react-signature-canvas';
import * as yup from 'yup';
import {SIGNATURE_MIN_STROKE} from '../../const/config';
import FieldError from '../../components/FieldError';
import {MediaConsentType} from '../../generated/graphql';
import {theme} from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import {convertFieldErrorToString} from '../../utils/convertToFormErrors';
import {spotProduct} from './Spots';
import {StageComponent} from './Stages';

type ConsentForm = {
  signatureSVG: string;
};

interface ConsentProps {
  readOnly: boolean;
}

export const StageConsent: StageComponent<ConsentProps> = (props) => {
  const {t} = useTranslation();
  const ref = useRef<SignatureCanvas>(null);

  const {readOnly, session, onNext, step, onPrev} = props;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ConsentSchema = React.useMemo(() => {
    return yup.object<ConsentForm>().shape({
      signatureSVG: readOnly ? yup.string() : yup.string()
            .test('signatureSVG', t('constraints:isNotEmpty', {context: 'signature'}), (val) => {
              if (ref.current) {
                const points = ref.current.toData().map((v) => v.length);
                if (points.length > 0) {
                  const longest = points.reduce((p, v) => (p > v ? p : v));
                  return longest > SIGNATURE_MIN_STROKE;
                }
              }
              return false;
            }),


    });
  }, [readOnly]);

  const {
    formState,
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<ConsentForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
    },
    resolver: yupResolver(ConsentSchema),
    criteriaMode: 'all',
  });
  const {isSubmitting} = formState;

  const onSubmit = React.useCallback(
    (values) => {
      values.signatureSVG = ref.current?.toDataURL() || '';
      onNext && onNext(step, {...values});
    },
    [onNext, step],
  );

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton
        disabled={isSubmitting}
        onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t('button.next')}
      </PrimaryButton>
    ),
    [isSubmitting, t, handleSubmit, onSubmit],
  );

  const prevButton = React.useMemo(() => {
    return onPrev ? (
      <DefaultButton disabled={isSubmitting} onClick={async (): Promise<void> => onPrev()}>
        {t('button.previous')}
      </DefaultButton>
    ) : undefined;
  }, [isSubmitting, onPrev, t]);

  const {spots} = session;

  const productTypes = useMemo(() => {
    const mappedProduct = spots?.map((v) => spotProduct[v]);
    if (!mappedProduct || mappedProduct.length === 0) return [];
    return mappedProduct.reduce((acc, current) => {
      const curAcc = acc ? [...acc] : [];
      current &&
        current.forEach((v) => {
          if (!curAcc.includes(v)) curAcc.push(v);
        });
      return curAcc;
    });
  }, [spots]);

  return (
    <Page title={t('page.session.title')}>
      <MultipageForm
        nextButton={nextButton}
        prevButton={prevButton}
        title={t(`page.session.${step}.title`)}>
        <form>
          <Stack tokens={{childrenGap: theme.spacing.m}}>
            <StackItem className='justified'>
              <ReactMarkdown children={t(`page.session.${step}.text.common`)} />
              {productTypes.map((productType) => (
                <>
                  <ReactMarkdown children={t(`page.session.${step}.text.${productType}`)} />
                  <hr />
                </>
              ))}
            </StackItem>
            <StackItem>
              {t(`page.session.${step}.certify${readOnly ? 'Read' : 'Sign'}`)}
            </StackItem>
            {!readOnly && (
              <StackItem>
                <Stack>
                  <StackItem>
                    <Label>{t('field.signature')}</Label>
                  </StackItem>
                  <StackItem>
                    <Controller
                      control={control}
                      name='signatureSVG'
                      render={() => (
                        <SignatureCanvas
                          ref={ref}
                          clearOnResize={false}
                          canvasProps={{className: 'signature'}}
                        />
                      )}
                    />
                  </StackItem>
                  <StackItem>
                    {errors.signatureSVG && (
                      <FieldError>{convertFieldErrorToString(errors.signatureSVG)}</FieldError>
                    )}
                    <Link
                      onClick={(): void => {
                        ref.current && ref.current.clear();
                      }}>
                      {t('button.reset')}
                    </Link>
                  </StackItem>
                </Stack>
              </StackItem>
            )}
          </Stack>
        </form>
      </MultipageForm>
    </Page>
  );
};

export const StageConsentRead: StageComponent = (props) => {
  return <StageConsent readOnly={true} {...props}></StageConsent>;
};

export const StageConsentSign: StageComponent = (props) => {
  return <StageConsent readOnly={false} {...props}></StageConsent>;
};

export default StageConsent;
