import { DateTime } from 'luxon';
import React from 'react';

import { Patient, Session, TimeSlot } from '../../generated/graphql';
import { PopUpMessageType } from './PopUpMessage';
import { DashboardReducer, getRange } from './Reducer';
import { DateTimeRange, PeriodType } from './types';
import {settings} from '../../const/config';

export interface DashboardState {
  selectedDate: DateTime;
  periodType: PeriodType;
  range: DateTimeRange;
  timeSlots?: TimeSlot[];
  searchTextDB: string;
  timeSlot?: TimeSlot;
  patient?: Patient;
  message?: PopUpMessageType;
}

export enum DashboardActionType {
  UpdateSession,
  UpdatePatient,
  UpdateTimeSlot,
  LoadTimeSlots,
  Filter,
  TimeSlot,
  Patient,
  PopUp,
}

export interface UpdatePatientAction {
  type: DashboardActionType.UpdatePatient;
  patient: Patient;
}

export interface UpdateSessionAction {
  type: DashboardActionType.UpdateSession;
  session: Session;
}

export interface UpdateTimeSlotAction {
  type: DashboardActionType.UpdateTimeSlot;
  timeSlot: TimeSlot;
  action: 'create' | 'remove';
}

export interface LoadTimeSlotsAction {
  type: DashboardActionType.LoadTimeSlots;
  timeSlots?: TimeSlot[];
}

export interface FilterAction {
  type: DashboardActionType.Filter;
  selectedDate?: DateTime;
  periodType?: PeriodType;
  searchTextDB?: string;
}

export interface TimeSlotAction {
  type: DashboardActionType.TimeSlot;
  timeSlot?: TimeSlot;
}

export interface PatientAction {
  type: DashboardActionType.Patient;
  patient?: Patient;
}

export interface PopUpAction {
  type: DashboardActionType.PopUp;
  message?: PopUpMessageType;
}

export type DashboardAction =
  | UpdatePatientAction
  | UpdateSessionAction
  | UpdateTimeSlotAction
  | LoadTimeSlotsAction
  | FilterAction
  | TimeSlotAction
  | PatientAction
  | PopUpAction;

interface DashboardContextValue {
  state: DashboardState;
  dispatch: React.Dispatch<DashboardAction>;
}

export const DashboardContext = React.createContext<DashboardContextValue>(
  {} as DashboardContextValue,
);

const initialState = (): DashboardState => {
  const selectedDate = DateTime.fromObject({zone:settings.TIME_ZONE});
  const periodType = PeriodType.WEEK
  return {periodType, selectedDate, range: getRange(selectedDate, periodType), searchTextDB: ''};
};

export const DashboardProvider: React.FunctionComponent = (props) => {
  const [state, dispatch] = React.useReducer(DashboardReducer, initialState());
  return (
    <DashboardContext.Provider value={{state, dispatch}}>
      {props.children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = (): DashboardContextValue => React.useContext(DashboardContext);

export const DashboardConsumer = DashboardContext.Consumer;
