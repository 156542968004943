import gql from 'graphql-tag';

const query = gql`
  query getAvailableTimeslots($params: AvailableSlotInput!) {
    getAvailableTimeslots(params: $params) {
      id
      date
      length
    }
  }
`;
export default query;
