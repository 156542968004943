// noinspection ES6UnusedImports

import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  IconButton,
  IDropdownOption,
  ITextField,
  Label,
  MessageBarType,
  Modal,
  Pivot,
  PivotItem,
  Separator,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import FaceImg from '../../assets/face_1080x1080.jpg';
import MarkerImg from '../../assets/spot.svg';
import MarkerSelectedImg from '../../assets/spot_selected.svg';
import { HotSpotImage } from '../../components/HotSpotImage/HotSpotImage';
import { settings, URL_SESSION } from '../../const/config';
import {
  MutationchangeTimeArgs,
  MutationcreateAppointmentArgs,
  MutationsaveTreatmentArgs,
  MutationsaveTreatmentNoteArgs,
  PhotoType,
  Product,
  QuerygetProductsArgs,
  Session,
  SessionStatus,
  SessionType,
  TimeSlot,
  TimeSlotAvailability,
  TreatmentFormType,
  TreatmentInjection,
  TreatmentInjectionInput,
  TreatmentInput,
} from '../../generated/graphql';
import { changeTime, createAppointment, saveTreatment, saveTreatmentNote } from '../../graphql/mutations';
import getProducts from '../../graphql/queries/getProducts';
import SessionTimeChange from '../../organisms/SessionTimeChange';
import TreatmentNote from '../../organisms/TreatmentNote';
import { theme } from '../../styles/theme';
import { convertErrors, convertToFormErrors } from '../../utils/convertToFormErrors';
import { compareProduct } from '../../utils/sortProduct';
import { availableSpots, spotProduct } from '../Session/Spots';
import { DashboardActionType, useDashboardContext } from './Context';
import { PatientQuestionary } from './PatientQuestionary';
import { styles } from './styles';
import TreatmentForm from './TreatmentForm';

/* eslint-disable quotes */
interface TreatmentDetailsProps {
  treatment: Session;
  visible?: boolean;
  readOnly?: boolean;
  onNewFollowUp: (session: Session) => void;
}


type ClientPhoto = Exclude<PhotoType, PhotoType.TREATMENT | PhotoType.FOLLOWUP | PhotoType.SESSION>;

export const TreatmentDetails: React.FunctionComponent<TreatmentDetailsProps> = (props) => {
  const { t } = useTranslation();
  const [photo, setPhoto] = React.useState<string>();
  const [treatment, setTreatment] = React.useState(props.treatment);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [parentTreatment, setParentTreatment] = React.useState<Session>();
  const [isDetailsVisible, setIsDetailsVisible] = React.useState(props.visible);

  const { dispatch } = useDashboardContext();

  const { onNewFollowUp, readOnly } = props;

  const [mutationSaveTreatment] = useMutation<{ saveTreatment: Session }, MutationsaveTreatmentArgs>(
    saveTreatment,
  );

  const [mutationSaveTreatmentNote] = useMutation<
    { saveTreatmentNote: TreatmentInjection },
    MutationsaveTreatmentNoteArgs
  >(saveTreatmentNote);

  const [mutationChangeTime] = useMutation<{ changeTime: TimeSlot }, MutationchangeTimeArgs>(
    changeTime,
  );

  const [mutationCreateAppointment] = useMutation<
    { createAppointment: Session },
    MutationcreateAppointmentArgs
  >(createAppointment);

  const [products, setProducts] = React.useState<IDropdownOption[]>();

  useQuery<{ getProducts: Product[] }, QuerygetProductsArgs>(getProducts, {
    fetchPolicy: 'network-only',
    variables: {},
    onCompleted: (data) => {
      //TODO sort products and add sections
      setProducts([
        ...data.getProducts.sort(compareProduct).map((product) => ({
          key: product.id,
          data: product,
          text:
            product.name !== product.brand.name
              ? `${product.brand.name} ${product.name}`
              : product.name,
        })),
      ]);
    },
    onError: () => {
      setProducts(undefined);
    },
  });

  const onSaveTreatmentNote = React.useCallback(
    async (values: TreatmentInjectionInput): Promise<TreatmentInjection> => {
      return new Promise<TreatmentInjection>(async (resolve, reject) => {
        try {
          const ret = await mutationSaveTreatmentNote({
            variables: {
              id: Number(treatment.id) || 0,
              version: treatment.version,
              params: {
                ...values,
                schemaBase: '-',
              },
            },
          });

          dispatch({
            type: DashboardActionType.PopUp,
            message: {
              type: MessageBarType.success,
              text: t('message.successfulySaved'),
            },
          });

          if (ret.data?.saveTreatmentNote) {
            const newTreatment = { ...treatment, treatment: ret.data.saveTreatmentNote };
            setTreatment(newTreatment);
            dispatch({ type: DashboardActionType.UpdateSession, session: newTreatment });
            resolve(ret.data?.saveTreatmentNote);
          }
        } catch (e) {
          if (e instanceof ApolloError) {
            const { globalErrors } = convertErrors(e, t);

            if (globalErrors)
              dispatch({
                type: DashboardActionType.PopUp,
                message: {
                  type: MessageBarType.error,
                  text: globalErrors.map((v) => v.message).join(' '),
                },
              });
          }

          reject(e);
        }
      });
    },
    [dispatch, mutationSaveTreatmentNote, t, treatment],
  );

  const onSaveTreatment = React.useCallback(
    async (values: TreatmentInput): Promise<void> => {
      return new Promise(async (resolve, reject) => {
        try {
          const ret = await mutationSaveTreatment({
            variables: {
              id: Number(treatment.id) || 0,
              version: treatment.version,
              params: values,
            },
          });

          dispatch({
            type: DashboardActionType.PopUp,
            message: {
              type: MessageBarType.success,
              text: t('message.successfulySaved'),
            },
          });

          if (ret.data?.saveTreatment) {
            const newTreatment = { ...treatment, ...ret.data.saveTreatment };
            setTreatment(newTreatment);
            dispatch({ type: DashboardActionType.UpdateSession, session: newTreatment });
          }

          resolve();

          return;
        } catch (e) {
          if (e instanceof ApolloError) {
            const { globalErrors } = convertErrors(e, t);

            if (globalErrors)
              dispatch({
                type: DashboardActionType.PopUp,
                message: {
                  type: MessageBarType.error,
                  text: globalErrors.map((v) => v.message).join(' '),
                },
              });
          }
          reject(e);
        }
      });
    },
    [dispatch, mutationSaveTreatment, t, treatment],
  );

  const onChangeTimeSlot = React.useCallback(
    async (dateTime?: DateTime) => {
      try {
        const ret = await mutationChangeTime({
          variables: {
            sessionId: treatment.id,
            timeSlot: dateTime
              ? {
                date: dateTime.toSeconds(),
                userId: '1',
                length: 60,
                availability: TimeSlotAvailability.EXISTING,
              }
              : null,
          }, // TODO replace with real user id
        });
        const newTreatment = { ...treatment, timeSlot: ret.data?.changeTime };
        if (!dateTime) newTreatment.status = SessionStatus.CANCELED;
        setTreatment(newTreatment);
        dispatch({ type: DashboardActionType.UpdateSession, session: newTreatment });
        if (!dateTime) {
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          const { globalErrors } = convertErrors(e, t);

          if (globalErrors)
            dispatch({
              type: DashboardActionType.PopUp,
              message: {
                type: MessageBarType.error,
                text: globalErrors.map((v) => v.message).join(' '),
              },
            });
        }
      }
      setIsModalVisible(false);
      return new Promise<void>((r) => r());
    },
    [dispatch, mutationChangeTime, t, treatment],
  );

  const onFollowUp = React.useCallback(
    async (dateTime?: DateTime) => {
      try {
        if (parentTreatment) {
          const ret = await mutationCreateAppointment({
            variables: {
              params: {
                dateTime: dateTime?.toSeconds(),
                userId: '1',
                sessionId: parentTreatment.id,
              },
            },
          });

          const newTreatment = ret.data?.createAppointment;

          if (newTreatment) {
            dispatch({ type: DashboardActionType.UpdateSession, session: newTreatment });
            onNewFollowUp(newTreatment);
          }
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          const err = Object.values(convertToFormErrors(e, t)).flat().join(' ');
          if (err)
            dispatch({
              type: DashboardActionType.PopUp,
              message: {
                type: MessageBarType.error,
                text: err,
              },
            });
        }
      }
      setIsModalVisible(false);
      return new Promise<void>((r) => r());
    },
    [dispatch, mutationCreateAppointment, onNewFollowUp, parentTreatment, t],
  );

  const textRef = React.createRef<ITextField>();

  const timeTreatment = treatment.timeSlot
    ? DateTime.fromSeconds(treatment.timeSlot.date, { zone: settings.TIME_ZONE })
    : undefined;

  const url = window.location.href.split('/');
  const requestLink =
    url[0] +
    '//' +
    url[2] +
    generatePath(URL_SESSION, {
      sessionCode: treatment.sessionCode,
    });

  const productTypes = React.useMemo(() => {
    const mappedProduct = treatment.spots?.map((v) => spotProduct[v]);
    if (!mappedProduct || mappedProduct.length === 0) return [];
    return mappedProduct.reduce((acc, current) => {
      const curAcc = acc ? [...acc] : [];
      current &&
      current.forEach((v) => {
        if (!curAcc.includes(v)) curAcc.push(v);
      });
      return curAcc;
    });
  }, [treatment.spots]);

  const photos = React.useMemo(() => {
    const ret: Record<ClientPhoto, string | undefined> = {
      NEUTRAL: undefined,
      LEFT: undefined,
      RIGHT: undefined,
      FROWN: undefined,
      SURPRISE: undefined,
    };
    treatment.photos?.forEach((photo) => (ret[photo.type] = photo.fileCDN));

    if (!treatment.photos || treatment.photos.length === 0) return null;

    return (
      <div className={styles.photo}>
        <div className={styles.sectionHeader}>{t('tab.photos')}</div>
        {Object.keys(PhotoType).map((type) => (
          <div key={type}>
            {ret[type] ? (
              <img
                onClick={(): void => setPhoto(ret[type])}
                className={[styles.img, styles.thumb].join(' ')}
                src={`${settings.URL_UPLOAD_FILES}${ret[type]}`}
                alt={t(`page.session.photo.sample.${type}`)}
              />
            ) : null}
          </div>
        ))}
      </div>
    );
  }, [t, treatment.photos]);

  if (treatment.status === SessionStatus.CANCELED) {
    return (
      <div className={styles.treatmentHeader}>
        <span>{t('text.cancelledSession')}</span>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Modal isOpen={!!photo} onDismiss={(): void => setPhoto(undefined)}>
        {photo && (
          <a target="_blank" rel="noreferrer" href={`${settings.URL_UPLOAD_FILES}${photo}`}>
            <img src={`${settings.URL_UPLOAD_FILES}${photo}`} className={styles.img} alt=""/>
          </a>
        )}
      </Modal>
      <Modal
        isOpen={isModalVisible}
        isBlocking={true}
        onDismiss={(): void => setIsModalVisible(false)}>
        <SessionTimeChange
          hideCancel={!!parentTreatment}
          value={timeTreatment || DateTime.fromObject({ zone: settings.TIME_ZONE })}
          onChange={parentTreatment ? onFollowUp : onChangeTimeSlot}
          onCancel={(): void => setIsModalVisible(false)}
        />
      </Modal>
      <div
        className={styles.treatmentHeader}
        onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
        <span className={styles.valign}>
          <span>
            {timeTreatment ? timeTreatment.toFormat('yyyy-MM-dd HH:mm') : t('text.unknownDate')}
          </span>
          {[SessionStatus.PENDING, SessionStatus.REQUEST, SessionStatus.COMPLETED].includes(
            treatment.status,
          ) && (
            <span className={styles.icon}>
              {!readOnly && (
                <IconButton
                  iconProps={{ iconName: 'DateTime' }}
                  onClick={(e): void => {
                    e.stopPropagation();
                    setIsModalVisible(true);
                    setParentTreatment(undefined);
                  }}
                />
              )}
            </span>
          )}
        </span>
        <span>{t(`option.sessionType.${treatment.type}`)}</span>
        <span>
          {t(`option.sessionStatus.${treatment.status}`)}
          {treatment.status === SessionStatus.COMPLETED &&
            treatment.type === SessionType.PRIMARY &&
            !readOnly && (
              <IconButton
                iconProps={{ iconName: 'History' }}
                label={t('button.createFollowUp')}
                onClick={(e): void => {
                  e.stopPropagation();
                  setIsModalVisible(true);
                  setParentTreatment(treatment);
                }}
              />
            )}{' '}
        </span>
        <span>
          <IconButton
            iconProps={{
              iconName: isDetailsVisible ? 'CollapseContentSingle' : 'ExploreContentSingle',
            }}
            style={{ marginLeft: theme.spacing.l1 }}
            onClick={() => setIsDetailsVisible(!isDetailsVisible)}
          />
        </span>
      </div>
      {isDetailsVisible && (
        <Pivot defaultSelectedKey="treatment">
          <PivotItem headerText={t('tab.information')} itemKey={'info'}>
            <div>
              {treatment.status === SessionStatus.REQUEST && (
                <div className={styles.procedure}>
                  <Label>{t('field.sessionCode')}</Label>
                  <Stack horizontal>
                    <StackItem grow>
                      <TextField
                        borderless
                        underlined={true}
                        value={requestLink}
                        componentRef={textRef}
                        onChange={(): void => {
                          /* no changes allowed */
                        }}
                      />
                    </StackItem>
                    <StackItem>
                      <IconButton
                        iconProps={{ iconName: 'Copy' }}
                        onClick={(): void => {
                          textRef.current?.select();
                          document.execCommand('copy');
                          textRef.current?.setSelectionRange(0, 0);
                        }}
                      />
                    </StackItem>
                  </Stack>
                </div>
              )}
              {photos}
              {treatment.spots && treatment.spots.length !== 0 && (
                <div className={styles.procedure}>
                  <div className={styles.sectionHeader}>{t('tab.procedure')}</div>
                  <div className="hotSpot">
                    <HotSpotImage
                      hideUnselected={true}
                      mainSrc={FaceImg}
                      markerSrc={MarkerImg}
                      mappedWidth={1080}
                      markerRate={0.05}
                      markerSelectedSrc={MarkerSelectedImg}
                      spots={availableSpots}
                      selected={treatment.spots}
                    />
                  </div>
                  <div>
                    <p>
                      <b>{t('field.comment')}</b>: {treatment.commentProcedure || t('text.none')}{' '}
                      <br/>
                    </p>

                    <b>{t('field.spots')}:</b>
                    <ul>
                      {treatment.spots.map((st) => (
                        <li key={st}>{t(`spots.${st}.name`)}</li>
                      ))}
                    </ul>

                    <p>
                      <b>{t('field.mediaConsent')}</b>:{' '}
                      {t(`option.mediaConsent.${treatment.mediaConsent}`)}
                    </p>
                  </div>
                </div>
              )}
              <PatientQuestionary treatment={treatment}/>
              {treatment.signatureSVG && (
                <div className={styles.consent}>
                  <div className={styles.sectionHeader}>{t('tab.consent')}</div>
                  <div className={styles.merge2}>
                    {productTypes.map((productType) => (
                      <p key={productType}>{t(`page.session.consent.form.${productType}`)}</p>
                    ))}
                    <p>{t('page.session.terms.agreed')}</p>
                  </div>
                  <div>
                    {treatment.signatureSVG && (
                      <img className={styles.signature} alt="" src={treatment.signatureSVG}/>
                    )}
                  </div>
                  <div>
                    {treatment.signatureTime &&
                      DateTime.fromSeconds(treatment.signatureTime, { zone: settings.TIME_ZONE }).toFormat('yyyy-MM-dd HH:MM')}
                  </div>
                </div>
              )}
              <Stack>
                <StackItem>
                  <Separator>{t('tab.consent')}</Separator>
                </StackItem>
                <StackItem>
                  <table style={{ width: '100%' }}>
                    <thead>
                    <tr>
                      <td>{t('label.consent')}</td>
                      <td>{t('label.time')}</td>
                      <td>{t('label.signature')}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {treatment.consents?.map((consent) => (
                      <tr key={consent.id}>
                        <td>
                          <Label>{t(`product.${consent.productType}`)}</Label>
                        </td>
                        <td>{DateTime.fromSeconds(consent.signatureTime, { zone: settings.TIME_ZONE }).toLocaleString()}</td>
                        <td>
                          {consent.signatureSVG && (
                            <img
                              src={consent.signatureSVG}
                              alt={t('label.signature')}
                              height={50}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </StackItem>
              </Stack>

              <TreatmentForm
                treatment={treatment}
                formType={TreatmentFormType.SESSION}
                products={[]}
                onSave={onSaveTreatment}
              />
            </div>
          </PivotItem>

          <PivotItem headerText={t('tab.treatment')} itemKey={'treatment'}>
            <TreatmentForm
              treatment={treatment}
              readOnly={props.readOnly}
              formType={TreatmentFormType.TREATMENT}
              products={products || []}
              onSave={onSaveTreatment}
            />
            <hr/>
            <TreatmentNote
              readOnly={props.readOnly}
              products={products || []}
              onSave={onSaveTreatmentNote}
              treatment={treatment.treatment || undefined}
            />
          </PivotItem>

          <PivotItem headerText={t('tab.followup')} itemKey={'followup'}>
            <TreatmentForm
              treatment={treatment}
              formType={TreatmentFormType.FOLLOWUP}
              products={[]}
              onSave={onSaveTreatment}
            />
          </PivotItem>
        </Pivot>
      )}
    </div>
  );
};

export default TreatmentDetails;
