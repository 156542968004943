import { useMutation, useQuery } from '@apollo/react-hooks';
import { DefaultButton, Label, MessageBarType, Separator, Stack, StackItem, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Questions from '../../components/Questions';
import { QuestionaryForm, ToggleKeys } from '../../const/questionary';
import { MutationsaveQuestionaryArgs, Question, Session, Toggle } from '../../generated/graphql';
import { saveQuestionary } from '../../graphql/mutations';
import { getQuestions } from '../../graphql/queries';
import { convertErrors, convertFieldErrorToString } from '../../utils/convertToFormErrors';
import { DashboardActionType, useDashboardContext } from './Context';
import { styles } from './styles';

interface PatientQuestionaryProps {
  treatment: Session;
}

const NotNumber = /([^0-9]+)/g;

type FormFieldNames = Extract<keyof QuestionaryForm, string>;

const QuestionarySchema =
  yup.object<QuestionaryForm>().shape({
    answers: yup
      .array()
      .required()
      .of(
        yup.object().shape({
          questionCode: yup
            .string()
            .required()
            .matches(/[A-z0-9]+/),
          answer: yup.string().oneOf(ToggleKeys),
        }),
      ),
    commentHealth: yup.string().nullable(),
  });

export const PatientQuestionary: React.FunctionComponent<PatientQuestionaryProps> = ({ treatment }) => {

  const { dispatch } = useDashboardContext();
  const [questions, setQuestions] = React.useState<Question[]>();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isExistingClient = treatment.patient?.existingClient === Toggle.YES;
  const alreadyFilled = treatment.answers.length !== 0;
  const [runMutation, result] = useMutation<boolean, MutationsaveQuestionaryArgs>(
    saveQuestionary,
  );

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    setError
  } = useForm<QuestionaryForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      answers: treatment.answers?.length ? treatment.answers : undefined,
      commentHealth: treatment.commentHealth || '',
    },
    resolver: yupResolver(QuestionarySchema),
    criteriaMode: 'all',
  });


  const onSubmit = React.useCallback(async (data: QuestionaryForm) => {
    try {
      await runMutation({
        variables: {
          id: Number(treatment.id).toString(),
          params: {
            answers: data.answers || [],
            commentHealth: data.commentHealth
          },
        },
      });
      dispatch({
        type: DashboardActionType.PopUp,
        message: { type: MessageBarType.success, text: t('message.successfulySaved') },
      });
    } catch (e) {
      if (e instanceof ApolloError) {
        const { globalErrors, fieldErrors } = convertErrors(e, t);

        if (globalErrors)
          dispatch({
            type: DashboardActionType.PopUp,
            message: {
              type: MessageBarType.error,
              text: globalErrors.map((v) => v.message).join(' '),
            },
          });


        fieldErrors?.forEach((e, idx) => {
          setError(e.path as FormFieldNames, { types: { 'required': e.message } });
        });

      }
    }

  }, [dispatch, runMutation, t, treatment.id]);


  useQuery<{ getQuestions: Question[] }>(getQuestions, {
    variables: { language, isExistingClient },
    fetchPolicy: 'network-only',
    skip: alreadyFilled,
    onCompleted: (data) => {
      if (data?.getQuestions) {
        setQuestions(
          data.getQuestions.sort((q1, q2) => {
            return parseInt(q1.code.replace(NotNumber, '')) >
            parseInt(q2.code.replace(NotNumber, ''))
              ? 1
              : -1;
          }),
        );
      }
    },
    onError: (error) => {
      dispatch({
        type: DashboardActionType.PopUp,
        message: {
          type: MessageBarType.error,
          text: t('error.getQuestions'),
        },
      });
    },
  });


  if (alreadyFilled) {
    return <PatientQuestionaryFilled treatment={treatment}/>;
  }

  return <Stack className={styles.bgColor}>
    <StackItem>
      <Separator>{t('tab.medical')}</Separator>
    </StackItem>
    <StackItem>
      <Label>{t(`field.comment`)}</Label>
      <Controller
        name="commentHealth"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TextField
            onChange={(_, newValue) => onChange(newValue)}
            onBlur={onBlur}
            value={value || ''}
            multiline
            rows={3}
            errorMessage={convertFieldErrorToString(errors.commentHealth)}
          />
        )}
      />
    </StackItem>
    <StackItem>
      <table className="questionary">
        <thead>
        <tr>
          <th>{t('field.question')}</th>
          <th>{t('option.yes')}</th>
          <th>{t('option.no')}</th>
        </tr>
        </thead>
        <Controller
          control={control}
          name="answers"
          render={({ field: { onChange } }) => (
            <Questions questions={questions} onChange={onChange}
                       error={errors?.answers ? t('constraints:mustAnswer') : ''}/>
          )}
        />
      </table>
    </StackItem>
    <StackItem>
      <div style={{ textAlign: 'right' }}>
        <DefaultButton
          primary={true}
          disabled={isSubmitting}
          onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
          {t('button.save')}
        </DefaultButton>
      </div>
    </StackItem>
  </Stack>;
};

const PatientQuestionaryFilled: React.FunctionComponent<PatientQuestionaryProps> = ({ treatment }) => {
  const { t } = useTranslation();

  return <Stack className={styles.bgColor}><StackItem>
    <Separator>{t('tab.medical')}</Separator>
  </StackItem>
    <StackItem>
      <b>{t('field.comment')}</b>: {treatment.commentHealth || t('text.none')}
    </StackItem>
    <StackItem>
      <table className="questionary">
        <thead>
        <tr>
          <th>{t('field.question')}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {treatment.answers.map((ans) => (
          <tr key={ans.id}>
            <td className={styles.questionSize}>{ans.questionText}</td>
            <td className={styles.questionSize}>
              {ans.answer === Toggle.YES ? <b>{t('option.yes')}</b> : t('option.no')}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </StackItem></Stack>;
};
