import { DefaultButton, IButtonProps, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import React from 'react';

import { PADDING } from '../pages/Dashboard/const';

export interface ModalTwoButtonsProps {
  cancelButtonProps: IButtonProps;
  saveButtonProps?: IButtonProps;
}

export const ModalTwoButtons: React.FunctionComponent<ModalTwoButtonsProps> = (props) => {
  const {cancelButtonProps, saveButtonProps} = props;
  return (
    <Stack tokens={{childrenGap: PADDING, padding: PADDING}} style={{minHeight: '200px', minWidth:'400px'}}>
      <StackItem grow>{props.children}</StackItem>
      <StackItem align='end'>
        <DefaultButton {...cancelButtonProps} />
        {saveButtonProps && <PrimaryButton styles={{root: {marginLeft: PADDING}}} {...saveButtonProps} />}
      </StackItem>
    </Stack>
  );
};
