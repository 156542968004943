import { Checkbox, Label, Stack, StackItem } from '@fluentui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TimePicker from '../components/TimePicker';
import { PADDING32 } from '../pages/Dashboard/const';
import { ModalTwoButtons } from '../templates/ModalTwoButtons';

export interface SessionTimeChangeProps {
  value: DateTime;
  onChange: (value?: DateTime) => void;
  onCancel: () => void;
  hideCancel?: boolean;
}

export const SessionTimeChange: React.FunctionComponent<SessionTimeChangeProps> = (props) => {
  const {t} = useTranslation();
  const {onCancel, onChange, value, hideCancel} = props;
  const [isCancel, setIsCancel] = React.useState(false);

  const [dateTime, setDateTime] = React.useState<DateTime>(value);

  const onSave = React.useCallback(() => {
    onChange(isCancel ? undefined : dateTime);
  }, [dateTime, isCancel, onChange]);

  return (
    <ModalTwoButtons
      cancelButtonProps={{text: t('button.cancel'), onClick: (): void => onCancel()}}
      saveButtonProps={{
        text: t('button.save'),
        disabled: dateTime === value && !isCancel,
        onClick: (): void => onSave(),
      }}>
      <Stack tokens={{childrenGap: PADDING32}}>
        <StackItem>
          <Label>{t('field.time')}</Label>
          <TimePicker stepMinutes={5} value={dateTime} onChange={(nd): void => setDateTime(nd)} />
        </StackItem>
        {!hideCancel && (
          <StackItem>
            <Checkbox
              label={t('field.cancelSession')}
              checked={isCancel}
              onChange={(_, checked): void => {
                setIsCancel(!!checked);
              }}
            />
          </StackItem>
        )}
      </Stack>
    </ModalTwoButtons>
  );
};

export default SessionTimeChange;
