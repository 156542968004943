import gql from 'graphql-tag';

const query = gql`
  mutation saveNote($id: ID, $version: Int, $params: NoteInput!) {
    saveNote(id: $id, version: $version, params: $params) {
      id
      text
      archived
    }
  }
`;
export default query;
