import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { PARAM_STEP, URL_REQUEST } from '../../const/config';
import { GlobalState } from '../../state/globalState';
import { updateSession } from '../../state/sessionState';
import StageMessage from './StageMessage';
import {
  getNextStage,
  getPreviousStage,
  NextHandler,
  SessionStage,
  StageComponents,
  Stages,
  StageType,
  StepMessage,
} from './Stages';

export const RequestRouter: React.FunctionComponent = (props) => {
  const history = useHistory();
  const {step} =
    useParams<{
      step: string;
    }>();

  const {
    action,
    state: {session},
  } = useStateMachine<GlobalState>(updateSession);

  const stepNorm = React.useMemo(() => {
    const idx = Stages.REQUEST.findIndex((value) => value === step);
    if (idx === -1) return Stages.REQUEST[0];
    const idxState = Stages.REQUEST.findIndex((value) => value === session.step);
    if (idxState === -1) return Stages.REQUEST[0];
    return Stages.REQUEST[idxState < idx ? idxState : idx];
  }, [step, session]);

  const onPrev = React.useCallback(() => {
    const prevStep = getPreviousStage(StageType.REQUEST, step as SessionStage);
    action({step: prevStep});
    history.push(URL_REQUEST.replace(PARAM_STEP, prevStep));
  }, [action, history, step]);

  const onNext: NextHandler = React.useCallback(
    (step, update, doReset) => {
      if (doReset) {
        action({session: undefined});
      } else {
        const updatedSession = {...session.session, ...update};
        const nextStep = getNextStage(StageType.REQUEST, step);
        action({step: nextStep, session: updatedSession});
        history.push(URL_REQUEST.replace(PARAM_STEP, nextStep));
      }
    },
    [action, history, session.session],
  );

  if (StepMessage.includes(step as SessionStage)) {
    return <StageMessage step={step as SessionStage} type={StageType.REQUEST} session={{}} />;
  }

  if (step !== stepNorm) return <Redirect to={URL_REQUEST.replace(PARAM_STEP, stepNorm)} />;

  const $Component = StageComponents.REQUEST[step];
  return $Component ? (
    // eslint-disable-next-line react/jsx-pascal-case
    <$Component
      step={step as SessionStage}
      onNext={onNext}
      onPrev={onPrev}
      isLastOne={Stages.REQUEST[Stages.REQUEST.length - 2] === step}
      type={StageType.REQUEST}
      session={session.session || {}}
    />
  ) : null;
};

export default RequestRouter;
