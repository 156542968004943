import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  FontSizes,
  IconButton,
  IPanelHeaderRenderer,
  IPanelProps,
  Link,
  mergeStyleSets,
  MessageBar,
  MessageBarButton,
  MessageBarType,
  Modal,
  Panel,
  PanelType,
  Spinner,
} from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {settings, URL_DASHBOARD} from '../../const/config';
import { MutationcreateAppointmentArgs, Patient, QuerygetPatientArgs, Session } from '../../generated/graphql';
import { createAppointment } from '../../graphql/mutations';
import { getPatient } from '../../graphql/queries';
import SessionTimeChange from '../../organisms/SessionTimeChange';
import { theme } from '../../styles/theme';
import { ErrorType } from '../../types/ErrorType';
import { convertToFormErrors } from '../../utils/convertToFormErrors';
import { PADDING32 } from './const';
import { DashboardActionType, useDashboardContext } from './Context';
import { PatientForm } from './PatientForm';
import { TreatmentDetails } from './TreatmentDetails';

interface PatientDetailsProps {
  patientId: string;
  readOnly?: boolean;
  onClose: () => void;
}

const styles = mergeStyleSets({
  container: {
    width: 'calc(100vw - 48px)',
    //    height: "calc(100vh - 128px)",
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    paddingLeft: '24px',
    paddingRight: '24px',
    alignSelf: 'flex-start',
    flexGrow: '1',
    fontSize: FontSizes.xLarge,
    fontWeight: 600,
    backgroundColor: theme.palette.themeLight,
  },
});

export const PatientDetails: React.FunctionComponent<PatientDetailsProps> = (props) => {
  const {t} = useTranslation();
  const {patientId, onClose, readOnly} = props;
  const history = useHistory();
  const [patient, setPatient] = React.useState<Patient>();
  const [error, setError] = React.useState<ErrorType>();
  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const [isCreateVisible, setIsCreateVisible] = React.useState(false);

  const {dispatch} = useDashboardContext();

  const mounted = React.useRef<boolean>(true);
  React.useEffect(() => {
    return (): void => {
      mounted.current = false;
    };
  }, []);

  const [mutationCreateAppointment] = useMutation<
    {createAppointment: Session},
    MutationcreateAppointmentArgs
  >(createAppointment);

  const onRenderHeader: IPanelHeaderRenderer = React.useCallback(
    (props?: IPanelProps, defaultRender?: IPanelHeaderRenderer) => (
      <div className={styles.header}>
        <Link
          onClick={(): void => {
            setIsEditVisible(!isEditVisible);
          }}>
          {props?.headerText}
        </Link>
        <IconButton
          iconProps={{iconName: 'Edit'}}
          onClick={(): void => {
            setIsEditVisible(!isEditVisible);
          }}
        />
        {!readOnly && (
          <IconButton
            iconProps={{iconName: 'AddEvent'}}
            onClick={(): void => {
              setIsCreateVisible(true);
            }}
            styles={{root: {marginLeft: PADDING32}}}
          />
        )}
      </div>
    ),
    [isEditVisible, readOnly],
  );

  const onSuccessLoad = React.useCallback(
    (loadedPatient: Patient) => {
      if (!loadedPatient) {
        history.replace(URL_DASHBOARD);
        return;
      }
      setError(undefined);
      setPatient(loadedPatient);
    },
    [history],
  );

  const onNewFollowUp = React.useCallback(
    (session: Session) => {
      if (patient?.sessions) {
        const sessCopy = patient?.sessions
          .slice()
          .concat(session)
          .sort((a, b) => b.timeSlot?.date - a.timeSlot?.date);
        setPatient({...patient, sessions: sessCopy});
      }
    },
    [patient],
  );

  const onCreateTreatment = React.useCallback(
    async (dateTime?: DateTime) => {
      try {
        if (patient) {
          const ret = await mutationCreateAppointment({
            variables: {
              params: {
                dateTime: dateTime?.toSeconds(),
                userId: '1',
                patientId: patient.id,
              },
            },
          });

          const newTreatment = ret.data?.createAppointment;

          if (newTreatment) {
            dispatch({type: DashboardActionType.UpdateSession, session: newTreatment});
            onNewFollowUp(newTreatment);
          }
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          const err = Object.values(convertToFormErrors(e, t)).flat().join(' ');
          if (err)
            dispatch({
              type: DashboardActionType.PopUp,
              message: {
                type: MessageBarType.error,
                text: err,
              },
            });
        }
      }
      setIsCreateVisible(false);
    },
    [dispatch, mutationCreateAppointment, onNewFollowUp, patient, t],
  );

  const query = useQuery<{getPatient: Patient}, QuerygetPatientArgs>(getPatient, {
    variables: {id: String(patientId)},
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      onSuccessLoad(data.getPatient);
    },
    onError: (error) => {
      setError(error.networkError ? ErrorType.NETWORK : ErrorType.TOKEN);
    },
  });

  const content = React.useMemo((): JSX.Element => {
    if (error)
      return (
        <div className={styles.container}>
          <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            actions={
              <MessageBarButton
                onClick={(): void => {
                  query
                    .refetch()
                    .then((result) => onSuccessLoad(result.data.getPatient))
                    .catch((e) => {
                      setError(ErrorType.NETWORK);
                    });
                }}>
                {t('button.retry')}
              </MessageBarButton>
            }>
            {t('page.session.error.network')}
          </MessageBar>
        </div>
      );

    if (query.loading || !patient)
      return (
        <div className={[styles.container, styles.centered].join(' ')}>
          <Spinner />
        </div>
      );

    return (
      <div className={styles.container}>
        <Modal
          isOpen={isCreateVisible}
          isBlocking={true}
          onDismiss={(): void => setIsCreateVisible(false)}>
          <SessionTimeChange
            hideCancel={true}
            value={DateTime.fromObject({zone:settings.TIME_ZONE})}
            onChange={onCreateTreatment}
            onCancel={(): void => setIsCreateVisible(false)}
          />
        </Modal>
        {isEditVisible && (
          <PatientForm patient={patient} onUpdate={setPatient} readOnly={readOnly} />
        )}
        {patient.sessions.map((session, idx) => (
          <TreatmentDetails
            treatment={session}
            onNewFollowUp={onNewFollowUp}
            key={session.id}
            readOnly={readOnly}
          />
        ))}
      </div>
    );
  }, [
    error,
    isCreateVisible,
    isEditVisible,
    onCreateTreatment,
    onNewFollowUp,
    onSuccessLoad,
    patient,
    query,
    readOnly,
    t,
  ]);

  return (
    <Panel
      headerText={patient ? patient.firstName + ' ' + patient.lastName : ''}
      isOpen={true}
      onRenderHeader={onRenderHeader}
      layerProps={{eventBubblingEnabled: true}}
      onDismiss={onClose}
      isBlocking={true}
      allowTouchBodyScroll={true}
      type={PanelType.smallFluid}>
      {content}
    </Panel>
  );
};

export default PatientDetails;
