import {IChoiceGroupOptionStyles, IChoiceGroupStyles} from '@fluentui/react';

import {theme} from './theme';

export const InlineChoiceStyle: Partial<IChoiceGroupStyles> = {
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export const InlineChoiceItemStyle: Partial<IChoiceGroupOptionStyles> = {
  root: {paddingRight: theme.spacing.m},
};
