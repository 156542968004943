import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  FontWeights,
  ICheckboxProps,
  Icon,
  IconButton,
  IDropdownOption,
  Label,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApolloError } from 'apollo-client';
import { useStateMachine } from 'little-state-machine';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ERROR_IMAGE from '../../assets/neutral.svg';
import PDF_IMAGE from '../../assets/pdf.png';
import FieldError from '../../components/FieldError';
import { TextArea } from '../../components/TextArea';
import { PRECISION, settings } from '../../const/config';
import {
  Action,
  PaymentType,
  PhotoInput,
  PhotoType,
  ProductType,
  Session,
  Toggle,
  TreatmentFormType,
  TreatmentInput,
} from '../../generated/graphql';
import Uploader from '../../organisms/Uploader';
import { GlobalState } from '../../state/globalState';
import { dropdownProps, dropdownStyles } from '../../styles/dropDownStyles';
import { InlineChoiceItemStyle, InlineChoiceStyle } from '../../styles/InlineChoiceStyle';
import { theme } from '../../styles/theme';
import { UploadedFile } from '../../types/UploadedFile';
import { arrayStringSet } from '../../utils/arraySet';
import { convertErrors, convertFieldErrorToString } from '../../utils/convertToFormErrors';
import { resolveUpload } from '../../utils/resolveUpload';
import { ToggleArrayString } from '../../utils/toogleArray';
import { PADDING } from './const';


const primaryButtonStyle = { textContainer: { margin: 100 } };

const styles = mergeStyleSets({
  container: {
    display: 'grid',
    gap: '4px 4px',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(1,auto)',
    selectors: {
      '@media(min-width: 768px)': {
        //maxWidth: "50%",
      },
    },
  },
  uploader: {
    minHeight: 100,
    minWidth: 100,
    //borderWidth: 1,
    //borderStyle: "dashed",
  },
  images: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridAutoRows: '150px',
    gridGap: PADDING,
    selectors: {
      '@media(max-width: 768px)': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
    },
  },
  imageCnt: {
    position: 'relative',
  },
  delButton: {
    position: 'absolute',
    left: '100%',
    top: '100%',
    backgroundColor: theme.palette.neutralLighterAlt,
    transform: 'translate(-100%,-100%)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  img: { width: '100%', objectFit: 'contain', maxWidth: '90vw', maxHeight: '90vh' },

  product: {
    border: `1px solid ${theme.palette.themePrimary}`,
    width: '100%',
    textAlign: 'left',
    borderCollapse: 'collapse',
  },

  header: {
    background: theme.palette.neutralLight,
    minHeight: 32,
  },
  productHeader: {
    padding: theme.spacing.s2,
    textAlign: 'center',
  },
  actionColumn: {
    width: 1,
  },
  number: {
    width: 75,
  },
  ralign: {
    textAlign: 'right',
  },
  calign: {
    textAlign: 'center',
  },
  total: {
    paddingTop: theme.spacing.s1,
    paddingBottom: theme.spacing.s1,
    fontWeight: FontWeights.bold,
  },
});

interface TreatmentFormProps {
  treatment: Session;
  readOnly?: boolean;
  formType: TreatmentFormType;
  products: IDropdownOption[];
  onSave: (values: TreatmentInput) => Promise<void>;
}

interface ProductUsageItem {
  productId: string;
  unitsInjected: number;
  unitsPurchased: number;
  price: number;
  name: string;
  isNewPackage: Toggle;
}

type FormProps = {
  note: string;
  paymentType: PaymentType;
  actions: Action[];
  productTypes: ProductType[];
  uploaded: UploadedFile[];
  productUsage: ProductUsageItem[];
};

const productUsageField = 'productUsage';
const TAX_RATE = 1.14975;

type FormFieldNames = Extract<keyof FormProps, string>;

export const TreatmentForm: React.FunctionComponent<TreatmentFormProps> = (props) => {
  const { t } = useTranslation();
  const { treatment, formType, onSave, products } = props;

  console.log({ tr: treatment.paymentType });

  const {
    state: { isLowDataMode },
  } = useStateMachine<GlobalState>();

  const FormSchema = React.useMemo(() => {
    return yup.object<FormProps>().shape({
      note: yup.string(),
      actions: yup.array(),
      productTypes: yup.array(),
      uploaded: yup.array(),
      paymentType: yup.mixed().required().oneOf(Object.keys(PaymentType).filter(key => key !== PaymentType.UNKNOWN), t('constraints:isNotEmpty')),
      productUsage: yup.array().of(
        yup.object<ProductUsageItem>().shape({
          productId: yup.string().required(),
          price: yup.number().min(0),
          unitsInjected: yup.number().min(0),
          unitsPurchased: yup.number().min(0),
          isNewPackage: yup.mixed().oneOf(Object.keys(Toggle)),
        }),
      ),
    });
  }, [t]);

  const photoType =
    formType === TreatmentFormType.TREATMENT
      ? PhotoType.TREATMENT
      : formType === TreatmentFormType.FOLLOWUP
        ? PhotoType.FOLLOWUP
        : PhotoType.SESSION;

  const [photo, setPhoto] = React.useState<UploadedFile>();
  const [photoToDelete, setPhotoToDelete] = React.useState<UploadedFile>();
  const [uploadedResolved, setUploadedResolved] = React.useState<UploadedFile[]>([]);

  const CheckOptions = React.useMemo(() => {
    const options: Record<TreatmentFormType, ICheckboxProps[]> = {
      TREATMENT: [
        { id: Action.SENDINSTRUCTIONSBOTOX, label: t(`field.${Action.SENDINSTRUCTIONSBOTOX}`) },
        { id: Action.SENDINSTRUCTIONSFILLER, label: t(`field.${Action.SENDINSTRUCTIONSFILLER}`) },
        { id: Action.SENDREQUESTLINK, label: t(`field.${Action.SENDREQUESTLINK}`) },
        { id: Action.SENDINVOICELINK, label: t(`field.${Action.SENDINVOICELINK}`) },
      ],
      FOLLOWUP: [{ id: Action.SENDREQUESTLINK, label: t(`field.${Action.SENDREQUESTLINK}`) }],
      SESSION: [],
    };
    return options[formType];
  }, [formType, t]);

  const defaultValues = React.useMemo(() => {
    return {
      note:
        (formType === TreatmentFormType.FOLLOWUP
          ? treatment.noteFollowUp
          : formType === TreatmentFormType.TREATMENT
            ? treatment.noteTreatment
            : treatment.noteSession) || '',
      actions: [],
      productTypes: [],
      paymentType: treatment.paymentType,
      uploaded: treatment.files
        .filter((v) => v.type === photoType)
        .map<UploadedFile>((v) => {
          return { uploadedName: v.fileCDN, originalName: v.fileName };
        }),
      productUsage:
        treatment.productUsage?.map((lineItem) => ({
          productId: String(lineItem.product.id),
          isNewPackage: lineItem.isNewPackage,
          unitsInjected: Number.isNaN(lineItem.unitsInjected)
            ? undefined
            : Number(lineItem.unitsInjected),
          unitsPurchased: Number.isNaN(lineItem.unitsPurchased)
            ? undefined
            : Number(lineItem.unitsPurchased),
          price: Number.isNaN(lineItem.price) ? undefined : Number(lineItem.price),
          name:
            lineItem.product.brand.name === lineItem.product.name
              ? lineItem.product.name
              : `${lineItem.product.brand.name} ${lineItem.product.name}`,
        })) || [],
    };
  }, [formType, photoType, treatment]);

  const {
    handleSubmit,
    formState,
    formState: { errors },
    setValue,
    setError,
    register,
    watch,
    control,
  } = useForm<FormProps>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
    resolver: yupResolver(FormSchema),
    criteriaMode: 'all',
  });

  const {
    fields,
    append: appendUsage,
    remove: removeUsage,
  } = useFieldArray({
    control,
    name: productUsageField,
  });

  const fieldsLines = watch(productUsageField);
  const actions = watch('actions');
  const uploaded = watch('uploaded');
  const consentForms = watch('productTypes');

  React.useEffect(() => {
    setValue('actions', arrayStringSet(actions, Action.SENDCONSENTLINK, consentForms.length > 0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentForms, setValue]);

  React.useEffect(() => {
    register('actions');
    register('productTypes');
    register('uploaded');
    register(productUsageField);
  }, [register]);

  React.useEffect(() => {
    const resolve = async () => {
      setUploadedResolved(await Promise.all(uploaded.map((file) => resolveUpload(file))));
    };
    resolve().then();
  }, [uploaded]);

  const onSubmit = React.useCallback(
    async (values: FormProps) => {


      const treatmentInput: TreatmentInput = {
        type: formType,
        note: values.note,
        actions: values.actions,
        productTypes: values.productTypes,
        productUsage: values.productUsage.map(({ name, ...props }) => ({
          ...props,
          unitsInjected: props.unitsPurchased,
        })),
        paymentType: values.paymentType,
        files: values.uploaded.map<PhotoInput>((v) => {
          return { type: photoType, fileName: v.originalName, fileCDN: v.uploadedName };
        }),
      };

      try {
        setValue('actions', []);
        setValue('productTypes', []);
        return onSave(treatmentInput);
      } catch (e) {
        if (e instanceof ApolloError) {
          const { fieldErrors } = convertErrors(e, t);
          fieldErrors?.forEach((e, idx) => {
            setError(e.path as FormFieldNames, { types: { [idx]: e.message } });
          });
        }
      }
    },
    [formType, onSave, photoType, setError, setValue, t],
  );

  const onUpload = React.useCallback(
    (files: UploadedFile[]) => {
      if (files.length) {
        setValue('uploaded', uploaded.concat(files));
      }
    },
    [setValue, uploaded],
  );

  const { isSubmitting } = formState;

  const onSelectProduct = React.useCallback(
    (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option && fields.findIndex((item) => item.productId === option?.key) === -1) {
        appendUsage({
          unitsPurchased: 0,
          productId: String(option.key),
          unitsInjected: 0,
          price: option.data.defaultPrice / PRECISION,
          name: option.text,
          isNewPackage: option.data.productType === ProductType.BOTOX ? Toggle.NO : Toggle.YES,
        });
      }
    },
    [fields, appendUsage],
  );

  const lineTotals = React.useMemo<{ total: number; totalTx: number }[]>(() => {
    return fieldsLines.map((field) => {
      const total = field.price * field.unitsPurchased || 0;
      return {
        total,
        totalTx: Math.ceil(total * TAX_RATE * PRECISION) / PRECISION,
      };
    });
  }, [JSON.stringify(fieldsLines)]);

  const totals = React.useMemo<{ total: number; totalTx: number }>(() => {
    return lineTotals.reduce(
      (pv, cv) => {
        return { total: pv.total + cv.total, totalTx: pv.totalTx + cv.totalTx };
      },
      { total: 0, totalTx: 0 },
    );
  }, [JSON.stringify(lineTotals)]);

  return (
    <>
      <Modal isOpen={!!photo} onDismiss={(): void => setPhoto(undefined)}>
        {photo &&
          (photo.uri?.endsWith('.pdf') ||
          photo.uri?.endsWith('.PDF') ||
          photo.uri?.startsWith('data:application/pdf') ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${photo.uri?.startsWith('data:application/pdf') ? '#' : photo.uri}`}>
              <img src={PDF_IMAGE} className={styles.img} alt=""/>
            </a>
          ) : (
            <a target="_blank" rel="noreferrer" href={`${photo.uri}`}>
              <img src={`${photo.uri}`} className={styles.img} alt=""/>
            </a>
          ))}
      </Modal>
      <Modal isOpen={!!photoToDelete} onDismiss={(): void => setPhotoToDelete(undefined)}>
        <Stack tokens={{ childrenGap: PADDING, padding: PADDING }} style={{ minHeight: '200px' }}>
          <StackItem grow>{t('message.confirmDelete')}</StackItem>
          <StackItem align="end">
            <DefaultButton onClick={(): void => setPhotoToDelete(undefined)}>
              {t('button.cancel')}
            </DefaultButton>
            <PrimaryButton
              disabled={props.readOnly}
              styles={{ root: { marginLeft: PADDING } }}
              onClick={(): void => {
                setValue(
                  'uploaded',
                  uploaded.filter((v) => {
                    return v.uploadedName !== photoToDelete?.uploadedName;
                  }),
                );
                setPhotoToDelete(undefined);
              }}>
              {t('button.delete')}
            </PrimaryButton>
          </StackItem>
        </Stack>
      </Modal>
      <div className={styles.container}>
        <div>
          <Label>{t('tab.photos')}</Label>
          <div className={styles.images}>
            {uploadedResolved.map((file, idx) => {
              const isPDF =
                file.uploadedName.endsWith('.pdf') ||
                file.uploadedName.endsWith('.PDF') ||
                file.uri?.startsWith('data:application/pdf');
              return (
                <div className={styles.imageCnt} key={idx}>
                  {isPDF ? (
                    !file.uri?.startsWith('data:application/pdf') ? (
                      <a target="_blank" rel="noreferrer" href={`${file.uri}`}>
                        <img className={styles.image} src={PDF_IMAGE} alt={file.originalName}/>
                      </a>
                    ) : (
                      <img className={styles.image} src={PDF_IMAGE} alt={file.originalName}/>
                    )
                  ) : (
                    <img
                      style={{ cursor: 'pointer' }}
                      className={styles.image}
                      src={isLowDataMode && !file.uri?.startsWith('data:') ? ERROR_IMAGE : file.uri}
                      onClick={() => setPhoto(file)}
                      onError={(e) => {
                        e.currentTarget.src = ERROR_IMAGE;
                      }}
                      alt={file.originalName}
                    />
                  )}
                  <IconButton
                    disabled={isSubmitting}
                    iconProps={{ iconName: 'Delete' }}
                    className={styles.delButton}
                    onClick={(ev): void => {
                      ev.stopPropagation();
                      setPhotoToDelete(file);
                    }}
                  />
                </div>
              );
            })}
            <div className={styles.uploader}>
              <Uploader
                uploadURL={settings.URL_UPLOAD}
                notifyUpload={onUpload}
                isPDFAllowed={true}
              />
            </div>
          </div>
        </div>

        {formType === TreatmentFormType.TREATMENT && !props.readOnly && (
          <div>
            <table className={styles.product}>
              <thead>
              <tr className={styles.header}>
                <th className={[styles.actionColumn, styles.productHeader].join(' ')}>
                  <Icon iconName="Add"/>
                </th>
                <th className={[styles.productHeader].join(' ')}>Product</th>
                <th className={[styles.number, styles.productHeader].join(' ')}>Quantity</th>
                <th className={[styles.number, styles.productHeader].join(' ')}>
                  Price per unit
                </th>
                <th className={[styles.number, styles.productHeader].join(' ')}>Total</th>
                <th className={[styles.number, styles.productHeader].join(' ')}>Total + Tx</th>
                <th className={[styles.actionColumn, styles.productHeader].join(' ')}>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              {fields.map((field, index) => (
                <tr key={field.id}>
                  <td>
                    <Controller
                      control={control}
                      name={`productUsage.${index}.isNewPackage` as 'productUsage.0.isNewPackage'}
                      defaultValue={field.isNewPackage}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          checked={value === Toggle.YES}
                          onChange={(_, checked) => onChange(checked ? Toggle.YES : Toggle.NO)}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`productUsage.${index}.productId` as 'productUsage.0.productId'}
                      defaultValue={field.productId}
                      render={({ field: {} }) => (
                        <TextField
                          borderless
                          readOnly
                          value={field.name}
                          styles={
                            errors.productUsage && errors.productUsage[index]?.productId
                              ? {
                                fieldGroup: { borderColor: theme.semanticColors.errorText },
                              }
                              : {}
                          }
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={
                        `productUsage.${index}.unitsPurchased` as 'productUsage.0.unitsPurchased'
                      }
                      defaultValue={field.unitsPurchased ?? 0}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          styles={
                            errors.productUsage && errors.productUsage[index]?.unitsPurchased
                              ? {
                                fieldGroup: { borderColor: theme.semanticColors.errorText },
                                field: { textAlign: 'right' },
                              }
                              : { field: { textAlign: 'right' } }
                          }
                          value={value ? String(value) : ''}
                          onChange={(_, newValue) => {
                            if (!newValue || newValue?.match(/^[0-9]+$/)) {
                              onChange(newValue);
                            }
                          }}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      control={control}
                      name={`productUsage.${index}.price` as 'productUsage.0.price'}
                      defaultValue={field.price ?? 0}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          onChange={(_, newValue) => {
                            if (!newValue || newValue?.match(/^[0-9]+$/)) {
                              onChange(newValue);
                            }
                          }}
                          onBlur={onBlur}
                          value={value ? String(value) : ''}
                          styles={
                            errors.productUsage && errors.productUsage[index]?.price
                              ? {
                                fieldGroup: { borderColor: theme.semanticColors.errorText },
                                field: { textAlign: 'right' },
                              }
                              : { field: { textAlign: 'right' } }
                          }
                        />
                      )}
                    />
                  </td>
                  <td className={styles.ralign}>{lineTotals[index].total.toFixed(2)}</td>
                  <td className={styles.ralign}>{lineTotals[index].totalTx.toFixed(2)}</td>
                  <td>
                    <IconButton
                      iconProps={{ iconName: 'Delete' }}
                      onClick={(): unknown => removeUsage(index)}
                    />
                  </td>
                </tr>
              ))}
              <tr className={[styles.header].join(' ')}>
                <td colSpan={4}></td>
                <td className={[styles.ralign, styles.total].join(' ')}>
                  {totals.total.toFixed(2)}
                </td>
                <td className={[styles.ralign, styles.total].join(' ')}>
                  {totals.totalTx.toFixed(2)}
                </td>
                <td></td>
              </tr>
              <tr className={styles.header}>
                <td colSpan={6}>
                  <Dropdown
                    {...dropdownProps}
                    styles={dropdownStyles}
                    options={products || []}
                    selectedKey=""
                    onChange={onSelectProduct}
                    onRenderOption={(item) => <span
                      style={item?.data.priority ? { fontWeight: 'bold' } : {}}>{item?.text}</span>}
                  />
                </td>
                <td></td>
              </tr>
              </tbody>
            </table>

            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (errors.productUsage as any)?.message ? (
                <FieldError>
                  {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (errors.productUsage as any).message
                  }
                </FieldError>
              ) : null
            }
            <div>
              <Controller
                control={control}
                name="paymentType"
                render={({ field: { onChange, value } }) => (
                  <><ChoiceGroup
                    styles={InlineChoiceStyle}
                    selectedKey={value}
                    onChange={(_, option) => onChange(option?.key)}
                    options={[PaymentType.CASH, PaymentType.CARD].map((pt) => ({
                      key: pt,
                      styles: InlineChoiceItemStyle,
                      text: t(`option.paymentType.${pt}`),
                    }))}
                  />{(errors.paymentType as any)?.message ? (
                    <FieldError>
                      {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (errors.paymentType as any).message
                      }
                    </FieldError>
                  ) : null}</>
                )}
              />
            </div>
          </div>
        )}
        <div>
          <Controller
            control={control}
            name="note"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextArea
                label={t('field.comment')}
                multiline
                resizable={true}
                autoAdjustHeight
                disabled={isSubmitting}
                onBlur={onBlur}
                onChange={(_, newValue) => onChange(newValue)}
                value={value}
                errorMessage={convertFieldErrorToString(errors.note)}
              />
            )}
          />
        </div>
        {Object.values(CheckOptions).map((checkOption, idx) => (
          <div key={idx}>
            <Checkbox
              {...checkOption}
              disabled={isSubmitting}
              checked={actions.includes(checkOption.id as Action)}
              onChange={(_: unknown, checked): void => {
                const newActions = arrayStringSet<Action>(
                  actions,
                  checkOption.id as Action,
                  !!checked,
                );
                setValue('actions', newActions);
              }}
            />
          </div>
        ))}
        {formType === TreatmentFormType.TREATMENT && (
          <Controller
            name="productTypes"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Dropdown
                {...dropdownProps}
                styles={dropdownStyles}
                label={t(`field.${Action.SENDCONSENTLINK}`)}
                multiSelect
                selectedKeys={value}
                onBlur={onBlur}
                onChange={(_, newValue) => {
                  onChange(ToggleArrayString(value, newValue?.key as string));
                }}
                options={Object.keys(ProductType).map((v) => ({ key: v, text: t(`product.${v}`) }))}
              />
            )}
          />
        )}
        <div style={{ textAlign: 'left' }}>
          <DefaultButton
            primary={true}
            styles={primaryButtonStyle}
            disabled={isSubmitting || props.readOnly}
            onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
            {t('button.save')}
          </DefaultButton>
        </div>
      </div>
    </>
  );
};

export default TreatmentForm;
