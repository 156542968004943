import { useMutation } from '@apollo/react-hooks';
import { Label, Link, PrimaryButton, Stack, StackItem } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useRouteMatch } from 'react-router';
import SignatureCanvas from 'react-signature-canvas';
import { Cell, Grid } from 'styled-css-grid';
import * as yup from 'yup';

import FieldError from '../components/FieldError';
import { SIGNATURE_MIN_STROKE } from '../const/config';
import { MutationsignConsentArgs, ProductType } from '../generated/graphql';
import signConsent from '../graphql/mutations/signConsent';
import { theme } from '../styles/theme';
import MultipageForm from '../templates/MultipageForm';
import Page from '../templates/Page';
import { convertFieldErrorToString, convertToFormErrors, GLOBAL_ERROR } from '../utils/convertToFormErrors';
import { isEmpty } from '../utils/isEmpty';

type ConsentForm = {
  sessionCode: string;
  productTypes: ProductType[];
  signatureSVG: string;
};

export const Consent: React.FunctionComponent = (props) => {
  const {t} = useTranslation();
  const {
    params: {sessionCode, productTypes: productTypesString},
  } = useRouteMatch<{sessionCode: string; productTypes: string}>();
  const ref = React.useRef<SignatureCanvas>(null);

  const [globalError, setGlobalError] = React.useState<string>();

  const [runMutation, result] = useMutation<boolean, MutationsignConsentArgs>(signConsent);

  const {loading} = result;

  const ConsentSchema = React.useMemo(() => {
    return yup.object<ConsentForm>().shape({
      signatureSVG: yup
        .string()
        .test('signatureSVG', t('constraints:isNotEmpty', {context: 'signature'}), (val) => {
          if (ref.current) {
            const points = ref.current.toData().map((v) => v.length);
            if (points.length > 0) {
              const longest = points.reduce((p, v) => (p > v ? p : v));
              return longest > SIGNATURE_MIN_STROKE;
            }
          }
          return false;
        }),
    });
  }, [t]);

  const productTypes = productTypesString.split('|').map((v) => v.toUpperCase() as ProductType);

  const {
    formState,
    control,
    formState: {errors},
    handleSubmit,
    register,
    setError,
  } = useForm<ConsentForm>({
    mode: 'onChange',
    defaultValues: {sessionCode, productTypes},
    reValidateMode: 'onChange',
    resolver: yupResolver(ConsentSchema),
    criteriaMode: 'all',
  });
  const {isSubmitting} = formState;

  const isMounted = React.useRef<boolean>();
  React.useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    register('sessionCode');
    register('productTypes');
  }, [register]);

  const onSubmit = React.useCallback(
    (values) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          await runMutation({
            variables: {
              params: {
                ...values,
                signatureSVG: ref.current?.toDataURL() || '',
              },
            },
          });
          setSubmitted(true);
        } catch (e) {
          if (e instanceof ApolloError) {
            Object.entries(convertToFormErrors(e)).forEach(([fld, error]) => {
              if (fld === GLOBAL_ERROR) {
                setGlobalError(error.join(' \n'));
              } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setError(fld as any, {message: error.join(' \n')});
              }
            });
          }
        } finally {
          resolve();
        }
      });
    },
    [runMutation, setError],
  );

  const nextButton = React.useMemo(
    () => (
      <PrimaryButton
        disabled={isSubmitting}
        onClick={async (): Promise<void> => handleSubmit(onSubmit)()}>
        {t(globalError ? 'button.retry' : 'button.submit')}
      </PrimaryButton>
    ),
    [isSubmitting, t, globalError, handleSubmit, onSubmit],
  );

  if (submitted) {
    return (
      <Page title={t('page.session.title')}>
        <Grid style={{width: '100%', minHeight: '50vh'}} columns={'1fr'} rows={'1fr'}>
          <Cell center middle className='messageText'>
            <ReactMarkdown children={t('page.consent.done')} />
          </Cell>
        </Grid>
      </Page>
    );
  }

  return (
    <Page title={t('page.session.title')}>
      <MultipageForm
        title={t('page.session.consent.title', {
          context: loading ? 'submit' : !isEmpty(errors) ? 'error' : '',
        })}
        nextButton={nextButton}>
        <form>
          <Stack tokens={{childrenGap: theme.spacing.m}}>
            <StackItem className='justified'>
              <ReactMarkdown children={t('page.session.consent.text.common')} />
              {productTypes.map((productType) => {
                const key = `page.session.consent.text.${productType.toUpperCase()}`;
                const txt = t(key);
                return (
                  txt !== key && (
                    <div key={productType}>
                      <hr />
                      <ReactMarkdown children={txt} />
                    </div>
                  )
                );
              })}
            </StackItem>
            <StackItem>
              <hr />
              <br />
              <br />
              {t('page.session.consent.certifySign')}
            </StackItem>
            <StackItem>
              <Stack>
                <StackItem>
                  <Label>{t('field.signature')}</Label>
                </StackItem>
                <StackItem>
                  <Controller
                    control={control}
                    name='signatureSVG'
                    render={() => (
                      <SignatureCanvas
                        ref={ref}
                        clearOnResize={false}
                        canvasProps={{className: 'signature'}}
                      />
                    )}
                  />
                </StackItem>
                <StackItem>
                  {errors.signatureSVG && (
                    <FieldError>{convertFieldErrorToString(errors.signatureSVG)}</FieldError>
                  )}
                  <Link
                    onClick={(): void => {
                      ref.current && ref.current.clear();
                    }}>
                    {t('button.reset')}
                  </Link>
                </StackItem>
                {globalError && (
                  <StackItem>
                    <FieldError>{globalError}</FieldError>
                  </StackItem>
                )}
              </Stack>
            </StackItem>
          </Stack>
        </form>
      </MultipageForm>
    </Page>
  );
};
