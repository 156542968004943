import gql from 'graphql-tag';

const query = gql`
  query getSession($id: String!) {
    getSession(id: $id) {
      patient {
        email
        phone
        firstName
        lastName
        gender
        birthday
        comment
        existingClient
      }
      sessionCode
      status
      commentHealth
      commentProcedure
      spots
      mediaConsent
      photos {
        type
        fileName
        fileCDN
      }
      answers {
        questionCode
        answer
      }
      timeSlot {
        date
      }
    }
  }
`;
export default query;
