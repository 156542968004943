import gql from 'graphql-tag';

const query = gql`
  mutation saveTreatment($version: Int, $id: Int, $params: TreatmentInput!) {
    saveTreatment(version: $version, id: $id, params: $params) {
      id
      version
      files {
        id
        version
        type
        fileName
        fileCDN
      }
      productUsage {
        id
        price
        unitsInjected
        unitsPurchased
        isNewPackage
        product {
          id
          name
          brand {
            id
            name
          }
        }
      }
      status
      noteTreatment
      noteFollowUp
    }
  }
`;
export default query;
