import {FontSizes, PrimaryButton} from '@fluentui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {generatePath, useHistory} from 'react-router';

import {SectionProps} from '.';
import {URL_REQUEST} from '../../const/config';

const Contact: React.FunctionComponent<SectionProps> = (props) => {
  const {className} = props;
  const {t} = useTranslation();
  const history = useHistory();

  return (
    <section className={className}>
      <div>
        <h2>{t('main:contact.title')}</h2>
      </div>
      <div className='contact'>
        <div className='map'>
          <iframe
            title='map'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2802.219056647743!2d-74.01178118442462!3d45.38475177910009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9481d106fad69%3A0xf9b71896cdf4e958!2s18%20Av.%20Saint-Henri%2C%20Vaudreuil-Dorion%2C%20QC%20J7V%202J6%2C%20Canada!5e0!3m2!1sen!2sbs!4v1662323869772!5m2!1sen!2sbs'
            width='100%'
            height='100%'
            frameBorder='0'
            style={{border: 0}}
            allowFullScreen={false}
            aria-hidden='false'
            tabIndex={0}></iframe>
        </div>
        <div className='address'>
          <ReactMarkdown transformLinkUri={null}>{t('main:contact.content')}</ReactMarkdown>
          <PrimaryButton
            styles={{
              root: {fontSize: FontSizes.xLargePlus, width: '100%', height: '100px'},
            }}
            onClick={(): void => {
              history.push(generatePath(URL_REQUEST, {}));
            }}>
            {t('button.bookNow')}
          </PrimaryButton>
        </div>
      </div>
    </section>
  );
};

export default Contact;
