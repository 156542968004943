import gql from 'graphql-tag';

const query = gql`
  query getAppointments($params: GetAppointmentsParams!) {
    getAppointments(params: $params) {
      id
      version
      patient {
        id
        version
        email
        phone
        firstName
        lastName
        gender
        birthday
        comment
        existingClient
      }
      sessionCode
      status
      commentHealth
      commentProcedure
      note
      signatureSVG
      signatureTime
      signatureIP
      requestTime
      requestIP
      spots
      photos {
        id
        version
        type
        fileName
        fileCDN
      }
      answers {
        id
        version
        questionCode
        answer
      }
      productUsage {
        id
        version
        productType
        unitsInjected
        unitsPurchased
      }
      timeSlot {
        id
        version
        date
        length
      }
    }
  }
`;
export default query;
