import React from 'react';
import { useTranslation } from 'react-i18next';

export const Agenda: React.FunctionComponent = (props) => {
  const {t} = useTranslation();
  return (
    <>
      <h2>{t('element.agenda.title')}</h2>
      {props.children}
    </>
  );
};

export default Agenda;
