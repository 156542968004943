import { useMutation } from '@apollo/react-hooks';
import { MessageBar, MessageBarType, PrimaryButton, Spinner, Stack, StackItem } from '@fluentui/react';
import { ApolloError } from 'apollo-client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PARAM_STEP, URL_REQUEST } from '../../const/config';
import { MutationrequestAppointmentArgs } from '../../generated/graphql';
import { requestAppointment } from '../../graphql/mutations';
import { theme } from '../../styles/theme';
import MultipageForm from '../../templates/MultipageForm';
import Page from '../../templates/Page';
import { convertToFormErrors, FormErrors, GLOBAL_ERROR } from '../../utils/convertToFormErrors';
import { isEmpty } from '../../utils/isEmpty';
import { SessionStage, StageComponent } from './Stages';

export const StageSubmitRequest: StageComponent = (props) => {
  const {session, step, onNext} = props;
  const {t} = useTranslation();
  const history = useHistory();

  const [runMutation, result] = useMutation<boolean, MutationrequestAppointmentArgs>(
    requestAppointment,
  );

  const isMounted = React.useRef<boolean>();
  React.useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
    };
  }, []);
  const [errors, setErrors] = React.useState<FormErrors>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {called, loading} = result;

  const onSubmit = React.useCallback(() => {
    let errors: FormErrors;
    return new Promise<void>(async (resolve, reject) => {
      const {patient, timeSlot} = session;
      try {
        if (!patient) throw new Error();
        if (!timeSlot) throw new Error();

        const {firstName, lastName, phone, email} = patient;

        await runMutation({
          variables: {
            params: {
              patient: {
                firstName: firstName?.trim() || '',
                lastName: lastName?.trim() || '',
                phone: phone?.replace(/[^0-9]/g, '')?.trim() || '',
                email: email?.trim() || '',
              },
              timeSlot: timeSlot.id,
            },
          },
        });

        onNext && onNext(SessionStage.SUBMIT, {}, true);

        history.replace(URL_REQUEST.replace(PARAM_STEP, SessionStage.REQUEST));
      } catch (e) {
        if (e instanceof ApolloError) {
          errors = convertToFormErrors(e);
        }
      } finally {
        isMounted.current && setErrors(errors);
        resolve();
      }
    });
  }, [history, onNext, runMutation, session]);

  React.useEffect(() => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Page>
      <MultipageForm
        title={t(`page.session.${step}.title`, {
          context: loading && called ? 'submit' : !isEmpty(errors) ? 'error' : '',
        })}>
        <Stack tokens={{childrenGap: theme.spacing.m}}>
          <StackItem>
            {!called || loading ? (
              <Spinner label={t('message.progress', {context: 'submit'})} />
            ) : (
              errors && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                  {Object.entries(errors).map(([key, value]) => (
                    <div key={key}>
                      {value.map((message, idx) => (
                        <div key={key + idx}>{key === GLOBAL_ERROR ? t(message) : message}</div>
                      ))}
                    </div>
                  ))}
                </MessageBar>
              )
            )}
          </StackItem>
          {errors && errors[GLOBAL_ERROR] && errors[GLOBAL_ERROR].includes('error.network') && (
            <StackItem>
              <PrimaryButton
                onClick={(): void => {
                  onSubmit();
                }}>
                {t('button.retry')}
              </PrimaryButton>
            </StackItem>
          )}
        </Stack>
      </MultipageForm>
    </Page>
  );
};

export default StageSubmitRequest;
