import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';

import MultipageForm from '../templates/MultipageForm';
import Page from '../templates/Page';

export const Info: React.FunctionComponent = (props) => {
  const {page} = useParams<{page: string}>();
  const {t} = useTranslation();
  return (
    <Page title={t('page.info.title')}>
      <MultipageForm title={t(`page.info.${page}.title`)}>
        <ReactMarkdown children={t(`page.info.${page}.text`)} className='justified' />
      </MultipageForm>
    </Page>
  );
};

export default Info;
