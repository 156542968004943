import gql from 'graphql-tag';

const query = gql`
  query getTimeSlots($params: GetTimeSlotsParams!) {
    getTimeSlots(params: $params) {
      id
      date
      availability
      session {
        id
        status
        type
        sessionCode
        requestTime
        signatureTime
        photos {
          id
          type
          fileCDN
        }
        patient {
          id
          firstName
          lastName
          phone
          email
          existingClient
        }
      }
    }
  }
`;
export default query;
