import gql from 'graphql-tag';

const query = gql`
  mutation savePatient($version: Float, $id: Float, $params: PatientInput!) {
    savePatient(version: $version, id: $id, params: $params) {
      id
      version
      firstName
      lastName
      email
      existingClient
      birthday
      gender
      language
      comment
    }
  }
`;
export default query;
