import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

import { SectionProps } from '.';
import FacebookLogo from '../../assets/f.svg';
import InstagramLogo from '../../assets/ig.svg';
import Signature from '../../assets/signature.svg';

const Home: React.FunctionComponent<SectionProps> = (props) => {
  const {className} = props;
  const {t} = useTranslation();
  return (
    <section className={className}>
      <div className='left'>
        <div className='banner'>
          <div>{t('main:about.banner')}</div>
        </div>
      </div>
      <div className='mid'>
        <div className='number absolute'></div>
      </div>
      <div className='right'>
        <div className='text'>
          <ReactMarkdown>{t('main:about.brief')}</ReactMarkdown>
          <p className='signature2'>
            <img src={Signature} alt='Signature' width='50%' draggable='false' />
          </p>
        </div>
        <div className='social'>
          <a href='https://facebook.com/juvenis.ca' target='_blank' rel='noopener noreferrer'>
            <img src={FacebookLogo} alt='Facebook' width='64px' height='64px' draggable='false' />
          </a>
          <a href='https://www.instagram.com/irina.injections/'>
            <img src={InstagramLogo} alt='Instagram' width='64px' height='64px' draggable='false' />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Home;
