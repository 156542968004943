import { Product, ProductType, ProductVariant } from '../generated/graphql';

const SortByType: Record<ProductType, number> = {
  BOTOX: 0,
  FILLER: 1,
  RADIESSE: 2,
  SCULPTRA: 3,
  PRP: 4,
  MESO: 5,
  HYALO: 6,
  OTHER: 7,
};

export type PartialProductVariant = Partial<ProductVariant> & { product: Product };

export const compareProductVariant = (
  { product: a }: PartialProductVariant,
  { product: b }: PartialProductVariant,
): number => {
  // First, compare by priority
  if (a.priority !== b.priority) {
    return a.priority >= b.priority ? -1 : 1;
  }

  // If priorities are equal, then compare by productType
  if (a.productType !== b.productType) {
    return SortByType[a.productType] < SortByType[b.productType] ? -1 : 1;
  }

  // If productType is also equal, compare by name
  return `...${a.name}`.localeCompare(`...${b.name}`);
};

export const compareProduct = (productA: Product, productB: Product): number => {
  return compareProductVariant({ product: productA, name: '' }, { product: productB, name: '' });
};
