import { FontSizes, mergeStyleSets } from '@fluentui/react';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { settings } from '../../const/config';

import { TimeSlot } from '../../generated/graphql';
import { theme } from '../../styles/theme';
import { PADDING } from './const';
import { DashboardActionType, useDashboardContext } from './Context';
import { Slot } from './Slot';
import { PeriodType } from './types';

const hours = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const hoursFmt = hours.map((v) => String(v).padStart(2, '0') + ':00');

const styles = mergeStyleSets({
  grid: {
    display: 'grid',
    width: '100%',
    height: 'calc(100% - 32px)',
    gridTemplateRows: `32px 32px repeat(${hours.length},1fr)`,
  },

  columns7: {
    gridTemplateColumns: '48px repeat(7, 1fr)',
  },

  columns5: {
    gridTemplateColumns: '48px repeat(5, 1fr)',
  },

  columns1: {
    gridTemplateColumns: '48px 1fr',
  },

  header: {
    textAlign: 'center',
  },

  hour: {
    padding: PADDING,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    //width: PADDING,
  },
  topBorder: {
    borderTop: '1px solid ' + theme.palette.neutralQuaternaryAlt,
    boxSizing: 'border-box',
  },
  day: {
    padding: PADDING,
    overflow: 'hidden',
    borderLeft: '1px solid ' + theme.palette.neutralQuaternaryAlt,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.themeLighterAlt,
  },
  today: {
    borderTop: '4px solid ' + theme.palette.themeDark,
    backgroundColor: theme.palette.themeLighterAlt,
  },
  selected: {
    backgroundColor: theme.palette.neutralLight,
  },
  mDay: {
    fontSize: FontSizes.medium,
  },

  leftBorder: {
    borderLeft: '1px solid ' + theme.palette.neutralLighter,
    paddingLeft: '4px',
    paddingRight: '4px',
  },
});

type DayData = {
  weekday: string;
  day: string;
  hours: Record<number, TimeSlot[]>;
};

export const WeekView: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const {
    state: { periodType, selectedDate, timeSlots, range },
    dispatch,
  } = useDashboardContext();

  const numDays = periodType === PeriodType.DAY ? 1 : periodType === PeriodType.WEEK ? 7 : 5;
  const columnsClass = styles[`columns${numDays}`];

  const daysCells = React.useMemo(() => {
    if (!selectedDate || !range || !timeSlots) return null;
    const pMonth = -1;
    const { startDate, endDate } = range;

    const data: DayData[] = Array<DayData>(numDays);
    const mapDay: Record<number, number> = {};

    let currentIdx = 0;
    for (let dt = startDate; dt < endDate; dt = dt.plus({ day: 1 })) {
      mapDay[dt.weekday] = currentIdx;
      const cMonth = dt.month;
      const mName = cMonth !== pMonth ? t(`calendar:shortMonth.${dt.monthLong}`) : '';
      if (currentIdx < numDays)
        data[currentIdx] = {
          weekday: t(`calendar:day.${dt.weekdayLong}`),
          day: `${mName} ${dt.day}`,
          hours: {},
        };
      currentIdx++;
    }

    for (const ts of timeSlots) {
      const dt = DateTime.fromSeconds(ts.date, { zone: settings.TIME_ZONE });
      const { weekday, hour } = dt;
      const idx = mapDay[weekday];
      const dcell = data[idx];
      if (dcell) {
        if (!dcell.hours[hour]) dcell.hours[hour] = [];
        dcell.hours[hour].push(ts);
      }
    }
    return data;
  }, [numDays, range, selectedDate, t, timeSlots]);

  if (!timeSlots || !selectedDate || !range) return null;

  return (
    <div className={[styles.grid, columnsClass].join(' ')}>
      <div></div>
      {daysCells?.map((day, idx) => (
        <div key={idx} className={styles.leftBorder}>
          {day.weekday}
        </div>
      ))}
      <div></div>
      {daysCells?.map((day, idx) => (
        <div key={idx} className={styles.leftBorder}>
          {day.day}
        </div>
      ))}
      {hours.map((hour, idx) => (
        <React.Fragment key={idx}>
          <div className={styles.hour}>{hoursFmt[idx]}</div>
          {daysCells?.map(({ hours }, idx) => {
            const timeSlots = hours[hour];
            return timeSlots ? (
              <div className={styles.leftBorder} key={idx}>
                {timeSlots.map((ts, idx) => (
                  <Slot
                    key={idx}
                    ts={ts}
                    onClick={(): void =>
                      dispatch({ type: DashboardActionType.TimeSlot, timeSlot: ts })
                    }
                  />
                ))}
              </div>
            ) : (
              <div className={styles.leftBorder} key={idx}></div>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
};

export default WeekView;
