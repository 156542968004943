import {useMutation} from '@apollo/react-hooks';
import {Checkbox, mergeStyleSets, MessageBarType, Modal, Stack} from '@fluentui/react';
import {ApolloError} from 'apollo-client';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {
  MutationremoveSlotArgs,
  SessionStatus,
  SessionType,
  TimeSlot,
  TimeSlotAvailability,
  Toggle,
} from '../../generated/graphql';
import {removeSlot} from '../../graphql/mutations';
import {theme} from '../../styles/theme';
import {ModalTwoButtons} from '../../templates/ModalTwoButtons';
import {convertErrors} from '../../utils/convertToFormErrors';
import {PADDING32} from './const';
import {DashboardActionType, useDashboardContext} from './Context';
import {settings} from '../../const/config';

const styles = mergeStyleSets({
  appo: {
    borderRadius: '2px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginTop: '2px',
    //marginRight: "4px",
    overflow: 'hidden',
    //whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    userSelect: 'none',
    backgroundColor: theme.palette.themeLight,
    selectors: {
      ':hover': {
        backgroundColor: theme.palette.themeSecondary,
        color: theme.palette.white,
      },
    },
  },

  leftBorder: {
    borderLeft: '1px solid ' + theme.palette.neutralLighter,
    paddingLeft: '4px',
    paddingRight: '4px',
  },

  oldClients: {
    borderRight: '2px solid ' + theme.palette.greenDark,
  },
  allClients: {
    //borderRight: '2px solid ' + theme.palette.greenDark,
  },
  newClients: {
    borderRight: '2px solid ' + theme.palette.yellowDark,
  },

  pending: {
    borderLeft: '4px solid ' + theme.palette.greenDark,
  },
  request: {
    borderLeft: '4px solid ' + theme.palette.redDark,
  },
  new: {
    backgroundColor: '#c9ada7',
  },
  empty: {
    backgroundColor: '#313d5a30',
  },
  followup: {
    borderRight: '4px solid ' + theme.palette.themePrimary,
  },
});

interface SlotProps {
  ts: TimeSlot;
  onClick: (ts: TimeSlot) => void;
}

export const Slot: React.FunctionComponent<SlotProps> = (props) => {
  const {ts, onClick} = props;
  const {session, date} = ts;
  const time = DateTime.fromSeconds(date, {zone: settings.TIME_ZONE}).toFormat('HH:mm');
  const classNames = [styles.appo];

  const mounted = React.useRef<boolean>(true);
  React.useEffect(() => {
    return (): void => {
      mounted.current = false;
    };
  }, [mounted]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [doRemove, setDoRemove] = React.useState(false);

  const {dispatch} = useDashboardContext();
  const {t} = useTranslation();

  const [mutationremoveSlot] = useMutation<{removeSlot: boolean}, MutationremoveSlotArgs>(
    removeSlot,
  );

  if (!ts.session) {
    classNames.push(
      ts.availability === TimeSlotAvailability.ALL
        ? styles.allClients
        : ts.availability === TimeSlotAvailability.EXISTING
          ? styles.oldClients
          : styles.newClients,
    );
  }

  if (session) {
    switch (session.status) {
      case SessionStatus.REQUEST:
        classNames.push(styles.request);
        break;
      case SessionStatus.PENDING:
        classNames.push(styles.pending);
        break;
    }
    if (session.patient.existingClient === Toggle.NO) {
      classNames.push(styles.new);
    }
    if (session.type === SessionType.FOLLOWUP) {
      classNames.push(styles.followup);
    }
  } else {
    classNames.push(styles.empty);
  }

  const onRemoveSlot = React.useCallback(async () => {
    if (doRemove) {
      try {
        await mutationremoveSlot({
          variables: {
            timeSlotId: ts.id || '0',
          },
        });

        dispatch({
          type: DashboardActionType.PopUp,
          message: {type: MessageBarType.success, text: t('message.slotRemoved')},
        });

        dispatch({
          type: DashboardActionType.UpdateTimeSlot,
          action: 'remove',
          timeSlot: ts,
        });

        if (mounted.current) {
          setIsOpen(false);
          setDoRemove(false);
        }
      } catch (e) {
        if (e instanceof ApolloError) {
          const {globalErrors} = convertErrors(e, t);

          if (globalErrors)
            dispatch({
              type: DashboardActionType.PopUp,
              message: {
                type: MessageBarType.error,
                text: globalErrors.map((v) => v.message).join(' '),
              },
            });
        }
      }
    }
  }, [dispatch, doRemove, mutationremoveSlot, t, ts]);

  return (
    <>
      <Modal isOpen={isOpen} isBlocking onDismiss={(): void => setIsOpen(false)}>
        <ModalTwoButtons
          cancelButtonProps={{text: t('button.cancel'), onClick: (): void => setIsOpen(false)}}
          saveButtonProps={{
            disabled: !doRemove,
            text: t('button.save'),
            onClick: (): Promise<void> => onRemoveSlot(),
          }}>
          <Stack tokens={{childrenGap: PADDING32, padding: PADDING32}} verticalAlign='center'>
            <span>
              <Checkbox
                label={t('field.removeTimeSlot')}
                checked={doRemove}
                onChange={(_, checked): void => {
                  setDoRemove(!!checked);
                }}
              />
            </span>
          </Stack>
        </ModalTwoButtons>
      </Modal>
      <div
        className={classNames.join(' ')}
        onClick={(): void => {
          if (session) {
            onClick(ts);
          } else {
            setIsOpen(true);
          }
        }}>
        {time} {session && `${session.patient.firstName} ${session.patient.lastName}`}
      </div>
    </>
  );
};
